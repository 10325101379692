import { Task } from '@lit-labs/task';
import { request } from '../request.js';
import { TaskController } from './task.js';

/**
 * @typedef {import('lit').LitElement} LitElement
 */

export class AccountSupplierController extends TaskController {
  /**
   * @param {LitElement} host
   */
  constructor(host) {
    super();

    this.task = new Task(
      host,
      async ([account, created]) => {
        const supplier = await request(
          'GET',
          `/accounts/${account}/suppliers/${created}`
        );
        return supplier;
      },
      () => [host.getAttribute('account'), host.getAttribute('created')]
    );
  }
}
