import { html, nothing } from 'lit';
import { request } from '../../../lib/request.js';
import { ModalElement } from '../../modal/base.js';
import {
  buildIntegrations,
  integrationMethods
} from '../../../lib/supplier-integrations.js';

class ModalSupplierIntegrations extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    integrations: { state: true },
    supplier: {},
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Supplier Integrations';
    this.account = '';
    this.integrations = {};
    this.supplier = null;
    this.saving = false;
  }

  connectedCallback() {
    super.connectedCallback();

    this.integrations = integrationMethods(this.supplier);
  }

  onToggleIntegration(method, operation, action) {
    const op = this.integrations[method][operation];
    op[action] = !op[action];
    this.requestUpdate();
  }

  async onSave() {
    this.saving = true;
    const integrations = buildIntegrations(this.integrations);
    const updated = await request(
      'PATCH',
      `/accounts/${this.account}/suppliers/${this.supplier.created}`,
      {
        integrations
      }
    );
    this.emit('updated', updated);
    this.close();
  }

  renderBody() {
    if (!Object.keys(this.integrations).length) {
      return nothing;
    }

    return Object.entries(this.integrations).map(
      ([method, operations]) => html`
        <div class="my-2">
          <h6>${method}</h6>
          ${Object.entries(operations).map(
            ([operation, actions]) => html`
              <div class="ps-2 my-2 hstack gap-4">
                <strong class="me-auto">${operation}</strong>
                ${Object.entries(actions).map(
                  ([action, approved]) => html`
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        role="switch"
                        type="checkbox"
                        id="${operation}_${action}"
                        ?checked=${approved}
                        ?disabled=${this.saving}
                        @change=${() =>
                          this.onToggleIntegration(method, operation, action)}
                      />
                      <label
                        class="form-check-label"
                        for="${operation}_${action}"
                      >
                        ${action}
                      </label>
                    </div>
                  `
                )}
              </div>
            `
          )}
        </div>
      `
    );
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Update
      </button>
    `;
  }
}

customElements.define('modal-supplier-integrations', ModalSupplierIntegrations);
