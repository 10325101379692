import { html } from 'lit';
import { request } from '../../lib/request.js';
import { prepareDivision } from '../../lib/prepare-division.js';
import spinner from '../../html/spinner.js';
import { ModalElement } from '../modal/base.js';
import './division-details.js';

class ModalNewDivision extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: { attribute: true },
    division: {}
  };

  constructor() {
    super();
    this.title = 'New Location';
    this.account = '';
    this.division = { name: '' };
    this.processing = false;
  }

  renderBody() {
    if (this.processing) {
      return spinner();
    }
    return html`
      <division-details
        .division=${this.division}
        @update=${() => this.requestUpdate()}
      ></division-details>
    `;
  }

  renderFooter() {
    const create_disabled = this.processing || !this.division.name;
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.processing}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.createDivision}
        ?disabled=${create_disabled}
      >
        Create
      </button>
    `;
  }

  async createDivision() {
    const location = await request(
      'POST',
      `/accounts/${this.account}/divisions`,
      prepareDivision(this.division)
    );

    this.emit('added', location);
    this.close();
  }
}

customElements.define('modal-new-division', ModalNewDivision);
