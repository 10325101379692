import { html } from 'lit';
import country_codes from '@studio/country-codes';
import { BaseElement } from '../base.js';

class SharedCountrySelector extends BaseElement {
  static properties = {
    selected: { attribute: true }
  };

  constructor() {
    super();
    this.selected = 'CH';
  }

  render() {
    return html`
      <select class="form-select" name="country">
        ${country_codes.map(
          ({ c, n }) => html`
            <option value="${c}" ?selected=${c === this.selected}>${n}</option>
          `
        )}
      </select>
    `;
  }
}

customElements.define('shared-country-selector', SharedCountrySelector);
