import { html } from 'lit';
import { ParentElement } from '../parent.js';

class SharedSearchBar extends ParentElement {
  static properties = {
    value: {}
  };

  value = '';

  render() {
    return html`
      <div class="input-group mb-3">
        <span class="input-group-text"><i class="bi bi-search"></i></span>
        <input
          type="text"
          class="form-control"
          autocomplete="off"
          value=${this.value}
        />
        ${this.content}
      </div>
    `;
  }
}

customElements.define('shared-search-bar', SharedSearchBar);
