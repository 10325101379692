import { html } from 'lit';
import { request } from '../../lib/request.js';
import spinner from '../../html/spinner.js';
import { ModalElement } from '../modal/base.js';

class ModalNewLocation extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: { attribute: true },
    display_name: {},
    type: {},
    division: {},
    group: {}
  };

  constructor() {
    super();
    this.title = 'New Area';
    this.account = '';
    this.display_name = '';
    this.type = 'bar';
    this.division = null;
    this.group = null;
    this.processing = false;
  }

  inputName(event) {
    this.display_name = event.target.value;
  }

  inputType(event) {
    this.type = event.target.value;
  }

  renderBody() {
    if (this.processing) {
      return spinner();
    }
    return html`
      <div class="vstack gap-3">
        ${this.division &&
        html` <div>
          <h6>Location</h6>
          ${this.division.name}
        </div>`}
        ${this.group &&
        html`
          <div>
            <h6>Group</h6>
            ${this.group.display_name}
          </div>
        `}
        <div>
          <h6>Type</h6>
          ${this.renderTypeSelector()}
        </div>
        <div>
          <label class="form-label">Name:</label>
          <input
            type="text"
            name="location_name"
            value=${this.display_name}
            class="form-control"
            placeholder="Area name"
            @input=${this.inputName}
          />
        </div>
      </div>
    `;
  }

  renderTypeSelector() {
    return this.group
      ? this.group.type
      : html`
          <select class="form-select form-select-sm" @change=${this.inputType}>
            <option value="bar">Bar</option>
            <option value="storage">Storage</option>
          </select>
        `;
  }

  renderFooter() {
    const create_disabled = this.processing || !this.display_name;
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.processing}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.createLocation}
        ?disabled=${create_disabled}
      >
        Create
      </button>
    `;
  }

  async createLocation() {
    const json = {
      display_name: this.display_name,
      type: this.type
    };
    if (this.division) {
      json.division = this.division.id;
    }
    if (this.group) {
      json.group = this.group.id;
    }

    const location = await request(
      'POST',
      `/accounts/${this.account}/locations`,
      json
    );

    this.emit('added', location);
    this.close();
  }
}

customElements.define('modal-new-location', ModalNewLocation);
