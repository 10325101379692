import { html } from 'lit';

export default function (message) {
  return html`
    <div class="alert alert-success mb-4">
      <i class="bi bi-check2-circle me-1"></i>
      ${message}
    </div>
  `;
}
