import { ORDER_ACTIONS, ORDER_OPERATIONS } from './constants.js';

export function integrationMethods(supplier) {
  if (!supplier.connect) {
    return {};
  }

  const { notifications } = supplier.connect;
  const integrations = supplier.integrations;
  const integration_methods = {};

  for (const operation of ORDER_OPERATIONS) {
    for (const action of ORDER_ACTIONS) {
      notifications[operation][action].methods
        .filter((method) => method !== 'email')
        .forEach((method) => {
          ensureOperation(integration_methods, method, operation)[action] =
            Boolean(integrations?.[operation][action].includes(method));
        });
    }
  }

  return integration_methods;
}

function ensureOperation(integrations, method, operation) {
  if (!integrations[method]) {
    integrations[method] = {};
  }
  if (!integrations[method][operation]) {
    integrations[method][operation] = {};
  }
  return integrations[method][operation];
}

export function buildIntegrations(integration_methods = {}) {
  const integrations = {};

  for (const operation of ORDER_OPERATIONS) {
    integrations[operation] = {};
    for (const action of ORDER_ACTIONS) {
      if (!integrations[operation][action]) {
        integrations[operation][action] = [];
      }
      for (const [method, operations] of Object.entries(integration_methods)) {
        if (operations[operation]?.[action]) {
          integrations[operation][action].push(method);
        }
      }
    }
  }

  return integrations;
}

export function connectStatus(supplier) {
  const { connect, integrations } = supplier;
  for (const operation of ORDER_OPERATIONS) {
    for (const action of ORDER_ACTIONS) {
      if (!isIntegrated(operation, action, connect, integrations)) {
        return 'warning';
      }
    }
  }
  return 'success';
}

function isIntegrated(operation, action, connect, integrations) {
  const methods = connect.notifications[operation][action].methods;
  const action_integrations = integrations?.[operation][action] || [];

  for (const method of methods) {
    if (method !== 'email' && !action_integrations.includes(method)) {
      return false;
    }
  }
  return true;
}
