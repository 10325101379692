import { html } from 'lit';
import { ParentElement } from '../../elements/parent.js';

class LayoutCenter extends ParentElement {
  render() {
    return html`
      <div class="d-flex flex-column justify-content-around vh-100">
        <div class="container">
          <div class="mx-auto" style="width: 320px;">${this.content}</div>
        </div>
      </div>
    `;
  }
}

customElements.define('layout-centered', LayoutCenter);
