import { html } from 'lit';
import { Task } from '@lit-labs/task';
import { request } from '../../lib/request.js';
import spinner from '../../html/spinner.js';
import alertError from '../../html/alert-error.js';
import { ModalElement } from '../modal/base.js';

class ModalNewConnect extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    data: { state: true },
    saving: { state: true },
    account: { state: true }
  };

  constructor() {
    super();
    this.title = 'Create New Connect Account';
    this.data = {};
    this.saving = null;
    this.account = null;
  }

  createNewAccount() {
    this.saving = new Task(
      this,
      async () => {
        const new_account = await request(
          'POST',
          `/connect-accounts`,
          this.data
        );
        this.title = 'New Account Created';
        this.account = new_account.account;
        return new_account;
      },
      () => []
    );
  }

  showAccount() {
    // Could not get this to work with the router ¯\_(ツ)_/¯
    location.href = `/connect-accounts/${this.account}`;
  }

  inputData(event) {
    this.data[event.target.name] = event.target.value;
  }

  renderNewAccount(new_account) {
    return html`
      <div class="input-group">
        <span class="input-group-text">Token</span>
        <input
          type="text"
          class="form-control w-50"
          value="${new_account.token}"
          disabled
        />
      </div>
    `;
  }

  renderBody() {
    if (this.saving) {
      return this.saving.render({
        pending: () => spinner(),
        error: alertError,
        complete: (new_account) => this.renderNewAccount(new_account)
      });
    }
    return html`
      <div class="vstack gap-3">
        ${this.renderInput('name', 'Name')}
        ${this.renderInput('email', 'Account Email')}
        ${this.renderInput(
          'order_email',
          'Order Emails',
          'Comma separated emails'
        )}
      </div>
    `;
  }

  renderInput(name, title, placeholder = title) {
    return html`
      <div>
        <label class="form-label">${title}</label>
        <input
          type="text"
          name=${name}
          value=${this.data[name]}
          class="form-control"
          placeholder="${placeholder}"
          @input=${this.inputData}
          autocomplete="off"
        />
      </div>
    `;
  }

  renderFooter() {
    if (this.saving) {
      return this.saving.render({
        pending: () => null,
        error: () => renderCloseButton(),
        complete: () => html`
          <button
            type="button"
            class="btn btn-primary"
            @click=${this.showAccount}
          >
            Show Account
          </button>
        `
      });
    }
    return html`
      ${renderCloseButton()}
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.createNewAccount}
        ?disabled=${this.saving}
      >
        Create
      </button>
    `;
  }
}

function renderCloseButton() {
  return html`
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
      Close
    </button>
  `;
}

customElements.define('modal-new-connect', ModalNewConnect);
