import { html } from 'lit';
import { request } from '../../lib/request.js';
import { ORDER_METHODS } from '../../lib/constants.js';
import { ModalElement } from '../modal/base.js';

class ModalNotifications extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    notifications: { state: true },
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Notification methods';
    this.size = 'lg';
    this.account = '';
    this.notifications = {};
    this.saving = false;
  }

  async onSave() {
    this.saving = true;
    const updated = await request(
      'PATCH',
      `/connect-accounts/${this.account}`,
      {
        notifications: {
          order: this.notifications.order,
          return: this.notifications.return
        }
      }
    );
    this.emit('updated', updated);
    this.close();
  }

  changeMethod(operation, action, method, checked) {
    const { methods } = this.notifications[operation][action];

    if (checked) {
      methods.push(method);
    } else {
      methods.splice(methods.indexOf(method), 1);
    }

    this.requestUpdate();
  }

  renderBody() {
    return html`
      ${this.renderSection('order', this.notifications.order)}
      ${this.renderSection('return', this.notifications.return)}
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Update
      </button>
    `;
  }

  renderSection(operation, data) {
    return html`
      <div class="container mt-3">
        <div class="row">
          <h5>${operation}</h5>
        </div>
        ${this.renderRow(operation, 'request', data.request)}
        ${this.renderRow(operation, 'cancel', data.cancel)}
      </div>
    `;
  }

  renderRow(operation, action, data) {
    return html`
      <div class="row">
        <div class="col col-md-4 col-lg-2">${action}</div>
        ${ORDER_METHODS.map((method) =>
          this.renderCheckBox(
            operation,
            action,
            method,
            data.methods.includes(method)
          )
        )}
      </div>
    `;
  }

  renderCheckBox(operation, action, method, checked) {
    const id = `${operation}-${action}-${method}`;
    return html`
      <div class="col">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="checkbox"
            id="${id}"
            value=${method}
            ?checked=${checked}
            @change=${() =>
              this.changeMethod(operation, action, method, !checked)}
          />
          <label class="form-check-label" for=${id}>${method}</label>
        </div>
      </div>
    `;
  }
}

customElements.define('modal-notifications', ModalNotifications);
