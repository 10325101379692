import { html } from 'lit';
import { ConnectAccountsController } from '../../lib/controllers/connect-accounts.js';
import { BaseElement } from '../base.js';
import './status.js';
import './suppliers.js';

export class ConnectAccountSelect extends BaseElement {
  static properties = {
    details: {},
    suppliers: { attribute: false }
  };

  constructor() {
    super();
    this.suppliers = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.connect_accounts = new ConnectAccountsController(
      this,
      linkedAccountFilter(this.suppliers)
    );
  }

  render() {
    return this.connect_accounts?.render((accounts) => {
      const unlinked_accounts = Object.fromEntries(
        accounts.map((account) => [
          `${account.name} (${account.email})`,
          account
        ])
      );

      return html`
        <input
          class="form-control"
          list="datalistOptions"
          placeholder="Type to search connect accounts..."
          @input=${(e) => {
            e.stopPropagation();
            this.emit('input', unlinked_accounts[e.target.value]);
          }}
        />
        <datalist id="datalistOptions">
          ${Object.keys(unlinked_accounts).map(
            (name) => html`<option value="${name}"></option>`
          )}
        </datalist>
      `;
    });
  }
}

function linkedAccountFilter(suppliers) {
  const connect_accounts = suppliers
    .filter((supplier) => supplier.connect)
    .map((supplier) => supplier.connect.account);
  return ({ account }) => !connect_accounts.includes(account);
}

customElements.define('select-connect-account', ConnectAccountSelect);
