import { html } from 'lit';
import { request } from '../../lib/request.js';
import spinner from '../../html/spinner.js';
import { ModalElement } from '../modal/base.js';
import './select-connect-account.js';

class ModalNewSupplier extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: { attribute: true },
    suppliers: { attribute: false },
    selected_account: { state: true }
  };

  constructor() {
    super();
    this.title = 'New Supplier';
    this.account = '';
    this.suppliers = [];
    this.customer_id = '';
    this.processing = false;
    this.selected_account = null;
  }

  selectAccount(account) {
    this.selected_account = account;
  }

  inputCustomerId(event) {
    this.customer_id = event.target.value;
  }

  renderBody() {
    if (this.processing) {
      return spinner();
    }
    return html`
      <div class="vstack gap-3">
        <div>
          <label class="form-label">Connect Account:</label>
          <select-connect-account
            .suppliers=${this.suppliers}
            @input=${(e) => this.selectAccount(e.detail)}
          >
          </select-connect-account>
        </div>
        <div>
          <label class="form-label">Customer ID:</label>
          <input
            type="text"
            name="customer_id"
            value=${this.customer_id}
            class="form-control"
            placeholder="Customer ID"
            @input=${this.inputCustomerId}
          />
        </div>
      </div>
    `;
  }

  renderFooter() {
    const create_disabled = this.processing || !this.selected_account;
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.processing}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.createSupplier}
        ?disabled=${create_disabled}
      >
        Create
      </button>
    `;
  }

  async createSupplier() {
    const json = {
      name: this.selected_account.name,
      connect: {
        account: this.selected_account.account
      }
    };
    if (this.customer_id) {
      json.customer_id = this.customer_id;
    }
    this.processing = true;

    const supplier = await request(
      'POST',
      `/accounts/${this.account}/suppliers`,
      json
    );

    this.emit('added', supplier);
    this.close();
  }
}

customElements.define('modal-new-supplier', ModalNewSupplier);
