import { BaseElement } from './base.js';

export class ParentElement extends BaseElement {
  constructor() {
    super();

    this.content = document.createDocumentFragment();
  }

  connectedCallback() {
    super.connectedCallback();

    for (const node of Array.from(this.childNodes)) {
      this.content.appendChild(node);
    }
  }
}
