const NO_VALUE = Symbol('NO_VALUE');

export function throttle(callback, interval = 500) {
  let delay = false;
  let next_value = NO_VALUE;
  return (value) => {
    if (delay) {
      next_value = value;
    } else {
      delay = true;
      setTimeout(() => {
        delay = false;
        if (next_value !== NO_VALUE) {
          callback(next_value);
          next_value = NO_VALUE;
        }
      }, interval);

      callback(value);
    }
  };
}
