import { html } from 'lit';
import { ContextConsumer } from '@lit-labs/context';
import { pushState } from '../../lib/route.js';
import { router_context } from '../../lib/context.js';
import { LS_AUTH_TOKEN } from '../../lib/constants.js';
import { getTheme, setTheme } from '../../lib/theme.js';
import { ParentElement } from '../../elements/parent.js';

class LayoutApp extends ParentElement {
  static properties = {
    theme: { attribute: false }
  };

  constructor() {
    super();

    this.router = new ContextConsumer(this, router_context);
    this.theme = getTheme();
  }

  onLogout() {
    this.emit('modal:confirm', {
      description: 'Do you really want to log out?',
      confirm: () => this.logout()
    });
  }

  toggleTheme() {
    this.theme = this.theme === 'dark' ? 'light' : 'dark';
    setTheme(this.theme);
  }

  logout() {
    localStorage.removeItem(LS_AUTH_TOKEN);
    pushState(this.router.value, '/login');
  }

  render() {
    const logo =
      this.theme === 'dark'
        ? '/img/logo-white-wide.png'
        : '/img/logo-black-wide.png';
    return html`
      <nav class="navbar navbar-expand-lg bg-body sticky-top">
        <div class="container">
          <a class="navbar-brand" href="/">
            <img src="${logo}" class="img-fluid" width="140" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
          >
            <i class="bi bi-list"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto">
              <li class="nav-item">
                <a class="nav-link" href="/accounts/">Accounts</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/connect-accounts/">Connect</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/stats/">Statistics</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/reports/">Reports</a>
              </li>
            </ul>
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <button
                  class="btn btn-link nav-link dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-person-circle"></i>
                </button>
                <ul class="nav-item dropdown-menu dropdown-menu-end shadow">
                  <li>
                    <a class="dropdown-item" @click="${this.toggleTheme}">
                      ${this.theme === 'dark' ? 'Light mode' : 'Dark mode'}
                    </a>
                  </li>
                  <li><hr class="dropdown-divider" /></li>
                  <li>
                    <a class="dropdown-item" href="#" @click=${this.onLogout}>
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <main class="container my-3">${this.content}</main>
    `;
  }
}

customElements.define('layout-app', LayoutApp);
