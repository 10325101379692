import { html } from 'lit';
import { request } from '../../lib/request.js';
import { ModalElement } from '../modal/base.js';
import { deepCopy } from '../../lib/deep-copy.js';
import renderOptions from '../../html/options.js';

const pricing_profiles = ['bianchi-prices-csv', 'ybm-prices-csv'];

class ModalSftpImportPricing extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    sftp: {},
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Pricing Import';
    this.account = '';
    this.sftp = {};
    this.saving = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.sftp = deepCopy(this.sftp);
    const { import_config } = this.sftp;
    if (!import_config.pricing) {
      import_config.pricing = { remote_files: '' };
    } else if (import_config.pricing.remote_files) {
      import_config.pricing.remote_files =
        import_config.pricing.remote_files.join(', ');
    }
  }

  async onSave() {
    this.saving = true;

    const { remote_files, profile } = this.sftp.import_config.pricing || {};
    const json = {
      pricing: {
        remote_files: remote_files.split(/,\s*/).filter(Boolean),
        profile: profile || 'ybm-prices-csv'
      }
    };
    const response = await request(
      'PATCH',
      `/connect-accounts/${this.account}/sftp/import`,
      json
    );
    this.emit('updated', response);
    this.close();
  }

  async onRemove() {
    this.saving = true;

    await request('PATCH', `/connect-accounts/${this.account}/sftp/import`, {
      pricing: null
    });
    this.emit('updated', undefined);
    this.close();
  }

  onUpdateField(e) {
    this.sftp.import_config.pricing[e.target.name] = e.target.value;
    this.requestUpdate();
  }

  renderBody() {
    const { remote_files, profile } = this.sftp.import_config.pricing;
    return html`
      <div class="row">
        <label class="col-4 col-form-label">Remote files</label>
        <div class="col-8">
          <input
            type="text"
            class="form-control"
            name="remote_files"
            .value=${remote_files || ''}
            @input=${this.onUpdateField}
          />
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Profile</label>
        <div class="col-8">
          <select
            class="form-select"
            name="profile"
            @change=${this.onUpdateField}
          >
            ${renderOptions(pricing_profiles, profile || 'ybm-prices-csv')}
          </select>
        </div>
      </div>
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-outline-danger me-auto"
        @click=${this.onRemove}
        ?disabled=${this.saving}
      >
        Remove
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Save
      </button>
    `;
  }
}

customElements.define('modal-sftp-import-pricing', ModalSftpImportPricing);
