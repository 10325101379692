import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement } from '../base.js';
import './status.js';
import './suppliers.js';
import './usage.js';

export class AccountSidebar extends BaseElement {
  static properties = {
    details: {},
    item: { state: true }
  };

  constructor() {
    super();
    this.details = '';
    this.item = null;
  }

  render() {
    if (!this.item) {
      return nothing;
    }

    const item = this.item;
    const has_areas = item.features.includes('MIGRATED');
    return html`
      <div class="card">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="display-5 me-2">${renderAccountTypeIcon(item)}</div>
          <div class="text-truncate flex-grow-1">
            <h4 class="text-truncate mb-1">${item.display_name}</h4>
            ${item.company
              ? html`<small>${item.first_name} ${item.last_name}</small>`
              : null}
            ${item.principals
              ? html`
                  <small>
                    <a
                      href="/accounts/${item.principals[0]}/"
                      class="text-decoration-none"
                    >
                      Main Account <i class="bi bi-chevron-right"></i>
                    </a>
                  </small>
                `
              : null}
          </div>
        </div>
        <div class="list-group list-group-flush">
          <a
            href="/accounts/${item.account}/"
            class="list-group-item list-group-item-action ${classMap({
              active: this.details === 'status'
            })}"
          >
            Account
          </a>
          ${item.principals
            ? null
            : html`
                <a
                  href="/accounts/${item.account}/${has_areas
                    ? 'areas'
                    : 'locations'}"
                  class="list-group-item list-group-item-action ${classMap({
                    active:
                      this.details === 'locations' || this.details === 'areas'
                  })}"
                >
                  Locations & Areas
                </a>
                <a
                  href="/accounts/${item.account}/suppliers"
                  class="list-group-item list-group-item-action ${classMap({
                    active: this.details === 'suppliers'
                  })}"
                >
                  Suppliers
                </a>
                <a
                  href="/accounts/${item.account}/usage"
                  class="list-group-item list-group-item-action ${classMap({
                    active: this.details === 'usage'
                  })}"
                >
                  Usage
                </a>
              `}
        </div>
      </div>
    `;
  }
}

function renderAccountTypeIcon(item) {
  return html`
    <i class="bi bi-${item.principals ? 'person' : 'building'} me-2"></i>
  `;
}

customElements.define('account-sidebar', AccountSidebar);
