import { html } from 'lit';
import { request } from '../../../lib/request.js';
import { ModalElement } from '../../modal/base.js';

class ModalAccountSupplierCustomerId extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    created: { type: Number },
    customer_id: { attribute: 'customer-id' },
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Supplier Customer ID';
    this.account = '';
    this.created = null;
    this.customer_id = '';
    this.saving = false;
  }

  async onSave() {
    this.saving = true;
    const updated = await request(
      'PATCH',
      `/accounts/${this.account}/suppliers/${this.created}`,
      {
        customer_id: this.customer_id
      }
    );
    this.emit('updated', updated);
    this.close();
  }

  renderBody() {
    return html`
      <input
        class="form-control"
        name="customer-id"
        value=${this.customer_id}
        autocomplete="off"
        @input=${(e) => (this.customer_id = e.target.value)}
      />
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Update
      </button>
    `;
  }
}

customElements.define(
  'modal-account-supplier-customer-id',
  ModalAccountSupplierCustomerId
);
