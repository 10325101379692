import { html } from 'lit';
import { AccountAreasController } from '../../lib/controllers/account-areas.js';
import { request } from '../../lib/request.js';
import { BaseElement } from '../base.js';
import './new-area-modal.js';
import './new-division-modal.js';
import './setup-areas.js';
import { formatRelativeDate } from '@yourbarmate/commons/date-and-time/index.js';

export class AccountAreas extends BaseElement {
  static properties = {
    account: {},
    account_details: { state: true }
  };

  constructor() {
    super();
    this.account = '';
    this.account_details = null;
    this.areas = new AccountAreasController(this);
  }

  onArchiveArea(e) {
    this.emit('modal:confirm', {
      description: `Do you really want to archive this area?`,
      confirm: () => this.changeAreaArchiveDate('POST', e.target.dataset.id)
    });
  }

  onActivateArea(e) {
    this.emit('modal:confirm', {
      description: `Do you really want to activate this area?`,
      confirm: () => this.changeAreaArchiveDate('DELETE', e.target.dataset.id)
    });
  }

  onAddArea(div_id) {
    const { divisions } = this.areas;
    const division = div_id && divisions.find(({ id }) => id === div_id);
    this.emit(
      'modal:open',
      html`
        <modal-new-area
          .account=${this.account_details}
          .division=${division}
          @added=${({ detail }) => this.areas.addArea(detail)}
        ></modal-new-area>
      `
    );
  }

  onAddAreas(setup) {
    const { bars, storages, bar_stock_tracking, storage_stock_tracking } =
      setup;

    const all_areas = [
      ...storages.filter(Boolean).map((name) => ({
        type: 'storage',
        name,
        stock_pool: storage_stock_tracking ? 'STOCK_TRACKING' : 'ITEMS_ONLY'
      })),
      ...bars.filter(Boolean).map((name) => ({
        type: 'bar',
        name,
        stock_pool: bar_stock_tracking ? 'STOCK_TRACKING' : 'ITEMS_ONLY'
      }))
    ];
    this.emit('modal:confirm', {
      description: 'Create the following areas?',
      details: all_areas.map(({ type, name }) => `${name}(${type})`).join(', '),
      confirm: async () => {
        const new_areas = await request(
          'POST',
          `/accounts/${this.account}/areas`,
          all_areas
        );

        this.areas.addArea(...new_areas);
      }
    });
  }

  onAddDivision() {
    this.emit(
      'modal:open',
      html`
        <modal-new-division
          account=${this.account}
          @added=${({ detail }) => this.areas.addDivision(detail)}
        ></modal-new-division>
      `
    );
  }

  async changeAreaArchiveDate(method, id) {
    const area = await request(
      method,
      `/accounts/${this.account}/areas/${id}/archived`
    );
    this.areas.updateArea(area);
  }

  render() {
    return this.areas.render(({ divisions, areas }) => {
      if (!divisions.length && !areas.length) {
        return this.renderNewAccount();
      }
      if (divisions.length) {
        return this.renderDivisions(divisions, areas);
      }
      return this.renderAreas(areas);
    });
  }

  renderNewAccount() {
    return html`
      <account-areas-setup
        @addDivision=${() => this.onAddDivision()}
        @createAreas=${({ detail }) => this.onAddAreas(detail)}
      >
      </account-areas-setup>
    `;
  }

  renderDivisions(divisions, areas) {
    return html`
      ${divisions.map(
        (division) => html`
          <h3>
            <span>${division.name}</span>
            <code class="ms-2 fs-6">${division.id}</code>
          </h3>
          ${this.renderAreas(
            areas.filter((l) => l.division === division.id),
            division.id
          )}
        `
      )}
      <div class="my-2">&nbsp;</div>
      <div class="fixed-bottom p-3 bg-body shadow">
        <div class="container d-flex justify-content-between">
          <button class="btn btn-primary" @click=${() => this.onAddDivision()}>
            Add New Area
          </button>
        </div>
      </div>
    `;
  }

  renderAreas(areas, division) {
    return html`
      <div class="list-group mb-5">
        ${this.renderAreaRows(areas)} ${this.renderAddButton(division)}
      </div>
    `;
  }

  renderAreaRows(areas) {
    return areas.map(
      (area) => html`
        <div
          class="list-group-item d-flex align-items-center justify-content-between"
        >
          <div class="d-flex">
            <code class="me-2">${area.code}</code>
            ${renderType(area.type)}
            <h6 class="mb-0">${area.name}</h6>
          </div>
          <div>
            ${renderTrackingBadge(area)}
            ${area.archived
              ? html`
                  <shared-tooltip>
                    <div
                      class="badge text-bg-warning me-2"
                      data-bs-toggle="tooltip"
                      data-bs-title="${formatRelativeDate(area.archived, 'en')}"
                    >
                      Archived
                    </div>
                  </shared-tooltip>
                  <button
                    class="btn btn-sm btn-outline-secondary"
                    data-id="${area.id}"
                    @click=${this.onActivateArea}
                  >
                    Activate
                  </button>
                `
              : html`
                  <button
                    class="btn btn-sm btn-outline-secondary"
                    data-id="${area.id}"
                    @click=${this.onArchiveArea}
                  >
                    Archive
                  </button>
                `}
          </div>
        </div>
      `
    );
  }

  renderAddButton(division) {
    return html`
      <div class="mt-2">
        <button
          type="button"
          class="btn btn-primary btn-sm"
          @click=${() => this.onAddArea(division)}
        >
          <i class="bi bi-plus me-1"></i>
          Add area
        </button>
      </div>
    `;
  }
}

customElements.define('account-areas', AccountAreas);

function renderType(type) {
  return html`<span class="badge text-bg-secondary me-2">${type}</span>`;
}

function renderTrackingBadge(area) {
  return area.stock_pool === 'STOCK_TRACKING'
    ? html`<span class="badge bg-success me-2">Tracking</span>`
    : html`<span class="badge bg-secondary me-2">Items only</span>`;
}
