import { html } from 'lit';
import { Routes } from '@lit-labs/router';
import { ContextProvider } from '@lit-labs/context';
import { BaseElement } from '../../base.js';
import { router_context } from '../../../lib/context.js';
import { AccountSupplierController } from '../../../lib/controllers/account-supplier.js';
import './status.js';
import './products.js';
import './modal-connect.js';
import './modal-customer-id.js';

export class AccountSupplier extends BaseElement {
  static properties = {
    account: { type: String },
    created: { type: Number }
  };

  constructor() {
    super();
    this.routes = new Routes(this, [
      {
        path: 'products',
        render: () => this.renderProducts()
      },
      {
        path: '*',
        render: () => this.renderStatus()
      }
    ]);
    this.routerProvider = new ContextProvider(
      this,
      router_context,
      this.routes
    );
    this.account = null;
    this.created = null;
    this.supplier = null;
  }

  connectedCallback() {
    super.connectedCallback();
    this.supplier = new AccountSupplierController(this);
  }

  render() {
    return this.routes.outlet();
  }

  renderStatus() {
    return this.supplier.render(
      (supplier) => html`
        <account-supplier-status
          account="${this.account}"
          .supplier=${supplier}
        ></account-supplier-status>
      `
    );
  }

  renderProducts() {
    return this.supplier?.render(
      (supplier) => html`
        <account-supplier-products
          account="${this.account}"
          created="${this.created}"
          connect="${supplier.connect ? supplier.connect.account : ''}"
        ></account-supplier-products>
      `
    );
  }
}

customElements.define('account-supplier', AccountSupplier);
