import { LS_AUTH_TOKEN, LS_USER_NAME } from '../../lib/constants.js';
import alertSuccess from '../../html/alert-success.js';
import { BaseElement } from '../base.js';

export class Accounts extends BaseElement {
  static properties = {
    user: { state: true }
  };

  constructor() {
    super();
    this.user = localStorage.getItem(LS_USER_NAME);
  }

  connectedCallback() {
    super.connectedCallback();

    if (!this.user || !localStorage.getItem(LS_AUTH_TOKEN)) {
      location.href = '/login'; // Hard reload.
    }
  }

  render() {
    return alertSuccess(`Hi ${this.user}!`);
  }
}

customElements.define('app-index', Accounts);
