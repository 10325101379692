import { html, nothing } from 'lit';
import { request } from '../../lib/request.js';
import spinner from '../../html/spinner.js';
import { ModalElement } from '../modal/base.js';

class ModalNewArea extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: { state: true },
    name: {},
    type: {},
    division: {}
  };

  constructor() {
    super();
    this.title = 'New Area';
    this.account = null;
    this.name = '';
    this.type = 'bar';
    this.division = null;
    this.stock_tracking = true;
    this.processing = false;
  }

  inputName(event) {
    this.name = event.target.value;
  }

  inputType(event) {
    this.type = event.target.value;
  }

  inputStockTracking(event) {
    this.stock_tracking = event.target.checked;
  }

  renderBody() {
    if (this.processing) {
      return spinner();
    }
    const has_stock_tracking = this.account.features.includes('STOCK_TRACKING');
    return html`
      <div class="vstack gap-3">
        ${this.division &&
        html`
          <div>
            <h6>Location</h6>
            ${this.division.name}
          </div>
        `}
        <div>
          <h6>Type</h6>
          ${this.renderTypeSelector()}
        </div>
        <div>
          <label class="form-label">Name:</label>
          <input
            type="text"
            name="area_name"
            value=${this.name}
            class="form-control"
            placeholder="Area name"
            @input=${this.inputName}
          />
        </div>
        ${has_stock_tracking
          ? html`
              <div>
                <input
                  type="checkbox"
                  name="stock_tracking"
                  ?checked=${this.stock_tracking}
                  class="form-check-input"
                  @input=${this.inputStockTracking}
                />
                <label class="form-label">Stock tracking</label>
              </div>
            `
          : nothing}
      </div>
    `;
  }

  renderTypeSelector() {
    return html`
      <select class="form-select form-select-sm" @change=${this.inputType}>
        <option value="bar">Bar</option>
        <option value="storage">Storage</option>
      </select>
    `;
  }

  renderFooter() {
    const create_disabled = this.processing || !this.name;
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.processing}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.createLocation}
        ?disabled=${create_disabled}
      >
        Create
      </button>
    `;
  }

  async createLocation() {
    const json = {
      name: this.name,
      type: this.type,
      stock_pool: this.stock_tracking ? 'STOCK_TRACKING' : 'ITEMS_ONLY'
    };
    if (this.division) {
      json.division = this.division.id;
    }

    const area = await request(
      'POST',
      `/accounts/${this.account.account}/areas`,
      json
    );

    this.emit('added', area);
    this.close();
  }
}

customElements.define('modal-new-area', ModalNewArea);
