import { Task } from '@lit-labs/task';
import { request } from '../request.js';
import { TaskController } from './task.js';

/**
 * @typedef {import('lit').LitElement} LitElement
 */

export class ReportsController extends TaskController {
  /**
   * @param {LitElement} host
   */
  constructor(host) {
    super();

    this.next_range = 2;
    this.months = [];
    this.host = host;
    this.task = new Task(
      host,
      async () => {
        const ranges = [getDateRange(0), getDateRange(1)];
        const [daily, month_0, month_1] = await Promise.all([
          request('GET', '/reports'),
          request(
            'GET',
            `/reports/aggregate?from=${ranges[0].from}&to=${ranges[0].to}`
          ),
          request(
            'GET',
            `/reports/aggregate?from=${ranges[1].from}&to=${ranges[1].to}`
          )
        ]);
        this.months.push(month_0, month_1);
        return { daily, months: this.months };
      },
      () => []
    );
  }

  async loadMore() {
    const { from, to } = getDateRange(this.next_range++);
    const month = await request(
      'GET',
      `/reports/aggregate?from=${from}&to=${to}`
    );
    this.months.push(month);
    this.host.requestUpdate();
  }
}

function getDateRange(offset) {
  const from = new Date();
  from.setUTCHours(0, 0, 0, 0);
  from.setUTCDate(1);
  from.setUTCMonth(from.getUTCMonth() - offset);
  const to = new Date(from);
  to.setUTCMonth(to.getUTCMonth() + 1);
  to.setUTCDate(to.getUTCDate() - 1);
  return {
    from: from.toISOString().split('T')[0],
    to: to.toISOString().split('T')[0]
  };
}
