import { html } from 'lit';
import { ParentElement } from '../parent.js';

class SharedActionsBar extends ParentElement {
  render() {
    return html`
      <div class="fixed-bottom p-3 bg-body shadow">
        <div class="container hstack gap-3">${this.content}</div>
      </div>
    `;
  }
}

customElements.define('shared-actions-bar', SharedActionsBar);
