import { Task } from '@lit-labs/task';
import { stringPropertyComparator } from '@yourbarmate/commons/string-comparator.js';
import { request } from '../request.js';
import { TaskController } from './task.js';

/**
 * @typedef {import('lit').LitElement} LitElement
 */

export class AccountLocationsController extends TaskController {
  /**
   * @param {LitElement} host
   */
  constructor(host) {
    super();

    this.host = host;
    this.divisions = [];
    this.locations = [];
    this.location_groups = [];
    this.task = new Task(
      host,
      async ([account]) => {
        const { divisions, locations, location_groups } = await request(
          'GET',
          `/accounts/${account}/locations`
        );
        this.divisions = divisions.sort(stringPropertyComparator('name'));
        this.locations = locations.sort(
          stringPropertyComparator('display_name')
        );
        this.location_groups = location_groups.sort(
          stringPropertyComparator('display_name')
        );
        return { divisions, locations, location_groups };
      },
      () => [host.getAttribute('account')]
    );
  }

  /**
   * @param {Object} division
   */
  addDivision(division) {
    this.divisions.push(division);
    this.host.requestUpdate();
  }

  /**
   * @param {Object} location
   */
  addLocation(...location) {
    this.locations.push(...location);
    this.host.requestUpdate();
  }

  /**
   * @param {Object} location
   */
  updateLocation(location) {
    const index = this.locations.findIndex(
      ({ code }) => code === location.code
    );
    this.locations.splice(index, 1, location);
    this.host.requestUpdate();
  }

  /**
   * @param {Object} location_group
   */
  addLocationGroup(location_group) {
    this.location_groups.push(location_group);
    this.host.requestUpdate();
  }

  reload() {
    this.task.run();
  }
}
