import { html } from 'lit';
import { request } from '../../lib/request.js';
import { ModalElement } from '../modal/base.js';

class ModalAccountPassword extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    password: {},
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Account Password';
    this.account = '';
    this.password = '';
    this.saving = false;
  }

  async onSave() {
    this.saving = true;
    const updated = await request('PATCH', `/accounts/${this.account}`, {
      password: this.password
    });
    this.emit('updated', updated);
    this.close();
  }

  renderBody() {
    return html`
      <input
        class="form-control"
        type="password"
        name="password"
        value=${this.password}
        @input=${(e) => (this.password = e.target.value)}
      />
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Update
      </button>
    `;
  }
}

customElements.define('modal-account-password', ModalAccountPassword);
