import { html, nothing } from 'lit';
import { request } from '../../lib/request.js';
import { ModalElement } from '../modal/base.js';

class ModalAccountEmail extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    email: {},
    action: {},
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Account Email';
    this.account = '';
    this.email = '';
    this.action = 'update';
    this.saving = false;
  }

  async onSave() {
    this.saving = true;
    const updated =
      this.action === 'invite'
        ? await request('POST', `/accounts/${this.account}/invitation`, {})
        : await request('PATCH', `/accounts/${this.account}`, {
            email: this.email
          });
    this.emit('updated', updated);
    this.close();
  }

  renderBody() {
    return html`
      ${this.action === 'invite'
        ? html`
            <p>
              Send invitation email to the main account so they can set up their
              password and accept our terms.
            </p>
            <label class="form-label">Email:</label>
          `
        : nothing}
      <input
        class="form-control"
        name="email"
        value=${this.email}
        ?disabled=${this.saving || this.action === 'invite'}
        @input=${(e) => (this.email = e.target.value)}
      />
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        ${this.action === 'invite' ? 'Send invitiation' : 'Update'}
      </button>
    `;
  }
}

customElements.define('modal-account-email', ModalAccountEmail);
