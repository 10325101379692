import { html } from 'lit';
import { request } from '../../lib/request.js';
import { ModalElement } from '../modal/base.js';

class ModalNotificationEmail extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    emails: { state: true },
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Notification Email(s)';
    this.account = '';
    this.emails = [];
    this.saving = false;
  }

  async onSave() {
    this.saving = true;
    const updated = await request(
      'PATCH',
      `/connect-accounts/${this.account}`,
      {
        notifications: {
          emails_default: this.emails.filter((e) => e.trim())
        }
      }
    );
    this.emit('updated', updated);
    this.close();
  }

  renderBody() {
    return html`
      <p>These email addresses are used for sending delivery notifications.</p>
      <p>The first one is also used as the primary contact email.</p>

      <div class="alert alert-info mb-3">
        <strong>Primary contact email:</strong> ${this.emails[0]}
      </div>

      <label class="form-label">Email(s):</label>
      <shared-multi-input
        .values=${this.emails}
        .config=${{ move: true, remove: true }}
        @change=${() => this.requestUpdate()}
      >
      </shared-multi-input>
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Update
      </button>
    `;
  }
}

customElements.define('modal-notification-email', ModalNotificationEmail);
