import { html } from 'lit';
import { request } from '../../lib/request.js';
import spinner from '../../html/spinner.js';
import { ModalElement } from '../modal/base.js';

class ModalNewAccount extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    first_name: {},
    last_name: {},
    email: {},
    company: {},
    address: {},
    lang: {}
  };

  constructor() {
    super();
    this.title = 'New Account';
    this.first_name = '';
    this.last_name = '';
    this.email = '';
    this.company = null;
    this.address = { country: 'CH' };
    this.lang = 'de';
    this.processing = false;
  }

  input(name) {
    return (event) => {
      this[name] = event.target.value;
    };
  }

  inputAddress(property) {
    return (event) => {
      this.address[property] = event.target.value;
    };
  }

  renderBody() {
    if (this.processing) {
      return spinner();
    }
    return html`
      <div class="hstack gap-2 mb-3">
        ${this.renderInput('email', 'Email', 'w-75')}
        ${this.renderLangSelector()}
      </div>
      <div class="hstack gap-2 mb-3">
        ${this.renderInput('first_name', 'First Name', 'w-50')}
        ${this.renderInput('last_name', 'Last Name', 'w-50')}
      </div>
      ${this.renderInput('company', 'Company')} ${this.renderAddressInput()}
    `;
  }

  renderInput(name, title, style = '') {
    return html`
      <div class="mt-2 ${style}">
        <label class="form-label">${title}:</label>
        <input
          type="text"
          class="form-control"
          autocomplete="off"
          name=${name}
          value=${this[name]}
          @change=${this.input(name)}
        />
      </div>
    `;
  }

  renderLangSelector() {
    return html`
      <div class="mt-2 w-25">
        <label class="form-label">Language:</label>
        <select class="form-select" @change=${this.input('lang')}>
          <option value="de">German</option>
          <option value="en">English</option>
        </select>
      </div>
    `;
  }

  renderAddressInput() {
    return html`
      <div class="mt-2">
        <label class="form-label">Address:</label>
        <div class="hstack gap-1 mb-2">
          <input
            type="text"
            class="form-control"
            autocomplete="off"
            value=${this.address.city}
            placeholder="City"
            @change=${this.inputAddress('city')}
          />
          <input
            type="text"
            class="form-control"
            autocomplete="off"
            value=${this.address.address1}
            placeholder="zip"
            @change=${this.inputAddress('zip')}
          />
        </div>
        <input
          type="text"
          class="form-control mb-2"
          autocomplete="off"
          value=${this.address.address1}
          placeholder="Address line 1"
          @change=${this.inputAddress('address1')}
        />
        <div class="hstack gap-1 mb-2">
          <input
            type="text"
            class="form-control"
            autocomplete="off"
            value=${this.address.address1}
            placeholder="Address line 2"
            @change=${this.inputAddress('address2')}
          />
          <shared-country-selector
            @change=${this.inputAddress('country')}
          ></shared-country-selector>
        </div>
      </div>
    `;
  }

  renderFooter() {
    const { processing, email, first_name, last_name, lang } = this;
    const disabled = processing || !email || !first_name || !last_name || !lang;
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.processing}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.createAccount}
        ?disabled=${disabled}
      >
        Create
      </button>
    `;
  }

  async createAccount() {
    const json = {
      email: this.email,
      first_name: this.first_name,
      last_name: this.last_name,
      lang: this.lang
    };
    if (this.company) {
      json.company = this.company;
    }
    if (isFilledAddress(this.address)) {
      json.address = this.address;
    }

    const account = await request('POST', '/accounts', json);

    this.emit('added', account);
    this.close();
  }
}

customElements.define('modal-new-account', ModalNewAccount);

function isFilledAddress(address) {
  return (
    address &&
    Object.values(address).filter((value) => Boolean(value.trim())).length > 1
  );
}
