import { html } from 'lit';
import { ModalElement } from '../../modal/base.js';
import '../select-connect-account.js';

class ModalAccountSupplierConnect extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: { attribute: true },
    supplier: { attribute: false },
    selected_account: { state: true }
  };

  constructor() {
    super();
    this.title = 'Select Connect Supplier';
    this.account = '';
    this.supplier = null;
    this.selected_account = null;
  }

  selectAccount(account) {
    this.selected_account = account;
  }

  renderBody() {
    return html`
      <select-connect-account @input=${(e) => this.selectAccount(e.detail)}>
      </select-connect-account>
    `;
  }

  renderFooter() {
    return html`
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSelect}
        ?disabled=${!this.selected_account}
      >
        Preview
      </button>
    `;
  }

  onSelect() {
    this.emit('select', this.selected_account);
    this.close();
  }
}

customElements.define(
  'modal-account-supplier-connect',
  ModalAccountSupplierConnect
);
