import { html } from 'lit';
import { ModalElement } from './base.js';

class ModalAlert extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    title: {},
    message: {}
  };

  constructor() {
    super();
    this.title = 'Ouch!';
    this.message = '';
    this.css = 'bg-danger text-white';
  }

  renderBody() {
    return this.message;
  }

  renderFooter() {
    return html`
      <button type="button" class="btn btn-light" @click=${this.close}>
        Okay!
      </button>
    `;
  }
}

customElements.define('modal-alert', ModalAlert);
