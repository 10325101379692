import { html } from 'lit';
import { BaseElement } from '../base.js';

export class ConnectAccountsList extends BaseElement {
  static properties = {
    items: { state: true }
  };

  constructor() {
    super();
    this.items = [];
  }

  render() {
    return html`
      <div class="list-group mb-5 pb-5">
        ${this.items.map(
          (item) => html`
            <a
              href="${item.account}"
              class="list-group-item list-group-item-action hstack"
            >
              <div class="me-auto">
                <i class="bi bi-truck me-2"></i>
                ${item.name}
              </div>
              <small class="d-none d-md-block text-muted ms-2">
                ${item.email}
              </small>
            </a>
          `
        )}
      </div>
    `;
  }
}

customElements.define('connect-accounts-list', ConnectAccountsList);
