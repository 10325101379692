import { html } from 'lit';
import { stringPropertyComparator } from '@yourbarmate/commons/string-comparator.js';
import { AccountSupplierListController } from '../../lib/controllers/account-supplier-list.js';
import { throttle } from '../../lib/throttle.js';
import { createFilter, createSorter } from '../../lib/search.js';
import { connectStatus } from '../../lib/supplier-integrations.js';
import dropdown from '../../html/dropdown.js';
import { paginated } from '../paginated.js';
import { BaseElement } from '../base.js';
import './new-supplier-modal.js';

export class AccountSuppliers extends paginated(BaseElement) {
  static properties = {
    account: {},
    query: { state: true },
    order_by: { state: true }
  };

  static sort_options = [
    {
      value: 'name',
      name: 'Order by name',
      comparator: stringPropertyComparator('name')
    },
    {
      value: 'created',
      name: 'Newest first',
      comparator: (a, b) => b.created - a.created
    }
  ];

  constructor() {
    super();
    this.account = '';
    this.suppliers = new AccountSupplierListController(this);
    this.suppliersFilter = createFilter(['name', 'email']);
    this.suppliersSorter = createSorter(AccountSuppliers.sort_options);
    this.query = '';
    this.order_by = 'name';
  }

  setQuery(query) {
    this.query = query;
    this.page_index = 0;
  }

  setOrderBy(order_by) {
    this.order_by = order_by;
    this.page_index = 0;
  }

  render() {
    return this.suppliers.render((items) => {
      const { query } = this;
      const sorted = query
        ? this.suppliersFilter(items, query)
        : this.suppliersSorter(items, this.order_by);
      const page = this.page(sorted);

      return html`
        <shared-search-bar
          @input=${throttle((event) => this.setQuery(event.target.value))}
          value=${this.query}
        >
          ${dropdown({
            icon: 'sort-down',
            options: AccountSuppliers.sort_options,
            selected: this.order_by,
            onSelect: (value) => this.setOrderBy(value),
            disabled: Boolean(query)
          })}
        </shared-search-bar>
        <div class="list-group mb-5 pb-5">
          ${page.map(
            (supplier) => html`
              <a
                href="suppliers/${supplier.created}/"
                class="list-group-item list-group-item-action d-flex align-items-center justify-content-between"
              >
                <div>
                  <i
                    class="bi bi-${supplier.archived
                      ? 'archive'
                      : 'truck'} me-1"
                  ></i>
                  ${supplier.name}
                </div>
                <div class="hstack gap-3">
                  ${supplier.connect
                    ? html`
                        <div class="badge text-bg-${connectStatus(supplier)}">
                          Connect
                        </div>
                      `
                    : null}
                  ${supplier.archived &&
                  html`<div class="badge text-bg-secondary">Deactivated</div>`}
                </div>
              </a>
            `
          )}
        </div>
        <div class="fixed-bottom p-3 bg-body shadow">
          <div class="container d-flex justify-content-between">
            <button
              class="btn btn-primary"
              @click=${() => this.newModal(items)}
            >
              Add New Supplier
            </button>
            <shared-pagination
              page-count=${this.getPageCount(items)}
              page-index=${this.page_index}
            ></shared-pagination>
          </div>
        </div>
      `;
    });
  }

  newModal(items) {
    this.emit(
      'modal:open',
      html`
        <modal-new-supplier
          account=${this.account}
          .suppliers=${items}
          @added=${({ detail }) => this.suppliers.add(detail)}
        ></modal-new-supplier>
      `
    );
  }
}

customElements.define('account-suppliers', AccountSuppliers);
