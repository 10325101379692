import { html } from 'lit';
import { Routes } from '@lit-labs/router';
import { ContextProvider } from '@lit-labs/context';
import { stringPropertyComparator } from '@yourbarmate/commons/string-comparator.js';
import { ConnectAccountsController } from '../../lib/controllers/connect-accounts.js';
import { throttle } from '../../lib/throttle.js';
import { router_context } from '../../lib/context.js';
import { createFilter, createSorter } from '../../lib/search.js';
import dropdown from '../../html/dropdown.js';
import { BaseElement } from '../base.js';
import { paginated } from '../paginated.js';
import './list.js';
import './sidebar.js';
import './status.js';
import './customers.js';
import './integrations.js';
import './uploads.js';
import './new-account-modal.js';

export class ConnectAccounts extends paginated(BaseElement) {
  static properties = {
    account: {},
    query: { state: true },
    order_by: { state: true }
  };

  static sort_options = [
    {
      value: 'name',
      name: 'Order by name',
      comparator: stringPropertyComparator('name')
    },
    {
      value: 'created',
      name: 'Newest first',
      comparator: (a, b) => b.created - a.created
    }
  ];

  constructor() {
    super();
    this.routes = new Routes(this, [
      {
        path: ':id/customers',
        render: ({ id }) => this.renderDetails(id, 'customers')
      },
      {
        path: ':id/notifications',
        render: ({ id }) => this.renderDetails(id, 'notifications')
      },
      {
        path: ':id/integrations',
        render: ({ id }) => this.renderDetails(id, 'integrations')
      },
      {
        path: ':id/uploads',
        render: ({ id }) => this.renderDetails(id, 'uploads')
      },
      {
        path: ':id',
        render: ({ id }) => this.renderDetails(id, 'status')
      },
      {
        path: '*',
        render: () => this.renderList()
      }
    ]);
    this.routerProvider = new ContextProvider(
      this,
      router_context,
      this.routes
    );
    this.accounts = new ConnectAccountsController(this);
    this.accountsFilter = createFilter(['name', 'email']);
    this.accountsSorter = createSorter(ConnectAccounts.sort_options);
    this.query = '';
    this.order_by = 'name';
  }

  /**
   * @param {string} query
   */
  setQuery(query) {
    this.query = query;
    this.page_index = 0;
  }

  /**
   * @param {string} order_by
   */
  setOrderBy(order_by) {
    this.order_by = order_by;
    this.page_index = 0;
  }

  newModal() {
    this.emit('modal:open', html`<modal-new-connect></modal-new-connect>`);
  }

  render() {
    return this.routes.outlet();
  }

  renderList() {
    return this.accounts.render((accounts) => {
      const { query } = this;
      const sorted = query
        ? this.accountsFilter(accounts, query)
        : this.accountsSorter(accounts, this.order_by);
      const page = this.page(sorted);

      return html`
        <h1>Connect</h1>
        <shared-search-bar
          @input=${throttle((event) => this.setQuery(event.target.value))}
          value=${query}
        >
          ${dropdown({
            icon: 'sort-down',
            options: ConnectAccounts.sort_options,
            selected: this.order_by,
            onSelect: (value) => this.setOrderBy(value),
            disabled: Boolean(query)
          })}
        </shared-search-bar>
        <connect-accounts-list .items=${page}></connect-accounts-list>
        <shared-actions-bar>
          <button class="btn btn-sm btn-primary" @click=${this.newModal}>
            Create New
          </button>
          <shared-pagination
            class="ms-auto"
            page-count=${this.getPageCount(sorted)}
            page-index=${this.page_index}
          ></shared-pagination>
        </shared-actions-bar>
      `;
    });
  }

  renderDetails(id, tab) {
    return this.accounts.render((accounts) => {
      const account = findAccount(accounts, id);
      let tab_content;
      switch (tab) {
        case 'customers':
          tab_content = html`
            <connect-account-customers
              account=${id}
              .item=${account}
            ></connect-account-customers>
          `;
          break;
        case 'notifications':
          tab_content = html`
            <connect-account-notifications
              .item=${account}
            ></connect-account-notifications>
          `;
          break;
        case 'integrations':
          tab_content = html`
            <connect-account-integrations .item=${account}>
            </connect-account-integrations>
          `;
          break;
        case 'uploads':
          tab_content = html`
            <connect-account-uploads account=${account.account}>
            </connect-account-uploads>
          `;
          break;
        case 'status':
        default:
          tab_content = html`
            <connect-account-status .item=${account}></connect-account-status>
          `;
      }
      return html`
        <div class="row">
          <div class="col-12 col-lg-5 col-xl-4 mb-4">
            <connect-account-sidebar
              .item=${account}
              tab=${tab}
            ></connect-account-sidebar>
          </div>
          <div class="col-12 col-lg-7 col-xl-8">${tab_content}</div>
        </div>
      `;
    });
  }
}

function findAccount(accounts, account) {
  return accounts.find((acc) => acc.account === account);
}

customElements.define('app-connect-accounts', ConnectAccounts);
