import { ContextConsumer } from '@lit-labs/context';
import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import {
  LS_USER_NAME,
  LS_AUTH_TOKEN,
  LS_LOGIN_REDIRECT
} from '../../lib/constants.js';
import { router_context } from '../../lib/context.js';
import { request } from '../../lib/request.js';
import { pushState } from '../../lib/route.js';
import { BaseElement } from '../base.js';

export class AppLogin extends BaseElement {
  static properties = {
    redirect: {},
    user: { state: true },
    password: { state: true },
    disabled: { state: true },
    invalid: { state: true }
  };

  constructor() {
    super();
    this.user = localStorage.getItem(LS_USER_NAME) || '';
    this.password = '';
    this.disabled = false;
    this.invalid = false;
    this.saved_user = Boolean(this.user);
    this.redirect = localStorage.getItem(LS_LOGIN_REDIRECT) || '/';
    this.router = new ContextConsumer(this, router_context);
  }

  async onSubmit(e) {
    e.preventDefault();

    this.disabled = true;
    this.invalid = false;

    const { user, password } = this;
    let res;
    try {
      res = await request('POST', '/login', { user, password });
    } catch (_e) {
      this.disabled = false;
      this.invalid = true;
      return;
    }

    localStorage.setItem(LS_AUTH_TOKEN, res.token);
    localStorage.setItem(LS_USER_NAME, user);
    localStorage.removeItem(LS_LOGIN_REDIRECT);

    pushState(this.router.value, this.redirect);
  }

  render() {
    const logo =
      document.documentElement.dataset.bsTheme === 'dark'
        ? '/img/logo-white-wide.png'
        : '/img/logo-black-wide.png';
    return html`
      <div class="card mb-5">
        <div class="card-header text-center">
          <img src="${logo}" class="img-fluid p-1 pb-0" />
        </div>
        <div class="card-body">
          <form class="vstack gap-3" @submit=${this.onSubmit}>
            <div class="input-group">
              <div class="input-group-text">
                <i class="bi bi-person-circle"></i>
              </div>
              <input
                class="form-control ${classMap({
                  'is-invalid': this.invalid
                })}"
                name="user"
                value=${this.user}
                ?disabled=${this.disabled}
                ?autofocus=${!this.saved_user}
                @input=${(e) => (this.user = e.target.value)}
              />
            </div>
            <div class="input-group">
              <div class="input-group-text">
                <i class="bi bi-key"></i>
              </div>
              <input
                class="form-control ${classMap({
                  'is-invalid': this.invalid
                })}"
                name="password"
                type="password"
                value=${this.password}
                ?disabled=${this.disabled}
                ?autofocus=${Boolean(this.saved_user)}
                @input=${(e) => (this.password = e.target.value)}
              />
            </div>
            <button
              type="submit"
              class="btn btn-primary"
              ?disabled=${this.disabled}
            >
              <i class="bi bi-door-open me-1"></i> Login
            </button>
          </form>
        </div>
      </div>
    `;
  }
}

customElements.define('app-login', AppLogin);
