import { Routes } from '@lit-labs/router';

/**
 * @param {Routes | undefined} routes
 * @param {string} pathname
 */
export function pushState(routes, pathname) {
  if (!routes) {
    throw new Error('Routes is undefined');
  }

  const full_pathname = routes.link(pathname);
  history.pushState({}, '', full_pathname);
  routes.goto(pathname);
}
