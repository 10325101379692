import { html, nothing } from 'lit';
import { request } from '../../lib/request.js';
import spinner from '../../html/spinner.js';
import { prepareDivision } from '../../lib/prepare-division.js';
import { ModalElement } from '../modal/base.js';
import './select-connect-account.js';

class ModalSetupDivisions extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: { attribute: true },
    locations: {},
    location_groups: {},
    divisions: {},
    selected: { state: true },
    available_locations: { state: true },
    is_preview: { state: true }
  };

  constructor() {
    super();
    this.title = 'Set up locations';
    this.size = 'lg';
    this.account = '';
    this.locations = [];
    this.location_groups = [];
    this.available_locations = [];
    this.selected = 0;
    this.divisions = [createDivision()];
    this.processing = false;
    this.is_preview = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.available_locations = [
      ...this.locations.filter(({ group }) => !group),
      ...this.location_groups
    ]
      .filter(({ archived }) => !archived)
      .map(({ code, id }) => code || id);
  }

  renderBody() {
    if (this.processing) {
      return spinner();
    }
    const current_division = this.divisions[this.selected];
    return this.is_preview
      ? this.renderPreview()
      : html`
          <div class="mb-3">
            ${this.divisions.map((division, i) =>
              this.divisionBadge(division, i)
            )}
            ${this.renderAddDivisionButton()}
          </div>
          <division-details
            .division=${current_division}
            @update=${() => this.requestUpdate()}
          ></division-details>
          <div class="p-2">
            <h6>Areas</h6>
            ${this.location_groups
              .filter(({ archived }) => !archived)
              .map((location) => this.locationRow(location))}
            ${this.locations
              .filter(({ archived, group }) => !archived && !group)
              .map((location) => this.locationRow(location))}
          </div>
        `;
  }

  renderPreview() {
    return html`
      <div class="my-3">
        ${this.divisions.map(
          (division) => html`
            <h6>${division.name}</h6>
            <ul>
              ${division.locations.map(
                ({ display_name, type }) => html`
                  <li><strong>${display_name}</strong> (${type})</li>
                `
              )}
            </ul>
          `
        )}
      </div>
    `;
  }

  divisionBadge(division, index) {
    const selected = this.selected;
    const bg = index === selected ? 'primary' : 'secondary';
    return html`
      <span
        class="badge text-bg-${bg} me-2 mb-2 fs-6 fw-light py-2"
        @click=${() => this.setSelected(index)}
        style="cursor: default"
      >
        ${division.name || 'New Location'}
        ${this.divisions.length > 1 && !division.locations.length
          ? html`
              <i
                role="button"
                class="bi bi-trash"
                @click=${(event) => {
                  event.stopPropagation();
                  this.removeDivision(index);
                }}
              ></i>
            `
          : nothing}
      </span>
    `;
  }

  renderAddDivisionButton() {
    return html`
      <button
        class="btn btn-sm btn-outline-secondary me-2 mb-2 mt-1"
        @click=${() => this.addDivision()}
      >
        <i class="bi bi-plus"></i>
      </button>
    `;
  }

  locationRow(location) {
    const { type, display_name } = location;
    const code = location.code || location.id;
    const is_group = Boolean(location.id);
    const current_division = this.divisions[this.selected];
    if (
      !this.available_locations.includes(code) &&
      !current_division.locations.some(hasCodeOrId(code))
    ) {
      return nothing;
    }
    return html`
      <div class="mb-2">
        <input
          class="form-check-input"
          type="checkbox"
          @input=${(event) => this.toggleLocation(event, location)}
          ?checked=${current_division.locations.some(hasCodeOrId(code))}
          value=${code}
          id=${code}
        />
        <label class="form-check-label" for="${code}">
          ${is_group ? nothing : html`<code class="ms-1">${code}</code>`}
          ${display_name}
          <span class="badge text-bg-secondary me-2">${type}</span>
        </label>
      </div>
    `;
  }

  toggleLocation(event, location) {
    const { checked } = event.target;
    const code = location.code || location.id;
    const current_division = this.divisions[this.selected];

    if (checked) {
      this.available_locations = this.available_locations.filter(
        (v) => v !== code
      );
      current_division.locations.push(location);
    } else {
      this.available_locations.push(code);
      current_division.locations = current_division.locations.filter(
        (loc) => !hasCodeOrId(code)(loc)
      );
    }
    this.requestUpdate();
  }

  renderFooter() {
    const { selected } = this;
    const current_division = this.divisions[selected];

    return html`
      ${this.is_preview
        ? html`
            <button
              type="button"
              class="btn btn-secondary"
              @click=${() => {
                this.is_preview = false;
              }}
            >
              Back
            </button>
          `
        : html`
            <div class="flex-grow-1">
              <button
                type="button"
                class="btn btn-sm btn-outline-secondary"
                @click=${() => this.setSelected(selected - 1)}
                ?disabled=${selected < 1}
              >
                <i class="bi bi-chevron-left"></i>
              </button>
              <span class="mx-2 text-secondary">
                ${selected + 1} / ${this.divisions.length}
              </span>
              <button
                type="button"
                class="btn btn-sm btn-outline-secondary"
                @click=${() => this.setSelected(selected + 1)}
                ?disabled=${selected === this.divisions.length - 1}
              >
                <i class="bi bi-chevron-right"></i>
              </button>
            </div>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              ?disabled=${this.processing}
            >
              Cancel
            </button>
          `}
      ${this.is_preview
        ? html`
            <button
              type="button"
              class="btn btn-primary"
              @click=${this.setupDivisions}
              ?disabled=${!current_division.name}
            >
              Set up
            </button>
          `
        : html`
            <button
              type="button"
              class="btn btn-primary"
              @click=${() => {
                this.is_preview = true;
              }}
              ?disabled=${!current_division.name ||
              this.available_locations.length}
            >
              Preview
            </button>
          `}
    `;
  }

  setSelected(index) {
    this.selected = index;
    this.requestUpdate();
  }

  addDivision() {
    this.divisions.push(createDivision());
    this.selected += 1;
    this.requestUpdate();
  }

  removeDivision(index) {
    this.divisions.splice(index, 1);
    if (index <= this.selected) {
      this.selected -= 1;
    }
    this.requestUpdate();
  }

  async setupDivisions() {
    const json = this.divisions.map((division) => {
      return {
        ...prepareDivision(division),
        locations: division.locations.map((l) => l.code || l.id)
      };
    });

    await request('POST', `/accounts/${this.account}/divisions/setup`, json);

    this.emit('done');
    this.close();
  }
}

function createDivision() {
  return { name: '', locations: /** @type {Object[]} */ ([]) };
}

function hasCodeOrId(code_or_id) {
  return (location) => (location.code || location.id) === code_or_id;
}

customElements.define('modal-setup-divisions', ModalSetupDivisions);
