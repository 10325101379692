import { ContextConsumer } from '@lit-labs/context';
import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { router_context } from '../../lib/context.js';
import { BaseElement } from '../base.js';

export class ConnectAccountSidebar extends BaseElement {
  static properties = {
    tab: {},
    item: { state: true }
  };

  constructor() {
    super();
    this.tab = '';
    this.item = null;
    this.router = new ContextConsumer(this, router_context);
  }

  render() {
    if (!this.item) {
      return nothing;
    }
    const { item } = this;
    const tabs = [
      { href: item.account, tab: 'status', name: 'Status' },
      {
        href: `${item.account}/integrations`,
        tab: 'integrations',
        name: 'Integrations'
      },
      {
        href: `${item.account}/uploads`,
        tab: 'uploads',
        name: 'Uploads'
      },
      {
        href: `${item.account}/customers`,
        tab: 'customers',
        name: 'Customers'
      }
    ];
    return html`
      <div class="card">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="display-5 me-2"><i class="bi bi-truck me-2"></i></div>
          <div class="text-truncate flex-grow-1">
            <h3 class="text-truncate">${item.name}</h3>
            <p class="mb-1">${item.email}</p>
          </div>
        </div>
        <div class="list-group list-group-flush">
          ${tabs.map(({ href, tab, name }) => {
            return html`
              <a
                href="${this.router.value?.link(href)}"
                class="list-group-item list-group-item-action ${classMap({
                  active: this.tab === tab
                })}"
              >
                ${name}
              </a>
            `;
          })}
        </div>
      </div>
    `;
  }
}

customElements.define('connect-account-sidebar', ConnectAccountSidebar);
