import { html } from 'lit';
import { deepCopy } from '../../../lib/deep-copy.js';
import { request } from '../../../lib/request.js';
import { ModalElement } from '../../modal/base.js';

class ModalAccountSupplierDeliveries extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    supplier: {},
    changes: { state: true },
    saving: { state: true }
  };

  constructor() {
    super();
    this.size = 'lg';
    this.title = 'Supplier Delivery Details';
    this.supplier = null;
    this.changes = {};
    this.account = '';
    this.saving = false;
  }

  async onSave() {
    this.saving = true;
    const request_body = this.changes;
    if (
      request_body.delivery_days &&
      !Object.keys(request_body.delivery_days).length
    ) {
      request_body.delivery_days = null;
    }
    if (
      request_body.dates_with_no_delivery &&
      !request_body.dates_with_no_delivery.length
    ) {
      request_body.dates_with_no_delivery = null;
    }
    const updated = await request(
      'PATCH',
      `/accounts/${this.account}/suppliers/${this.supplier.created}`,
      request_body
    );
    this.emit('updated', updated);
    this.changes = {};
    this.close();
  }

  render() {
    this.title = `${this.supplier.name} delivery details`;
    return super.render();
  }

  renderBody() {
    return html`
      <div>${this.renderDeliveryDays()}</div>
      <div class="my-3">${this.renderDatesWithNoDelivery()}</div>
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
        @click=${() => {
          this.changes = {};
        }}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Update
      </button>
    `;
  }

  renderDeliveryDays() {
    return html`
      <h5>Delivery Settings</h5>
      <shared-delivery-days-editor
        .delivery_days=${deepCopy(this.getChangedValue('delivery_days'))}
        @delivery_days:toggleDay=${(e) => this.toggleDeliveryDay(e.detail)}
        @delivery_days:setDayValue=${(e) => this.setDeliveryDayValue(e.detail)}
      ></shared-delivery-days-editor>
    `;
  }

  renderDatesWithNoDelivery() {
    const no_delivery = this.getChangedValue('dates_with_no_delivery') || [];
    return html`
      <h5>Dates with no delivery</h5>
      <input
        class="form-control my-1"
        name="customer-id"
        value=${no_delivery.join(', ')}
        autocomplete="off"
        @change=${(e) =>
          (this.changes.dates_with_no_delivery = e.target.value
            .split(',')
            .map((s) => s.trim())
            .filter(Boolean))}
      />
    `;
  }

  change(key) {
    if (!this.changes[key]) {
      this.changes[key] = deepCopy(this.supplier.connect[key] || {});
    }
    return this.changes[key];
  }

  getChangedValue(key) {
    return Object.hasOwn(this.changes, key)
      ? this.changes[key]
      : this.supplier.connect[key];
  }

  toggleDeliveryDay(index) {
    const delivery_days = this.change('delivery_days');
    if (delivery_days[index]) {
      delete delivery_days[index];
    } else {
      delivery_days[index] = { lead_days: 1 };
    }
    this.requestUpdate();
  }

  setDeliveryDayValue({ index, key, value }) {
    this.change('delivery_days')[index][key] =
      key === 'lead_days' ? Number(value) : value || undefined;

    this.requestUpdate();
  }
}

customElements.define(
  'modal-account-supplier-deliveries',
  ModalAccountSupplierDeliveries
);
