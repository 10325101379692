import { html, nothing } from 'lit';
import { formatRelativeDate } from '@yourbarmate/commons/date-and-time/index.js';
import { BaseElement } from '../base.js';
import { request } from '../../lib/request.js';
import './modal-email.js';
import './modal-password.js';
import './modal-stripe.js';
import './modal-stripe-subscription.js';

const stripe_dashboard = 'https://dashboard.stripe.com';
const feature_flags = [
  { id: 'SETUP_LOCATIONS', name: 'Setup areas' },
  { id: 'SETUP_DIVISIONS', name: 'Setup locations' },
  { id: 'API', name: 'Use API' },
  { id: 'MIGRATED', name: 'Migrated to Stock-pools' },
  { id: 'RETURNS', name: 'Shop returns' }
];
const app_features = [
  { id: 'SHOP', name: 'Shop' },
  { id: 'STOCK_TRACKING', name: 'Stock tracking' },
  { id: 'INSPECTIONS', name: 'Inspections' },
  { id: 'INTERNAL_ORDERS', name: 'Internal Orders' }
];
const cache_scopes = [
  { scope: '', name: 'All' },
  { scope: 'items', name: 'Items' }
];

export class AccountStatus extends BaseElement {
  static properties = {
    item: { state: true },
    updating: { state: true },
    stripe_info: { state: true }
  };

  constructor() {
    super();
    this.item = null;
    this.stripe_info = null;
  }

  connectedCallback() {
    super.connectedCallback();
    this.loadStripeInfo();
  }

  async loadStripeInfo() {
    if (!this.item || this.item.principals) {
      return;
    }
    const { account } = this.item;
    this.stripe_info = { subscription_status: 'LOADING' };
    try {
      this.stripe_info = await request('GET', `/accounts/${account}/stripe`);
    } catch (error) {
      this.stripe_info = { subscription_status: 'ERROR', error };
    }
  }

  async onLogin() {
    const { account, email, features } = this.item;
    const { token } = await request('POST', `/accounts/${account}/login`, {});
    const subdomain = features.includes('MIGRATED') ? 'app.' : '';
    const hostname = location.hostname
      .replace('admin.', subdomain)
      .replace('localhost', 'localhost:8081');
    window.open(
      `${location.protocol}//${hostname}/login/token/${token}?email=${email}`
    );
  }

  async patchAccount(body) {
    this.updating = true;
    const updated = await request(
      'PATCH',
      `/accounts/${this.item.account}`,
      body
    );
    Object.assign(this.item, updated);
    this.updating = false;
  }

  onCreateStripeCustomer() {
    this.emit('modal:confirm', {
      description: `Do you really want to create a new Stripe customer?`,
      confirm: async () => {
        this.updating = true;
        const stripe = await request(
          'POST',
          `/accounts/${this.item.account}/stripe/customer`,
          {}
        );
        Object.assign(this.item, { stripe });
        this.updating = false;
      }
    });
  }

  onCreateStripeSubscription() {
    const { account } = this.item;
    this.emit(
      'modal:open',
      html`
        <modal-stripe-subscription
          account="${account}"
          .stripe_info="${this.stripe_info}"
          @updated=${(e) => {
            Object.assign(this.item, { stripe: e.detail });
            this.loadStripeInfo();
          }}
        ></modal-stripe-subscription>
      `
    );
  }

  onToggleStatus() {
    const { status, terms_accepted } = this.item;
    let new_status;
    let description;
    switch (true) {
      case status === 'ACTIVE' || status === 'PENDING':
        new_status = 'INACTIVE';
        description = 'Do you really want to DEACTIVATE this account?';
        break;
      case status === 'INACTIVE' && Boolean(terms_accepted):
        new_status = 'ACTIVE';
        description = 'Do you really want to ACTIVATE this account?';
        break;
      case status === 'INACTIVE' && !terms_accepted:
        new_status = 'PENDING';
        description =
          'Do you really want to set this account to PENDING again?';
        break;
      default:
        return;
    }
    this.emit('modal:confirm', {
      description,
      confirm: async () => {
        await this.patchAccount({ status: new_status });
      }
    });
  }

  onRemoveEmailChangeRequest() {
    this.emit('modal:confirm', {
      description: `Do you really want to remove the email change request?`,
      confirm: async () => {
        await this.patchAccount({ email_change_request: null });
      }
    });
  }

  onRemoveResetPassword() {
    this.emit('modal:confirm', {
      description: `Do you really want to remove the reset password request?`,
      confirm: async () => {
        await this.patchAccount({ reset_password: null });
      }
    });
  }

  async onToggleFeatureFlag(feature) {
    this.updating = true;
    const updated = await request(
      this.item.features.includes(feature) ? 'DELETE' : 'POST',
      `/accounts/${this.item.account}/features`,
      { feature }
    );
    Object.assign(this.item, updated);
    this.updating = false;
  }

  onChangeEmail() {
    const { account, email } = this.item;
    this.emit(
      'modal:open',
      html`
        <modal-account-email
          account="${account}"
          email="${email}"
          action="update"
          @updated=${(e) => {
            Object.assign(this.item, e.detail);
            this.requestUpdate();
          }}
        ></modal-account-email>
      `
    );
  }

  onInviteUser() {
    const { account, email } = this.item;
    this.emit(
      'modal:open',
      html`
        <modal-account-email
          account="${account}"
          email="${email}"
          action="invite"
          @updated=${(e) => {
            Object.assign(this.item, e.detail);
            this.requestUpdate();
          }}
        ></modal-account-email>
      `
    );
  }

  onChangePassword() {
    const { account } = this.item;
    this.emit(
      'modal:open',
      html`
        <modal-account-password
          account="${account}"
          @updated=${(e) => {
            Object.assign(this.item, e.detail);
            this.requestUpdate();
          }}
        ></modal-account-password>
      `
    );
  }

  onChangeStripe() {
    const { account, stripe } = this.item;
    this.emit(
      'modal:open',
      html`
        <modal-account-stripe
          account="${account}"
          customer_id="${stripe?.customer_id}"
          subscription_id="${stripe?.subscription_id}"
          @updated=${(e) => {
            Object.assign(this.item, e.detail);
            this.requestUpdate();
          }}
        ></modal-account-stripe>
      `
    );
  }

  onFlushCaches(index) {
    const { name, scope } = cache_scopes[index];
    this.emit('modal:confirm', {
      description: `Do you really want to flush “${name}”?`,
      confirm: async () => {
        this.updating = true;
        await request(
          'DELETE',
          `/accounts/${this.item.account}/caches${scope ? `/${scope}` : ''}`
        );
        this.updating = false;
      }
    });
  }

  render() {
    if (!this.item) {
      return nothing;
    }

    const {
      status,
      account,
      confirmation,
      terms_accepted,
      principals,
      email,
      lang
    } = this.item;
    const hostname = location.hostname.replace('admin.', '');
    const invite_link = `${location.protocol}//${hostname}/welcome/${confirmation}?email=${email}&lang=${lang}`;
    const is_active = status === 'ACTIVE';
    const is_inactive = status === 'INACTIVE';
    return html`
      <div
        class="alert alert-${is_active
          ? 'success'
          : is_inactive
            ? 'danger'
            : 'warning'} hstack gap-3"
      >
        <div class="me-auto">
          <i
            class="bi ${is_active
              ? 'bi-check2-circle'
              : 'bi-exclamation-circle-fill'} me-1"
          ></i>
          ${status}
        </div>
        <button
          class="btn btn-sm btn-secondary"
          ?disabled=${this.updating}
          @click=${this.onToggleStatus}
        >
          ${is_inactive
            ? terms_accepted
              ? 'Activate'
              : 'Pending'
            : 'Deactivate'}
        </button>
        ${!is_inactive
          ? html`
              <button class="btn btn-sm btn-success" @click=${this.onLogin}>
                Login
              </button>
            `
          : null}
      </div>
      <div class="my-4">
        ID: <shared-copy-id id=${account}></shared-copy-id>
      </div>
      ${!terms_accepted && confirmation && !principals
        ? html`
            <div class="alert alert-warning my-4">
              Invite sent with link:
              <shared-copy-id id=${invite_link}></shared-copy-id>
            </div>
          `
        : nothing}
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-1 row-cols-xl-2 g-4">
        ${this.renderEmailCard()} ${this.renderEmailChangeRequestCard()}
        ${this.renderPasswordCard()} ${this.renderPasswordChangeRequestCard()}
        ${this.renderStripeCard()} ${this.renderFlushCachesCard()}
        ${this.renderFeatureFlagsCard()}
      </div>
    `;
  }

  renderEmailCard() {
    const { confirmation, email, email_failed, principals, terms_accepted } =
      this.item;
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack gap-3">
            <h5 class="mb-0 me-auto">Email</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              ?disabled=${this.updating}
              @click=${this.onChangeEmail}
            >
              Change
            </button>
            ${!terms_accepted && !confirmation && !principals
              ? html`
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    ?disabled=${this.updating}
                    @click=${this.onInviteUser}
                  >
                    <i class="bi bi-envelope me-1"></i>
                    Invite
                  </button>
                `
              : nothing}
          </div>
          <div class="card-body hstack">
            <a class="text-decoration-none" href="mailto:${email}">${email}</a>
            ${email_failed
              ? html`<div class="badge text-bg-danger ms-auto">Failed</div>`
              : nothing}
          </div>
        </div>
      </div>
    `;
  }

  renderEmailChangeRequestCard() {
    const { email_change_request } = this.item;
    if (!email_change_request) {
      return null;
    }
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack">
            <h5 class="mb-0 me-auto">Change email request</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-danger"
              ?disabled=${this.updating}
              @click=${this.onRemoveEmailChangeRequest}
            >
              Remove
            </button>
          </div>
          <div class="card-body">
            <span class="badge text-bg-info me-2">
              ${email_change_request.email}
            </span>
            <span class="text-nowrap">
              ${formatRelativeDate(email_change_request.date, 'en')}
            </span>
          </div>
        </div>
      </div>
    `;
  }

  renderPasswordCard() {
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack">
            <h5 class="mb-0 me-auto">Password</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              ?disabled=${this.updating}
              @click=${this.onChangePassword}
            >
              Change
            </button>
          </div>
          <div class="card-body">•••••</div>
        </div>
      </div>
    `;
  }

  renderPasswordChangeRequestCard() {
    const { reset_password } = this.item;
    if (!reset_password) {
      return null;
    }
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack">
            <h5 class="mb-0 me-auto">Reset password request</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-danger"
              ?disabled=${this.updating}
              @click=${this.onRemoveResetPassword}
            >
              Remove
            </button>
          </div>
          <div class="card-body">
            <span class="badge text-bg-info me-2">${reset_password.type}</span>
            ${formatRelativeDate(reset_password.ts, 'en')}
          </div>
        </div>
      </div>
    `;
  }

  renderStripeCard() {
    if (this.item.principals) {
      return nothing;
    }
    const { stripe } = this.item;
    const customer_link = `${stripe_dashboard}/customers/${stripe?.customer_id}`;
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack gap-3">
            <h5 class="mb-0 me-auto">Stripe</h5>
            ${stripe
              ? html`
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-secondary"
                    ?disabled=${this.updating}
                    @click=${this.onChangeStripe}
                  >
                    Update
                  </button>
                `
              : nothing}
          </div>
          ${stripe?.customer_id
            ? html`
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <a
                      class="text-decoration-none"
                      href="${customer_link}"
                      target="_blank"
                    >
                      ${stripe?.customer_id}
                    </a>
                  </li>
                  ${this.renderStripeSubscription(stripe)}
                </ul>
              `
            : html`
                <div
                  class="d-flex p-3 justify-content-between align-items-center"
                >
                  <span>No active Stripe customer.</span>
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-secondary"
                    ?disabled=${this.updating}
                    @click=${this.onCreateStripeCustomer}
                  >
                    Create
                  </button>
                </div>
              `}
        </div>
      </div>
    `;
  }

  renderStripeSubscription(stripe) {
    const subscription_link = `${stripe_dashboard}/subscriptions/${stripe?.subscription_id}`;

    return stripe?.subscription_id
      ? html`
          <li class="list-group-item hstack">
            <a
              class="text-decoration-none me-auto text-truncate"
              href="${subscription_link}"
            >
              ${stripe?.subscription_id}
            </a>
            ${this.renderStripeSubscriptionStatus()}
          </li>
        `
      : html`
          <li class="list-group-item">
            <div class="d-flex justify-content-between align-items-center">
              <span>No active Stripe subscription.</span>
              <button
                type="button"
                class="btn btn-sm btn-outline-secondary"
                ?disabled=${this.updating}
                @click=${this.onCreateStripeSubscription}
              >
                Create
              </button>
            </div>
          </li>
        `;
  }

  renderStripeSubscriptionStatus() {
    const { subscription_status, error } = this.stripe_info || {};
    switch (subscription_status) {
      case 'LOADING':
        return html`<div class="spinner-border spinner-border-sm"></div>`;
      case 'ERROR':
        return html`
          <shared-tooltip>
            <div
              class="badge text-bg-danger"
              data-bs-toggle="tooltip"
              data-bs-title="${error}"
            >
              <i class="bi bi-info-circle me-1"></i> Error
            </div>
          </shared-tooltip>
        `;
      case null:
        return null;
      default:
        return html`
          <div class="badge text-bg-secondary">${subscription_status}</div>
        `;
    }
  }

  renderFeatureFlagsCard() {
    const { principals, features } = this.item;
    if (principals) {
      return null;
    }
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="my-1 me-auto">Feature Flags</h5>
          </div>
          <ul class="list-group list-group-flush">
            ${feature_flags.map(
              (flag) => html`
                <li class="list-group-item">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="${flag.id}"
                      id="${flag.id}"
                      ?checked=${features.includes(flag.id)}
                      ?disabled=${this.updating}
                      @change=${() => this.onToggleFeatureFlag(flag.id)}
                    />
                    <label class="form-check-label" for="${flag.id}">
                      ${flag.name}
                    </label>
                  </div>
                  ${flag.id === 'MIGRATED' && features.includes('MIGRATED')
                    ? this.renderAppFeatures()
                    : null}
                </li>
              `
            )}
          </ul>
        </div>
      </div>
    `;
  }

  renderAppFeatures() {
    return html`
      <ul class="list-group my-2">
        ${app_features.map(
          (flag) => html`
            <li class="list-group-item">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="${flag.id}"
                  id="${flag.id}"
                  ?checked=${this.item.features.includes(flag.id)}
                  ?disabled=${this.updating}
                  @change=${() => this.onToggleFeatureFlag(flag.id)}
                />
                <label class="form-check-label" for="${flag.id}">
                  ${flag.name}
                </label>
              </div>
            </li>
          `
        )}
      </ul>
    `;
  }

  renderFlushCachesCard() {
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack gap-3">
            <h5 class="my-1 me-auto">Flush Caches</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              ?disabled=${this.updating}
              @click=${() => this.onFlushCaches(0)}
            >
              Flush
            </button>
          </div>
          <ul class="list-group list-group-flush">
            ${cache_scopes.slice(1).map(
              ({ name }, index) => html`
                <li class="list-group-item hstack">
                  <div class="me-auto" alt="scope">${name}</div>
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-secondary"
                    ?disabled=${this.updating}
                    @click=${() => this.onFlushCaches(index + 1)}
                  >
                    Flush
                  </button>
                </li>
              `
            )}
          </ul>
        </div>
      </div>
    `;
  }
}

customElements.define('account-status', AccountStatus);
