function filterByFields(item, fields, query) {
  for (const field of fields) {
    if (
      item[field] &&
      item[field].toLowerCase().includes(query.toLowerCase())
    ) {
      return true;
    }
  }
  return false;
}

function sortByFields(fields, query) {
  return (a, b) => {
    const score_a = matchScore(a, fields, query);
    const score_b = matchScore(b, fields, query);

    if (score_a === score_b) {
      return a > b ? -1 : 1;
    }

    return score_a - score_b;
  };
}

function matchScore(item, fields, query) {
  let score = Infinity;
  let i = 0;
  for (const field of fields) {
    const field_score = item[field]
      ? item[field].toLowerCase().indexOf(query.toLowerCase()) + i
      : -1;
    if (field_score > -1 && field_score < score) {
      score = field_score;
    }
    i += 100;
  }
  return score;
}

export function createFilter(fields) {
  return (list, query) => {
    if (query) {
      return list
        .filter((item) => filterByFields(item, fields, query))
        .sort(sortByFields(fields, query));
    }
    return list;
  };
}

export function createSorter(sort_options) {
  return (items, order_by) => {
    const comparator = sort_options.find(
      (option) => option.value === order_by
    )?.comparator;
    return comparator ? items.slice().sort(comparator) : items;
  };
}
