import { html } from 'lit';
import { request } from '../../lib/request.js';
import { ModalElement } from '../modal/base.js';
import { deepCopy } from '../../lib/deep-copy.js';
import renderOptions from '../../html/options.js';

const import_profiles = [
  'bianchi-csv',
  'icit-csv',
  'marinello-csv',
  'metzgerei-keller-csv',
  'pistor-csv',
  'pistor-csv-2',
  'saviva-csv',
  'vergani-csv',
  'ybm-csv',
  'ybm-csv-2',
  'ybm-csv-3'
];

const encodings = ['utf8', 'latin1'];
const delimiters = ['default', ',', ';'];
const quotes = ['default', 'yes', 'no'];

class ModalSftpImport extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    sftp: {},
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Item Import';
    this.account = '';
    this.sftp = {};
    this.saving = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.sftp = deepCopy(this.sftp);
    if (!this.sftp.import_config) {
      this.sftp.import_config = {};
    }
    if (!this.sftp.import_config.protocol) {
      this.sftp.import_config.protocol = {};
    }
  }

  async onSave() {
    this.saving = true;

    const { remote_file, encoding, profile, protocol, pricing } =
      this.sftp.import_config;
    const json = {
      remote_file,
      profile: profile || 'ybm-csv-3'
    };
    if (Object.keys(protocol).length) {
      json.protocol = protocol;
    }
    if (encoding) {
      json.encoding = encoding;
    }
    if (pricing) {
      json.pricing = pricing;
    }
    const response = await request(
      'PUT',
      `/connect-accounts/${this.account}/sftp/import`,
      json
    );
    this.emit('updated', response);
    this.close();
  }

  async onRemove() {
    this.saving = true;

    await request('PUT', `/connect-accounts/${this.account}/sftp/import`, {});
    this.emit('updated', undefined);
    this.close();
  }

  onUpdateField(e) {
    this.sftp.import_config[e.target.name] = e.target.value;
    this.requestUpdate();
  }

  onUpdateProtocolField(e) {
    const { name, value } = e.target;
    const { protocol } = this.sftp.import_config;
    if (value === 'default') {
      delete protocol[name];
    } else {
      protocol[name] = name === 'delimiter' ? value : value === 'yes';
    }
    this.requestUpdate();
  }

  renderBody() {
    const {
      remote_file,
      encoding,
      profile,
      protocol = {}
    } = this.sftp.import_config;
    return html`
      <div class="row">
        <label class="col-4 col-form-label">Remote file</label>
        <div class="col-8">
          <input
            type="text"
            class="form-control"
            name="remote_file"
            .value=${remote_file || ''}
            @input=${this.onUpdateField}
          />
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Encoding</label>
        <div class="col-3">
          <select
            class="form-select"
            name="encoding"
            @change=${this.onUpdateField}
          >
            ${renderOptions(encodings, encoding || 'utf8')}
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Profile</label>
        <div class="col-8">
          <select
            class="form-select"
            name="profile"
            @change=${this.onUpdateField}
          >
            ${renderOptions(import_profiles, profile || 'ybm-csv-3')}
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Delimiter</label>
        <div class="col-3">
          <select
            class="form-select"
            name="delimiter"
            @change=${this.onUpdateProtocolField}
          >
            ${renderOptions(delimiters, protocol.delimiter || 'default')}
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Quotes</label>
        <div class="col-3">
          <select
            class="form-select"
            name="quotes"
            @change=${this.onUpdateProtocolField}
          >
            ${renderOptions(
              quotes,
              protocol.quotes === undefined
                ? 'Default'
                : protocol.quotes
                  ? 'yes'
                  : 'no'
            )}
          </select>
        </div>
      </div>
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-outline-danger me-auto"
        @click=${this.onRemove}
        ?disabled=${this.saving}
      >
        Remove
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Save
      </button>
    `;
  }
}

customElements.define('modal-sftp-import', ModalSftpImport);
