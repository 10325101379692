import { html } from 'lit';

export default function (message) {
  return html`
    <div class="alert alert-info mb-4">
      <i class="bi bi-info-circle me-1"></i>
      ${message}
    </div>
  `;
}
