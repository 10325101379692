import { parsePriceAsFloat } from '@yourbarmate/parsers';
import { formatPrice } from '@yourbarmate/commons/price.js';
import { url_safe_id } from '@yourbarmate/schema-validators/url-safe-id/index.js';

/**
 * @typedef ParserError
 * @property {number} line
 * @property {string} message
 * @property {string} [raw]
 */

const columns = [
  url_safe_id.verify, // price_group or customer_id
  url_safe_id.verify, // product_id
  columnPrice // price
];

function columnPrice(value) {
  return formatPrice(parsePriceAsFloat(value));
}

/**
 * @typedef {Array<string | number | undefined>} ParsedRow
 */

/**
 * @param {string} raw
 * @returns {{ headings: string[], parsed: ParsedRow[], errors: ParserError[] }}
 */
export function parseConnectPrices(raw) {
  /** @type {string[]} */
  const headings = [];
  /** @type {ParsedRow[]} */
  const parsed = [];
  /** @type {Object[]} */
  const errors = [];
  const seen_ids = new Set();

  let line = 0;
  const rows = raw.split('\n');
  const column_count = rows[0].split('\t').length;

  if (column_count !== columns.length) {
    errors.push({
      line,
      message: `Unexpected number of columns (${column_count})`
    });
    return { headings, parsed, errors };
  }
  if (
    rows[0].startsWith('Price Group\t') ||
    rows[0].startsWith('Customer ID\t')
  ) {
    line += 1;
    headings.push(.../** @type {string} */ (rows.shift()).split('\t'));
  }

  for (const row of rows) {
    line += 1;
    const raw_cells = row.split('\t');
    if (raw_cells.every(isEmptyString)) {
      continue;
    }
    if (raw_cells.length !== columns.length) {
      errors.push({
        line,
        message: `Unexpected number of columns (${raw_cells.length})`,
        raw: raw_cells.join(', ')
      });
    }
    /** @type {ParsedRow} */
    const cells = [];
    let valid = true;
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      const raw_cell = raw_cells[i];
      try {
        cells.push(column(raw_cell));
      } catch (e) {
        valid = false;
        cells.push(raw_cell);
        errors.push({
          line,
          message: /** @type {Error} */ (e).message,
          raw: raw_cells.join(', ')
        });
      }
    }
    if (valid) {
      const id = `${cells[0]}:${cells[1]}`;
      if (seen_ids.has(id)) {
        errors.push({
          line,
          message: 'Duplicate Product ID',
          raw: raw_cells.join(', ')
        });
      } else {
        seen_ids.add(id);
      }
    }
    parsed.push(cells);
  }

  return { headings, parsed, errors };
}

function isEmptyString(str) {
  return str.length === 0;
}
