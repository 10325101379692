import { html } from 'lit';
import { request } from '../../lib/request.js';
import { ModalElement } from '../modal/base.js';
import { deepCopy } from '../../lib/deep-copy.js';
import renderOptions from '../../html/options.js';

const protocols = ['sftp:', 'ftp:'];

class ModalSftpServer extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    sftp: {},
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = '(S)FTP Server';
    this.account = '';
    this.sftp = {};
    this.saving = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.sftp = deepCopy(this.sftp);
  }

  getDefaultPort() {
    return this.sftp.protocol === 'ftp:' ? 21 : 22;
  }

  async onSave() {
    this.saving = true;

    const json = {
      hostname: this.sftp.hostname,
      port: Number(this.sftp.port || this.getDefaultPort()),
      username: this.sftp.username || 'yourbarmate',
      password: this.sftp.password || ''
    };
    if (this.sftp.protocol && this.sftp.protocol !== 'sftp:') {
      json.protocol = this.sftp.protocol;
    }
    const response = await request(
      'PUT',
      `/connect-accounts/${this.account}/sftp`,
      json
    );
    this.emit('updated', response);
    this.close();
  }

  async onRemove() {
    this.saving = true;

    const response = await request(
      'PUT',
      `/connect-accounts/${this.account}/sftp`,
      {}
    );
    this.emit('updated', response);
    this.close();
  }

  onUpdateField(e) {
    this.sftp[e.target.name] = e.target.value;
    this.requestUpdate();
  }

  renderBody() {
    const { protocol, hostname, port, username, password } = this.sftp;
    return html`
      <div class="row">
        <label class="col-4 col-form-label">Protocol</label>
        <div class="col-3">
          <select
            class="form-select"
            name="protocol"
            @change=${this.onUpdateField}
          >
            ${renderOptions(protocols, protocol || 'sftp:')}
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Hostname</label>
        <div class="col-8">
          <input
            type="text"
            class="form-control"
            name="hostname"
            .value=${hostname || ''}
            @input=${this.onUpdateField}
          />
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Port</label>
        <div class="col-3">
          <input
            type="text"
            class="form-control"
            name="port"
            placeholder="${this.getDefaultPort()}"
            .value=${port || ''}
            @input=${this.onUpdateField}
          />
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Username</label>
        <div class="col-8">
          <input
            type="text"
            class="form-control"
            placeholder="yourbarmate"
            name="username"
            .value=${username || ''}
            @input=${this.onUpdateField}
          />
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Password</label>
        <div class="col-8">
          <input
            type="password"
            class="form-control"
            name="password"
            .value="${password || ''}"
            @input=${this.onUpdateField}
          />
        </div>
      </div>
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-outline-danger me-auto"
        @click=${this.onRemove}
        ?disabled=${this.saving}
      >
        Remove
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Save
      </button>
    `;
  }
}

customElements.define('modal-sftp-server', ModalSftpServer);
