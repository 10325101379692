import { html } from 'lit';
import { stringify } from 'csv-stringify/sync';
import { BaseElement } from '../base.js';
import { request } from '../../lib/request.js';
import { parseConnectProducts } from '../../lib/parse-connect-products.js';
import { parseConnectPrices } from '../../lib/parse-connect-prices.js';
import alertSuccess from '../../html/alert-success.js';
import alertError from '../../html/alert-error.js';
import spinner from '../../html/spinner.js';
import renderOptions from '../../html/options.js';
import { paginated } from '../paginated.js';

const profiles = [
  { value: 'products', label: 'Connect Products' },
  { value: 'prices-by-group', label: 'Group Prices' },
  { value: 'prices-by-customer', label: 'Customer ID Prices' }
];

export class ConnectAccountUploads extends BaseElement {
  static properties = {
    account: {},
    profile: { state: true },
    raw: { state: true },
    parsed: { state: true },
    errors: { state: true },
    pending: { state: true },
    success: { state: true }
  };

  constructor() {
    super();
    this.account = null;
    this.profile = profiles[0].value;
    this.raw = '';
    this.headings = null;
    this.parsed = null;
    this.errors = [];
    this.pending = false;
    this.success = false;
  }

  onSelectProfile(event) {
    this.profile = event.target.value;
  }

  parseInput() {
    const raw = /** @type {HTMLTextAreaElement} */ (
      this.querySelector('textarea')
    );

    const { headings, parsed, errors } =
      this.profile === 'products'
        ? parseConnectProducts(raw.value)
        : parseConnectPrices(raw.value);

    this.raw = raw.value;
    this.headings = headings;
    this.parsed = parsed;
    this.errors = errors;
  }

  async onUpload(e) {
    if (!this.headings || !this.parsed) {
      return;
    }

    const csv = stringify([this.headings, ...this.parsed]);
    const { delete_missing } = e.detail;

    this.pending = true;
    try {
      await request('POST', `/connect-accounts/${this.account}/upload-csv`, {
        profile: this.profile,
        csv,
        delete_missing
      });
      this.success = true;
    } finally {
      this.pending = false;
    }
  }

  onBack() {
    this.headings = null;
    this.parsed = null;
  }

  render() {
    if (this.pending) {
      return spinner();
    }
    if (this.success) {
      return alertSuccess(
        'Upload complete. Please check if the import file was removed from S3.'
      );
    }
    if (this.parsed && this.headings) {
      return html`
        <div>
          ${this.errors.length
            ? this.errors
                .slice(0, 50)
                .map((error) => alertError(renderError(error)))
            : alertSuccess('OK')}
          <connect-account-upload-results
            .headings=${this.headings}
            .parsed=${this.parsed}
            @back=${this.onBack}
            @upload=${this.onUpload}
          ></connect-account-upload-results>
        </div>
      `;
    }
    return html`
      <div class="vstack gap-2">
        <select
          class="form-select"
          name="encoding"
          @change=${this.onSelectProfile}
        >
          ${renderOptions(profiles, this.profile)}
        </select>
        <textarea cols="80" rows="10" class="form-control">
${this.raw}</textarea
        >
        <div class="text-end">
          <button
            type="submit"
            class="btn btn-primary"
            @click=${this.parseInput}
          >
            Check
          </button>
        </div>
      </div>
    `;
  }
}

customElements.define('connect-account-uploads', ConnectAccountUploads);

class ConnectAccountUploadResults extends paginated(BaseElement) {
  static properties = {
    headings: { state: true },
    parsed: { state: true },
    delete_missing: { state: true }
  };

  constructor() {
    super();
    this.headings = [];
    this.parsed = [];
    this.delete_missing = false;
    this.page_size = 20;
  }

  render() {
    const page = this.page(this.parsed);

    return html`
      <div class="table-responsive">
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              ${this.headings.map(
                (heading) => html`<th class="text-nowrap">${heading}</th>`
              )}
            </tr>
          </thead>
          <tbody>
            ${page.map(
              (row) => html`
                <tr>
                  ${row.map((cell) => html`<td>${cell}</td>`)}
                </tr>
              `
            )}
          </tbody>
        </table>
      </div>
      <div class="fixed-bottom bg-body container p-3 hstack gap-3">
        <shared-pagination
          page-count=${this.getPageCount(this.parsed)}
          page-index=${this.page_index}
        ></shared-pagination>
        <div class="form-check form-switch d-inline-block ms-auto">
          <input
            class="form-check-input"
            type="checkbox"
            id="switch-delete-missing"
            @change=${() => (this.delete_missing = !this.delete_missing)}
          />
          <label class="form-check-label" for="switch-delete-missing">
            Delete missing
          </label>
        </div>
        <button
          type="button"
          class="btn btn-secondary"
          @click=${() => this.emit('back')}
        >
          Back
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          .disabled=${!page.length}
          @click=${() =>
            this.emit('upload', { delete_missing: this.delete_missing })}
        >
          Upload
        </button>
      </div>
    `;
  }
}

customElements.define(
  'connect-account-upload-results',
  ConnectAccountUploadResults
);

function renderError(error) {
  return html`
    <strong>Line ${error.line}:</strong>
    ${error.message}
    ${error.raw
      ? html`
          <br />
          <small class="text-muted">${error.raw}</small>
        `
      : null}
  `;
}
