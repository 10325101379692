export function prepareDivision(division) {
  const { name, company, cost_center, address } = division;
  const mapped = {
    name
  };
  if (company) {
    mapped.company = company;
  }
  if (cost_center) {
    mapped.cost_center = cost_center;
  }
  if (address && isCompleteAddress(address)) {
    mapped.address = address;
  }

  return mapped;
}

function isCompleteAddress(address) {
  return address && address.zip && address.city && address.address1;
}
