import { html } from 'lit';
import { ParentElement } from '../parent.js';

class SharedTabContainer extends ParentElement {
  static properties = {
    tabs: { state: true }
  };

  tabs = [];

  connectedCallback() {
    /** @type {SharedTab[]} */
    const tabs = Array.from(this.querySelectorAll('shared-tab'));
    for (const tab of tabs) {
      this.tabs.push({
        id: tab.id,
        title: tab.title,
        active: tab.active
      });
    }
    super.connectedCallback();
  }

  render() {
    return html`
      <ul class="nav nav-tabs" role="tablist">
        ${this.tabs.map(
          (tab) => html`
            <li class="nav-item" role="presentation">
              <button
                class="nav-link ${tab.active ? 'active' : ''}"
                id="${tab.id}-tab"
                data-bs-toggle="tab"
                data-bs-target="#${tab.id}"
                type="button"
                role="tab"
              >
                ${tab.title}
              </button>
            </li>
          `
        )}
      </ul>
      <div class="tab-content">${this.content}</div>
    `;
  }
}

class SharedTab extends ParentElement {
  static properties = {
    active: { type: Boolean }
  };

  active = false;

  connectedCallback() {
    super.connectedCallback();

    this.classList.add('tab-pane', 'fade');
    if (this.active) {
      this.classList.add('show', 'active');
    }

    this.role = 'tabpanel';
  }

  render() {
    return this.content;
  }
}

customElements.define('shared-tab-container', SharedTabContainer);

customElements.define('shared-tab', SharedTab);
