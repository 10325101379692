import { html } from 'lit';
import { formatShortDate } from '@yourbarmate/commons/date-and-time/index.js';
import { days_of_the_week } from './date-and-time.js';

export function mapDeliveryDetails(delivery_details) {
  const { delivery_days, dates_with_no_delivery } = delivery_details || {};
  if (!delivery_days && !dates_with_no_delivery) {
    return '-';
  }

  const rows = [];
  if (delivery_days) {
    for (const [delivery_day, { lead_days, cutoff }] of Object.entries(
      delivery_days
    )) {
      const order_day =
        days_of_the_week[(7 + Number(delivery_day) - (lead_days % 7)) % 7];
      const prefix = lead_days > 6 ? `${lead_days} days before: ` : '';
      const day_details = html` <span class="day">
          <strong>${days_of_the_week[delivery_day]}</strong>
          (<small>${prefix}${order_day} ${cutoff || '23:59'}</small>)
        </span>
        <br />`;

      rows.push(day_details);
    }
  }
  if (dates_with_no_delivery) {
    rows.push(html`
      <i class="bi bi-x-circle"></i>
      <small>${dates_with_no_delivery.map(formatShortDate).join(', ')}</small>
    `);
  }

  return rows;
}
