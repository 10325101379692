import { html } from 'lit';
import { Routes } from '@lit-labs/router';
import {
  AccountsController,
  status_options,
  feature_options,
  type_options
} from '../../lib/controllers/accounts.js';
import { throttle } from '../../lib/throttle.js';
import { pushState } from '../../lib/route.js';
import dropdown from '../../html/dropdown.js';
import { BaseElement } from '../base.js';
import './list.js';
import './account-details.js';
import './new-account-modal.js';

export class Accounts extends BaseElement {
  static properties = {
    account: { reflect: true },
    query: { state: true },
    order_by: { state: true }
  };

  constructor() {
    super();
    this.routes = new Routes(this, [
      {
        path: ':id/*',
        render: ({ id }) => this.renderAccountDetails(id)
      },
      {
        path: '*',
        render: () => this.renderAccountsList()
      }
    ]);

    this.accounts = new AccountsController(this);
    this.status = null;
    this.feature = null;
    this.account_type = 'main';
    this.query = '';
    this.throttledQuery = throttle((event) =>
      this.setQuery(event.target.value)
    );
  }

  render() {
    return this.routes.outlet();
  }

  setQuery(query) {
    this.query = query;
    this.accounts.setSearch(query);
  }

  setStatus(status) {
    this.status = status;
    this.accounts.setStatus(status);
  }

  setFeature(feature) {
    this.feature = feature;
    this.accounts.setFeature(feature);
  }

  setAccountType(type) {
    this.account_type = type;
    this.accounts.setType(type);
  }

  setPage(page_index) {
    this.accounts.setPage(page_index);
  }

  onAddAccount() {
    this.emit(
      'modal:open',
      html`
        <modal-new-account
          @added=${({ detail }) => this.addAccount(detail)}
        ></modal-new-account>
      `
    );
  }

  addAccount(detail) {
    pushState(this.routes, `${detail.account}/locations`);
  }

  renderAccountsList() {
    return html`
      <h1>Accounts</h1>
      <shared-search-bar
        id="search-bar"
        @input=${this.throttledQuery}
        value=${this.query}
      >
        ${dropdown({
          icon: 'activity',
          options: status_options,
          selected: this.status,
          onSelect: (value) => this.setStatus(value)
        })}
        ${dropdown({
          icon: 'flag',
          options: feature_options,
          selected: this.feature,
          onSelect: (value) => this.setFeature(value)
        })}
        ${dropdown({
          icon: 'building',
          options: type_options,
          selected: this.account_type,
          onSelect: (value) => this.setAccountType(value)
        })}
      </shared-search-bar>
      ${this.renderAccountsListContent()}
    `;
  }

  renderAccountsListContent() {
    return this.accounts.render(({ accounts }) => {
      const { page_index, page_count } = this.accounts.pagination;
      return html`
        <accounts-list .items=${accounts}></accounts-list>
        <shared-actions-bar>
          <button
            class="btn btn-sm btn-primary"
            @click=${() => this.onAddAccount()}
          >
            Create New
          </button>
          <shared-pagination
            class="ms-auto"
            page-count=${page_count}
            page-index=${page_index}
            @pagination:pageIndex=${(event) => this.setPage(event.detail)}
          ></shared-pagination>
        </shared-actions-bar>
      `;
    });
  }

  renderAccountDetails(id) {
    return html` <account-details account=${id}></account-details> `;
  }
}

customElements.define('app-accounts', Accounts);
