import { Task } from '@lit-labs/task';
import { request } from '../request.js';
import { TaskController } from './task.js';
import { mapAccount } from './accounts.js';

/**
 * @typedef {import('lit').LitElement} LitElement
 */

export class AccountUsageController extends TaskController {
  /**
   * @param {LitElement} host
   */
  constructor(host) {
    super();

    this.host = host;
    this.task = new Task(
      host,
      async ([account]) => {
        if (account) {
          const { usages } = await request('GET', `/stats/accounts/${account}`);
          return usages[0];
        }
        const { usages } = await request('GET', '/stats/accounts');
        for (const usage of usages) {
          mapAccount(usage.account);
        }
        return usages;
      },
      () => [host.getAttribute('account')]
    );
  }
}
