import { html, nothing } from 'lit';
import { AccountLocationsController } from '../../../lib/controllers/account-locations.js';
import { SupplierSitesController } from '../../../lib/controllers/supplier-sites.js';
import { deepCopy } from '../../../lib/deep-copy.js';
import { mapDeliveryDetails } from '../../../lib/map-delivery-details.js';
import { request } from '../../../lib/request.js';
import { BaseElement } from '../../base.js';
import './modal-site-details.js';

/**
 * @typedef {import('lit').TemplateResult} TemplateResult
 */

export class SupplierSites extends BaseElement {
  static properties = {
    account: {},
    supplier: { attribute: false },
    supplier_id: {},
    new_division_id: { attribute: false }
  };

  constructor() {
    super();
    this.account = null;
    this.supplier = null;
    this.supplier_id = null;
    this.locations = new AccountLocationsController(this);
    this.sites = new SupplierSitesController(this);
    this.new_division_id = null;
  }

  onChangeSiteDetails(site, divisions_map) {
    const { created } = this.supplier;
    this.emit(
      'modal:open',
      html`
        <modal-account-supplier-site-details
          account="${this.account}"
          .site="${site}"
          .divisions="${deepCopy(divisions_map)}"
          supplier="${created}"
          @updated=${(e) => {
            this.sites.putSite(site.id, e.detail);
            this.requestUpdate();
          }}
        ></modal-account-supplier-site-details>
      `
    );
  }

  async removeSite(id) {
    await request(
      'DELETE',
      `/accounts/${this.account}/suppliers/${this.supplier_id}/sites/${id}`
    );
    this.sites.putSite(id, null);
    this.requestUpdate();
  }

  render() {
    return this.locations.render(({ divisions }) => {
      if (!divisions || !divisions.length) {
        return nothing;
      }
      const divisions_map = Object.fromEntries(
        divisions.map((div) => [div.id, { name: div.name }])
      );

      return html`
        <div class="mt-5">
          <h5>Supplier Sites</h5>
          <table class="table table-sm mt-2 align-middle">
            ${this.renderTableHeader()}
            <tbody>
              ${this.sites.render(({ sites }) => {
                if (sites.length) {
                  return sites.map((site) => {
                    for (const div_id of site.divisions) {
                      divisions_map[div_id].used = true;
                    }
                    return this.renderSite(site, divisions_map);
                  });
                }
                return nothing;
              })}
              ${this.renderTableFooter(divisions_map)}
            </tbody>
          </table>
        </div>
      `;
    });
  }

  renderTableHeader() {
    return html`
      <thead>
        <tr>
          <th scope="col">Name and Locations</th>
          <th scope="col">Customer ID</th>
          <th scope="col">Price group</th>
          <th scope="col">Deliveries</th>
          <th scope="col" style="width: 100px"></th>
        </tr>
      </thead>
    `;
  }

  renderTableFooter(divisions_map) {
    return html`
      <tr>
        <td colspan="3">
          <select
            id="add-site"
            class="form-control"
            @input=${(event) => {
              this.new_division_id = event.target.value;
            }}
          >
            <option value="-">- Choose Location -</option>
            ${Object.entries(divisions_map)
              .filter(([_, { used }]) => !used)
              .map(
                ([id, { name }]) => html` <option value=${id}>${name}</option> `
              )}
          </select>
        </td>
        <td class="align-center">
          <button
            type="button"
            class="btn btn-sm btn-outline-primary"
            @click=${() => {
              this.onChangeSiteDetails(
                {
                  name: this.new_division_id
                    ? divisions_map[this.new_division_id].name
                    : 'New Site',
                  customer_id: '',
                  divisions: this.new_division_id ? [this.new_division_id] : []
                },
                divisions_map
              );
              /** @type {HTMLSelectElement} */ (
                this.querySelector('#add-site')
              ).selectedIndex = 0;
            }}
          >
            Add new
          </button>
        </td>
      </tr>
    `;
  }

  renderSite(site, divisions_map) {
    const { id, customer_id, price_group, name } = site;
    return html`
      <tr>
        <td>${this.renderSiteName(site, divisions_map)}</td>
        <td>${customer_id || ''}</td>
        <td>${price_group || ''}</td>
        <td>${mapDeliveryDetails(site)}</td>
        <td>
          <div class="hstack gap-2 my-2">
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click=${() => this.onChangeSiteDetails(site, divisions_map)}
            >
              Update
            </button>
            <button
              type="button"
              class="btn btn-sm btn-outline-danger"
              @click=${() => {
                this.emit('modal:confirm', {
                  description: `Do you really want to delete customer ID for ${name}?`,
                  confirm: () => this.removeSite(id)
                });
              }}
            >
              Delete
            </button>
          </div>
        </td>
      </tr>
    `;
  }

  renderSiteName(site, divisions_map) {
    const division_names = site.divisions.map(
      (div_id) => divisions_map[div_id].name
    );

    if (
      (division_names[0] === site.name && division_names.length === 1) ||
      !division_names.length
    ) {
      return site.name;
    }

    return html`<strong>${site.name}</strong><br />
      ${division_names.join(', ')}`;
  }
}

customElements.define('account-supplier-sites', SupplierSites);
