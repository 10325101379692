import { html } from 'lit';
import { request } from '../../lib/request.js';
import { ModalElement } from '../modal/base.js';
import { deepCopy } from '../../lib/deep-copy.js';
import renderOptions from '../../html/options.js';

const encodings = ['utf8', 'latin1'];

class ModalSftpExport extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    sftp: {},
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Order Export';
    this.account = '';
    this.sftp = {};
    this.saving = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.sftp = deepCopy(this.sftp);
    if (!this.sftp.export_config) {
      this.sftp.export_config = {
        // eslint-disable-next-line no-template-curly-in-string
        remote_file: '/orders/yourbarmate-${type}-${id}.csv'
      };
    }
  }

  async onSave() {
    this.saving = true;

    const { remote_file, encoding, version } = this.sftp.export_config;
    const json = {
      remote_file
    };
    if (encoding) {
      json.encoding = encoding;
    }
    if (version) {
      json.version = Number(version);
    }
    const response = await request(
      'PUT',
      `/connect-accounts/${this.account}/sftp/export`,
      json
    );
    this.emit('updated', response);
    this.close();
  }

  async onRemove() {
    this.saving = true;

    await request('PUT', `/connect-accounts/${this.account}/sftp/export`, {});
    this.emit('updated', undefined);
    this.close();
  }

  onUpdateField(e) {
    this.sftp.export_config[e.target.name] = e.target.value;
    this.requestUpdate();
  }

  renderBody() {
    const { remote_file, encoding, version } = this.sftp.export_config;
    return html`
      <p>The remote file can contain the following placeholders:</p>
      <dl class="row">
        <dt class="col-4"><code>\${id}</code></dt>
        <dd class="col-8">The order ID</dd>
        <dt class="col-4"><code>\${type}</code></dt>
        <dd class="col-8">"delivery" or "return"</dd>
        <dt class="col-4"><code>\${date}</code></dt>
        <dd class="col-8">The ISO date, e.g. 2023-12-24</dd>
        <dt class="col-4"><code>\${time}</code></dt>
        <dd class="col-8">The time, e.g. 16:45</dd>
      </dl>
      <hr />
      <div class="row">
        <label class="col-4 col-form-label">Remote file</label>
        <div class="col-8">
          <input
            type="text"
            class="form-control"
            name="remote_file"
            .value=${remote_file || ''}
            @input=${this.onUpdateField}
          />
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Encoding</label>
        <div class="col-3">
          <select
            class="form-select"
            name="encoding"
            @change=${this.onUpdateField}
          >
            ${renderOptions(encodings, encoding || 'utf8')}
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <label class="col-4 col-form-label">Version</label>
        <div class="col-3">
          <select
            class="form-select"
            name="version"
            @change=${this.onUpdateField}
          >
            ${renderOptions(
              ['0'],
              version === undefined ? '0' : String(version)
            )}
          </select>
        </div>
      </div>
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-outline-danger me-auto"
        @click=${this.onRemove}
        ?disabled=${this.saving}
      >
        Remove
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Save
      </button>
    `;
  }
}

customElements.define('modal-sftp-export', ModalSftpExport);
