import { html } from 'lit';
import { Routes } from '@lit-labs/router';
import { AccountController } from '../../lib/controllers/account.js';
import { BaseElement } from '../base.js';
import './list.js';
import './sidebar.js';
import './status.js';
import './areas.js';
import './locations.js';
import './suppliers.js';
import './supplier/index.js';

export class AccountDetails extends BaseElement {
  static properties = {
    account: {}
  };

  constructor() {
    super();
    this.routes = new Routes(this, [
      {
        path: 'areas',
        render: () => this.renderPage('areas')
      },
      {
        path: 'locations',
        render: () => this.renderPage('locations')
      },
      {
        path: 'suppliers',
        render: () => this.renderPage('suppliers')
      },
      {
        path: 'suppliers/:created/*',
        render: ({ created }) => this.renderPage('suppliers', created)
      },
      {
        path: 'usage',
        render: () => this.renderPage('usage')
      },
      {
        path: '*',
        render: () => this.renderPage('status')
      }
    ]);

    this.account = '';
    this.account_loader = new AccountController(this);
  }

  render() {
    return this.routes.outlet();
  }

  renderPage(page, supplier) {
    return this.account_loader.render((account) => {
      let content;

      switch (page) {
        case 'suppliers':
          content = supplier
            ? html`
                <account-supplier
                  account="${account.account}"
                  created="${supplier}"
                ></account-supplier>
              `
            : html`
                <account-suppliers
                  account=${account.account}
                ></account-suppliers>
              `;
          break;
        case 'areas':
          content = html`
            <account-areas
              account=${account.account}
              .account_details=${account}
            ></account-areas>
          `;
          break;
        case 'locations':
          content = html`
            <account-locations account=${account.account}></account-locations>
          `;
          break;
        case 'usage':
          content = html`
            <account-usage account=${account.account}></account-usage>
          `;
          break;
        default:
          content = html`<account-status .item=${account}></account-status>`;
      }

      return html`
        <div class="row">
          <div class="col-12 col-lg-5 col-xl-4 mb-4">
            <account-sidebar
              .item=${account}
              details="${page}"
            ></account-sidebar>
          </div>
          <div class="col-12 col-lg-7 col-xl-8">${content}</div>
        </div>
      `;
    });
  }
}

customElements.define('account-details', AccountDetails);
