import { html } from 'lit';
import { ReportsController } from '../../lib/controllers/reports.js';
import { BaseElement } from '../base.js';

export class AppReports extends BaseElement {
  constructor() {
    super();
    this.reports = new ReportsController(this);
    this.numbers = new Intl.NumberFormat('de-CH');
  }

  async onLoadMore() {
    await this.reports.loadMore();
  }

  render() {
    return this.reports.render(({ daily, months }) => {
      return html`
        <section class="my-5">
          <h5>Monthly</h5>
          ${this.renderDeliveriesTable(months)}
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            @click=${this.onLoadMore}
          >
            <i class="bi bi-plus"></i>
          </button>
        </section>
        <section class="my-5">
          <h5>Last 31 days</h5>
          ${this.renderDeliveriesTable(daily.reports)}
        </section>
      `;
    });
  }

  renderDeliveriesTable(reports) {
    return html`
      <div class="table-responsive">
        <table class="table table-striped">
          <colgroup>
            <col style="width: 20%;" />
            <col style="width: 6%;" />
            <col style="width: 10%;" />
            <col style="width: 6%;" />
            <col style="width: 10%;" />
            <col style="width: 6%;" />
            <col style="width: 10%;" />
            <col style="width: 6%;" />
            <col style="width: 10%;" />
            <col style="width: 6%;" />
            <col style="width: 10%;" />
          </colgroup>
          <thead>
            <tr>
              <th rowspan="2">Date</th>
              <th colspan="2" rowspan="2" class="border-end">Total</th>
              <th colspan="6" class="border-end">Orders</th>
              <th colspan="2" rowspan="2">Own Delivery</th>
            </tr>
            <tr>
              <th colspan="2" class="border-end">All</th>
              <th colspan="2" class="border-end">Connect</th>
              <th colspan="2" class="border-end">Email</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            ${reports.map((report) =>
              this.renderDeliveriesRow(report.date, report.deliveries)
            )}
          </tbody>
        </table>
      </div>
    `;
  }

  renderDeliveriesRow(date, deliveries) {
    const {
      created,
      ordered,
      connect,
      direct,
      own,
      all_volume,
      ordered_volume,
      connect_volume,
      direct_volume,
      own_volume
    } = deliveries;
    return html`
      <tr>
        <td class="text-nowrap">${date}</td>
        <td class="text-nowrap text-end">${this.numbers.format(created)}</td>
        <td class="text-nowrap text-end border-end">
          ${this.numbers.format(all_volume)}
        </td>
        <td class="text-nowrap text-end">${this.numbers.format(ordered)}</td>
        <td class="text-nowrap text-end border-end">
          ${this.numbers.format(ordered_volume)}
        </td>
        <td class="text-nowrap text-end">${this.numbers.format(connect)}</td>
        <td class="text-nowrap text-end border-end">
          ${this.numbers.format(connect_volume)}
        </td>
        <td class="text-nowrap text-end">${this.numbers.format(direct)}</td>
        <td class="text-nowrap text-end border-end">
          ${this.numbers.format(direct_volume)}
        </td>
        <td class="text-nowrap text-end">${this.numbers.format(own)}</td>
        <td class="text-nowrap text-end">${this.numbers.format(own_volume)}</td>
      </tr>
    `;
  }
}

customElements.define('app-reports', AppReports);
