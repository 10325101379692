import { one, literal } from '@studio/schema';
import {
  parseDisplayName,
  parseOrderBy,
  parseVesselSize,
  parseVesselType,
  parseVesselUnit,
  parseBundleSize,
  parseBundleType,
  parsePriceAsFloat,
  parsePricePer,
  parseMinOrderCount
} from '@yourbarmate/parsers';
import { convertVessel } from '@yourbarmate/commons/convert-vessel.js';
import { formatPrice } from '@yourbarmate/commons/price.js';
import { url_safe_id } from '@yourbarmate/schema-validators/url-safe-id/index.js';
import { productStatusValidator } from '@yourbarmate/schema-validators/product-status/index.js';
import { vesselValidator } from '@yourbarmate/schema-validators/vessel/index.js';
import { bundleValidator } from '@yourbarmate/schema-validators/bundle/index.js';

/**
 * @typedef {import('@yourbarmate/schema-validators/vessel').Vessel} Vessel
 */

/**
 * @typedef ParserError
 * @property {number} line
 * @property {string} message
 * @property {string} [raw]
 */

const columns = [
  parseDisplayName, // category.name
  one(literal(''), url_safe_id).verify, // category.id
  url_safe_id.verify, // id
  parseDisplayName, // name
  parseOrderBy, // order_by
  parseVesselSize, // vessel.size
  parseVesselUnit, // vessel.unit
  parseVesselType, // vessel.type
  columnBundleSize, // bundle.size
  parseBundleType, // bundle.type
  columnPrice, // price
  parsePricePer, // price_per
  parseMinOrderCount, // min_order_count
  one(literal(''), productStatusValidator).verify, // status
  (image_url) => image_url || '' // image_url
];

function columnBundleSize(value) {
  const { size, unit } = parseBundleSize(value);
  if (!size) {
    return undefined;
  }
  if (unit) {
    return `${size / 1000} kg`;
  }
  return size;
}

function columnPrice(value) {
  return formatPrice(parsePriceAsFloat(value));
}

/**
 * @typedef {Array<string | number | undefined>} ParsedRow
 */

/**
 * @param {string} raw
 * @returns {{ headings: string[], parsed: ParsedRow[], errors: ParserError[] }}
 */
// eslint-disable-next-line complexity
export function parseConnectProducts(raw) {
  /** @type {string[]} */
  const headings = [];
  /** @type {ParsedRow[]} */
  const parsed = [];
  /** @type {Object[]} */
  const errors = [];
  const seen_ids = new Set();
  let last_id = null;

  let line = 0;
  const rows = raw.split('\n');
  const column_count = rows[0].split('\t').length;

  if (column_count < columns.length - 1) {
    errors.push({
      line,
      message: `Unexpected number of columns (${column_count})`
    });
    return { headings, parsed, errors };
  }
  if (rows[0].startsWith('Warengruppe\t')) {
    line += 1;
    rows.shift();
  }
  if (rows[0].startsWith('Name\tID')) {
    line += 1;
    headings.push(.../** @type {string} */ (rows.shift()).split('\t'));
  }

  const vessel_index = columns.indexOf(parseVesselSize);
  const bundle_index = columns.indexOf(columnBundleSize);
  for (const row of rows) {
    line += 1;
    const raw_cells = row.split('\t');
    if (raw_cells.every(isEmptyString)) {
      continue;
    }
    if (raw_cells.length < columns.length - 1) {
      errors.push({
        line,
        message: `Unexpected number of columns (${raw_cells.length})`,
        raw: raw_cells.join(', ')
      });
    }
    /** @type {ParsedRow} */
    const cells = [];
    let valid = true;
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      const raw_cell = raw_cells[i];
      try {
        // @ts-ignore
        cells.push(column(raw_cell));
      } catch (e) {
        valid = false;
        cells.push(raw_cell);
        errors.push({
          line,
          message: /** @type {Error} */ (e).message,
          raw: raw_cells.join(', ')
        });
      }
    }
    if (valid) {
      if (seen_ids.has(cells[2]) && cells[2] !== last_id) {
        errors.push({
          line,
          message: 'Duplicate ID',
          raw: raw_cells.join(', ')
        });
      } else {
        seen_ids.add(cells[2]);
      }
      last_id = cells[2];
      const [vessel_size, vessel_unit, vessel_type] = cells.slice(
        vessel_index,
        vessel_index + 3
      );
      if (vessel_size || vessel_unit || vessel_type) {
        const error = validateVessel(vessel_size, vessel_unit, vessel_type);
        if (error) {
          errors.push({
            line,
            message: error,
            raw: raw_cells.join(', ')
          });
        }
      }
      const [bundle_size, bundle_type] = cells.slice(
        bundle_index,
        bundle_index + 2
      );
      if (bundle_size || bundle_type) {
        /** @type {Object} */
        const bundle = { type: bundle_type };
        if (typeof bundle_size === 'string') {
          const { size, unit } = parseBundleSize(bundle_size);
          bundle.size = size;
          // eslint-disable-next-line max-depth
          if (unit) {
            bundle.unit = unit;
          }
        } else {
          bundle.size = bundle_size;
        }
        try {
          bundleValidator.verify(bundle, undefined, 'bundle');
        } catch (e) {
          errors.push({
            line,
            message: /** @type {Error} */ (e).message,
            raw: raw_cells.join(', ')
          });
        }
      }
    }
    parsed.push(cells);
  }

  return { headings, parsed, errors };
}

function isEmptyString(str) {
  return str.length === 0;
}

function validateVessel(vessel_size, vessel_unit, vessel_type) {
  /** @type {Object} */
  const vessel = {
    size: vessel_size,
    unit: vessel_unit
  };
  if (vessel_type) {
    vessel.type = vessel_type;
  }
  try {
    if (vessel_size && vessel_unit) {
      Object.assign(
        vessel,
        convertVessel(
          Number(vessel_size),
          /** @type {Vessel['unit']}*/ (vessel_unit)
        )
      );
    }
    vesselValidator.verify(vessel, undefined, 'vessel');
  } catch (e) {
    return /** @type {Error} */ (e).message;
  }

  return undefined;
}
