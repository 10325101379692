import { html } from 'lit';
import { request } from '../../../lib/request.js';
import { ModalElement } from '../../modal/base.js';

class ModalAccountSupplierPriceGroup extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    created: { type: Number },
    price_group: { attribute: 'price-group' },
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Supplier Customer Price Group';
    this.account = '';
    this.created = null;
    this.price_group = '';
    this.saving = false;
  }

  async onSave() {
    this.saving = true;
    const updated = await request(
      'PATCH',
      `/accounts/${this.account}/suppliers/${this.created}`,
      {
        price_group: this.price_group
      }
    );
    this.emit('updated', updated);
    this.close();
  }

  renderBody() {
    return html`
      <input
        class="form-control"
        name="price-group"
        value=${this.price_group}
        autocomplete="off"
        @input=${(e) => (this.price_group = e.target.value)}
      />
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Update
      </button>
    `;
  }
}

customElements.define(
  'modal-account-supplier-price-group',
  ModalAccountSupplierPriceGroup
);
