import logger from '@studio/log';
import { failure, FORBIDDEN, INVALID, E_FAILED } from '@studio/fail';
import { LS_AUTH_TOKEN, LS_LOGIN_REDIRECT } from './constants.js';

const log = logger('Request');

/**
 * @param {'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'} method
 * @param {string} pathname
 * @param {Object} [request_body]
 * @returns {Promise<Object>}
 */
export async function request(method, pathname, request_body) {
  const endpoint = `/api${pathname}`;
  const log_message = `${method} ${endpoint}`;
  log.fetch(log_message, request_body);

  const options = {
    method,
    headers: {}
  };
  if (request_body) {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(request_body);
  }

  const auth_token = localStorage.getItem(LS_AUTH_TOKEN);
  if (auth_token) {
    options.headers['Authorization'] = `Bearer ${auth_token}`;
  }

  const res = await fetch(endpoint, options);
  const { status } = res;
  const response_body = await res.json();
  if (status >= 200 && status < 300) {
    log.finish(log_message, response_body);
    return response_body;
  }
  const { code, message } = response_body;
  const log_data = { status, code, message };
  if (status === 401 || status === 403) {
    const error = failure(response_body.message, code || FORBIDDEN);
    log.ignore(log_message, log_data);
    if (message === 'Unauthorized') {
      if (auth_token) {
        localStorage.removeItem(LS_AUTH_TOKEN);
      }
      localStorage.setItem(LS_LOGIN_REDIRECT, location.pathname);
      location.href = '/login'; // Hard reload.
    }
    throw error;
  }
  if (status >= 400 && status < 500) {
    const error = failure(response_body.message, code || INVALID);
    log.warn(log_message, log_data);
    throw error;
  }
  const error = failure(response_body.message, code || E_FAILED);
  log.error(log_message, log_data);
  throw error;
}
