import { createContext } from '@lit-labs/context';
import { Routes } from '@lit-labs/router';

/**
 * @template T
 * @typedef {import('@lit-labs/context').Context<string, T>} Context
 */

export const router_context = /** @type {Context<Routes>} */ (
  createContext('router')
);
