import { html } from 'lit';

export default function (options, selection) {
  return options.map((option) => {
    const { value, label } =
      typeof option === 'string' ? { value: option, label: option } : option;
    return html`
      <option value="${value}" .selected=${value === selection}>
        ${label}
      </option>
    `;
  });
}
