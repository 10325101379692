import { html } from 'lit';
import { request } from '../../lib/request.js';
import { ModalElement } from '../modal/base.js';

class ModalAccountStripe extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    customer_id: {},
    subscription_id: {},
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Stripe Info';
    this.account = '';
    this.customer_id = '';
    this.subscription_id = '';
    this.saving = false;
  }

  async onSave() {
    this.saving = true;
    /** @type {null | { customer_id: string, subscription_id?: string }} */
    let stripe = null;
    // Allow to remove the stripe settings entirely by clearing the customer
    if (this.customer_id) {
      stripe = {
        customer_id: this.customer_id
      };
      // Allow to remove the subscription_id by clearing it:
      if (this.subscription_id) {
        stripe.subscription_id = this.subscription_id;
      }
    }
    const updated = await request('PATCH', `/accounts/${this.account}`, {
      stripe
    });
    this.emit('updated', updated);
    this.close();
  }

  renderBody() {
    return html`
      <div class="vstack gap-3">
        <div>
          <label class="form-label">Customer:</label>
          <input
            class="form-control"
            name="customer"
            value=${this.customer_id}
            @input=${(e) => (this.customer_id = e.target.value)}
          />
        </div>
        ${this.customer_id
          ? html`
              <div>
                <label class="form-label">Subscription:</label>
                <input
                  class="form-control"
                  name="subscription"
                  value=${this.subscription_id}
                  @input=${(e) => (this.subscription_id = e.target.value)}
                />
              </div>
            `
          : null}
      </div>
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-${this.customer_id ? 'primary' : 'danger'}"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        ${this.customer_id ? 'Update' : 'Remove'}
      </button>
    `;
  }
}

customElements.define('modal-account-stripe', ModalAccountStripe);
