import { html } from 'lit';
import { ModalElement } from './base.js';

class ModalConfirm extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    description: {},
    details: {}
  };

  constructor() {
    super();
    this.title = 'Are you sure?';
    this.description = '';
    this.details = '';
  }

  onConfirm() {
    this.close();
    this.emit('confirm');
  }

  renderBody() {
    return this.details
      ? html`
          <p>${this.description}</p>
          <p>${this.details}</p>
        `
      : this.description;
  }

  renderFooter() {
    return html`
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
        Cancel
      </button>
      <button type="button" class="btn btn-primary" @click=${this.onConfirm}>
        Yes, I'm sure!
      </button>
    `;
  }
}

customElements.define('modal-confirm', ModalConfirm);
