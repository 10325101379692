import { html } from 'lit';
import { formatShortDate } from '@yourbarmate/commons/date-and-time/index.js';

export function usageDays(date, created) {
  const days = Math.floor(
    (Date.now() - new Date(date || created).getTime()) / 86400000
  );
  const color = days >= 30 ? 'text-danger' : days >= 15 ? 'text-warning' : '';
  return date
    ? html`<span class="${color}">${formatShortDate(date)}</span>`
    : html`<em class="${color}">never</em>`;
}
