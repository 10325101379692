import { Task } from '@lit-labs/task';
import { request } from '../request.js';
import { TaskController } from './task.js';

/**
 * @typedef {import('lit').LitElement} LitElement
 * @typedef {import('../../elements/accounts/supplier/products').AccountSupplierProducts} AccountSupplierProducts
 */

export class AccountSupplierProductsController extends TaskController {
  /**
   * @param {AccountSupplierProducts} host
   */
  constructor(host) {
    super();

    this.host = host;
    this.results = [];
    this.task = new Task(
      host,
      async ([account, created, connect]) => {
        const res = await request(
          'GET',
          `/accounts/${account}/suppliers/${created}/products?connect=${connect}`
        );
        this.results = res.results;
        return this.results;
      },
      () => [
        host.getAttribute('account'),
        host.getAttribute('created'),
        host.getConnectAccount()
      ]
    );
  }

  reload() {
    this.task.run();
  }

  remove(client_id) {
    const index = this.results.findIndex(
      (result) => result.client.id === client_id
    );
    this.results.splice(index, 1);
    this.host.requestUpdate();
  }
}
