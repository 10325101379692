import { Tooltip } from 'bootstrap';
import { ParentElement } from '../parent.js';

class SharedTooltip extends ParentElement {
  render() {
    Tooltip.getOrCreateInstance(this.content.firstElementChild);
    return this.content;
  }
}

customElements.define('shared-tooltip', SharedTooltip);
