import { Task } from '@lit-labs/task';
import { request } from '../request.js';
import { TaskController } from './task.js';

/**
 * @typedef {import('lit').LitElement} LitElement
 */

export class ConnectCustomersController extends TaskController {
  /**
   * @param {LitElement} host
   */
  constructor(host) {
    super();

    this.task = new Task(
      host,
      async ([account]) => {
        const customers = await request(
          'GET',
          `/connect-accounts/${account}/customers`
        );
        return customers;
      },
      () => [host.getAttribute('account')]
    );
  }
}
