import { html } from 'lit';
import { BaseElement } from '../base.js';

export class SetupAreas extends BaseElement {
  static properties = {
    setup: { state: true }
  };

  constructor() {
    super();
    this.setup = {
      bars: [],
      storages: [],
      bar_stock_tracking: true,
      storage_stock_tracking: true
    };
  }

  inputStockTracking(type, event) {
    this.setup[type] = event.target.checked;
  }

  render() {
    return html`
      <shared-tab-container>
        <shared-tab class="pt-4" id="standard" title="Single Location" active>
          <p>Set up your Areas</p>
          ${this.renderSetupAreas()}
        </shared-tab>
        <shared-tab class="pt-4" id="divisions" title="Multiple Locations">
          <p>Set up your first location</p>
          <button
            class="btn btn-primary btn-sm"
            @click=${() => this.emit('addDivision')}
          >
            Add New Location
          </button>
        </shared-tab>
      </shared-tab-container>
    `;
  }

  renderSetupAreas() {
    const { bars, storages } = this.setup;
    return html`
      <div class="hstack gap-5 align-items-start">
        <div class="w-50">
          <h6>Bars</h6>
          <shared-multi-input
            .values=${bars}
            .config=${{ remove: true }}
            @change=${() => this.requestUpdate()}
          >
          </shared-multi-input>
          <div class="mt-3">
            <input
              type="checkbox"
              name="stock_tracking"
              ?checked=${this.setup.bar_stock_tracking}
              class="form-check-input"
              @input=${(event) =>
                this.inputStockTracking('bar_stock_tracking', event)}
            />
            <label class="form-label">Stock tracking for Bars</label>
          </div>
        </div>
        <div class="w-50">
          <h6>Storages</h6>
          <shared-multi-input
            .values=${storages}
            .config=${{ remove: true }}
            @change=${() => this.requestUpdate()}
          >
          </shared-multi-input>
          <div class="mt-3">
            <input
              type="checkbox"
              name="stock_tracking"
              ?checked=${this.setup.storage_stock_tracking}
              class="form-check-input"
              @input=${(event) =>
                this.inputStockTracking('storage_stock_tracking', event)}
            />
            <label class="form-label">Stock tracking for Storages</label>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <button
          class="btn btn-primary"
          ?disabled=${!bars.concat(storages).filter(Boolean).length}
          @click=${() => this.emit('createAreas', this.setup)}
        >
          <i class="bi bi-building me-2"></i>Create Areas
        </button>
      </div>
    `;
  }
}

customElements.define('account-areas-setup', SetupAreas);
