import { Task } from '@lit-labs/task';
import { request } from '../request.js';
import { TaskController } from './task.js';

/**
 * @typedef {import('lit').LitElement} LitElement
 */

export class SupplierSitesController extends TaskController {
  /**
   * @param {LitElement} host
   */
  constructor(host) {
    super();

    this.host = host;
    this.sites = [];
    this.task = new Task(
      host,
      async ([account, supplier_id]) => {
        if (!account || !supplier_id) {
          return { sites: {} };
        }
        const { sites } = await request(
          'GET',
          `/accounts/${account}/suppliers/${supplier_id}/sites`
        );
        this.sites = sites;
        return { sites };
      },
      () => [host.getAttribute('account'), host.getAttribute('supplier_id')]
    );
  }

  /**
   * @param {string} id
   * @param {Object} site
   */
  putSite(id, site) {
    const index = this.sites.findIndex((s) => s.id === id);
    if (site === null) {
      this.sites.splice(index, 1);
    } else if (index > -1) {
      this.sites[index] = site;
    } else {
      this.sites.push(site);
    }
    this.host.requestUpdate();
  }
}
