import { html } from 'lit';
import { BaseElement } from '../base.js';

export class SetupLocations extends BaseElement {
  static properties = {
    setup: { state: true }
  };

  constructor() {
    super();
    this.setup = { bars: [], storages: [] };
  }

  render() {
    return html`
      <shared-tab-container>
        <shared-tab class="pt-4" id="standard" title="Single Location" active>
          <p>Set up your Areas</p>
          ${this.renderSetupLocations()}
        </shared-tab>
        <shared-tab class="pt-4" id="divisions" title="Multiple Locations">
          <p>Set up your first location</p>
          <button
            class="btn btn-primary btn-sm"
            @click=${() => this.emit('addDivision')}
          >
            Add New Location
          </button>
        </shared-tab>
      </shared-tab-container>
    `;
  }

  renderSetupLocations() {
    const { bars, storages } = this.setup;
    return html`
      <div class="hstack gap-5 align-items-start">
        <div class="w-50">
          <h6>Bars</h6>
          <shared-multi-input
            .values=${bars}
            .config=${{ remove: true }}
            @change=${() => this.requestUpdate()}
          >
          </shared-multi-input>
        </div>
        <div class="w-50">
          <h6>Storages</h6>
          <shared-multi-input
            .values=${storages}
            .config=${{ remove: true }}
            @change=${() => this.requestUpdate()}
          >
          </shared-multi-input>
        </div>
      </div>
      <div class="mt-3">
        <button
          class="btn btn-primary"
          ?disabled=${!bars.concat(storages).filter(Boolean).length}
          @click=${() => this.emit('createLocations', this.setup)}
        >
          <i class="bi bi-building me-2"></i>Create Areas
        </button>
      </div>
    `;
  }
}

customElements.define('account-locations-setup', SetupLocations);
