import { html } from 'lit';
import { BaseElement } from '../base.js';
import './select-connect-account.js';

class DivisionDetails extends BaseElement {
  static properties = {
    division: {}
  };

  constructor() {
    super();
    this.division = { name: '' };
  }

  input(name, initial) {
    return (event) => {
      this.division[name] = initial ?? event.target.value;
      this.emit('update');
      this.requestUpdate();
    };
  }

  inputAddress(property) {
    return (event) => {
      this.division.address[property] = event.target.value;
      this.emit('update');
      this.requestUpdate();
    };
  }

  render() {
    const { division } = this;
    return html`
      <div>
        <label class="form-label">Name:</label>
        <input
          type="text"
          .value=${division.name}
          class="form-control"
          placeholder="Location name"
          @input=${this.input('name')}
        />
      </div>
      ${this.renderOptional('company', 'Company')}
      ${this.renderOptional('cost_center', 'Cost center')}
      ${this.renderOptional('address', 'Address')}
    `;
  }

  renderOptional(name, title) {
    const initial = name === 'address' ? { country: 'CH' } : '';
    return this.division[name] === undefined
      ? html`
          <button class="btn btn-link" @click=${this.input(name, initial)}>
            Add ${title}
          </button>
        `
      : html`
          <div class="my-3">
            <label class="form-label">${title}:</label>
            ${name === 'address'
              ? this.renderAddressInput()
              : this.renderInput(name)}
          </div>
        `;
  }

  renderInput(name) {
    return html`
      <input
        type="text"
        class="form-control"
        .value=${this.division[name]}
        @change=${this.input(name)}
      />
    `;
  }

  renderAddressInput() {
    const { division } = this;
    return html`
      <div class="hstack gap-1 mb-2">
        <input
          type="text"
          class="form-control"
          .value=${division.address.city || ''}
          placeholder="City"
          @change=${this.inputAddress('city')}
        />
        <input
          type="text"
          class="form-control"
          .value=${division.address.address1 || ''}
          placeholder="zip"
          @change=${this.inputAddress('zip')}
        />
      </div>
      <input
        type="text"
        class="form-control mb-2"
        .value=${division.address.address1 || ''}
        placeholder="Address line 1"
        @change=${this.inputAddress('address1')}
      />
      <div class="hstack gap-1 mb-2">
        <input
          type="text"
          class="form-control"
          value=${division.address.address2 || ''}
          placeholder="Address line 2"
          @change=${this.inputAddress('address2')}
        />
        <shared-country-selector
          @change=${this.inputAddress('country')}
        ></shared-country-selector>
      </div>
    `;
  }
}

customElements.define('division-details', DivisionDetails);
