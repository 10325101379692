import { html } from 'lit';
import { deepCopy } from '../../../lib/deep-copy.js';
import { request } from '../../../lib/request.js';
import { ModalElement } from '../../modal/base.js';

class ModalAccountSupplierSite extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    site: {},
    changes: { state: true },
    supplier: { type: Number },
    saving: { state: true },
    divisions: {}
  };

  constructor() {
    super();
    this.size = 'lg';
    this.site = null;
    this.divisions = {};
    this.changes = {};
    this.account = '';
    this.supplier = null;
    this.saving = false;
  }

  async onSave() {
    this.saving = true;

    const request_body = deepCopy(
      this.site.id ? this.changes : Object.assign({}, this.site, this.changes)
    );
    if (
      request_body.delivery_days &&
      !Object.keys(request_body.delivery_days).length
    ) {
      request_body.delivery_days = null;
    }
    if (
      request_body.dates_with_no_delivery &&
      !request_body.dates_with_no_delivery.length
    ) {
      request_body.dates_with_no_delivery = null;
    }
    if (request_body.customer_id === '') {
      request_body.customer_id = null;
    }
    if (request_body.price_group === '') {
      request_body.price_group = null;
    }
    const updated = this.site.id
      ? await request(
          'PATCH',
          `/accounts/${this.account}/suppliers/${this.supplier}/sites/${this.site.id}`,
          request_body
        )
      : await request(
          'POST',
          `/accounts/${this.account}/suppliers/${this.supplier}/sites`,
          request_body
        );
    this.emit('updated', updated);
    this.changes = {};
    this.close();
  }

  renderHeader() {
    return html`
      <h5 class="modal-title">${this.site.name}</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
    `;
  }

  renderBody() {
    return html`
      <shared-tab-container>
        <shared-tab class="pt-4" id="details" title="Details" active>
          <div class="row">
            <div class="col-4">${this.renderName()}</div>
            <div class="col-4">${this.renderCustomerId()}</div>
            <div class="col-4">${this.renderPriceGroup()}</div>
          </div>
          <div class="mt-5">${this.renderLocations()}</div>
        </shared-tab>
        <shared-tab class="pt-4" id="deliveries" title="Deliveries">
          <div>${this.renderDeliveryDays()}</div>
          <div class="my-3">${this.renderDatesWithNoDelivery()}</div>
        </shared-tab>
      </shared-tab-container>
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
        @click=${() => {
          this.changes = {};
        }}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving || !this.getChangedValue('divisions').length}
      >
        ${this.site.id ? 'Update' : 'Add new'}
      </button>
    `;
  }

  renderName() {
    return html`
      <h5>Name</h5>
      <input
        class="form-control my-1"
        value=${this.getChangedValue('name')}
        autocomplete="off"
        @input=${(e) => (this.changes.name = e.target.value.trim())}
      />
    `;
  }

  renderCustomerId() {
    return html`
      <h5>Customer ID</h5>
      <input
        class="form-control my-1"
        value=${this.getChangedValue('customer_id')}
        autocomplete="off"
        @input=${(e) => (this.changes.customer_id = e.target.value.trim())}
      />
    `;
  }

  renderPriceGroup() {
    return html`
      <h5>Price Group</h5>
      <input
        class="form-control my-1"
        value=${this.getChangedValue('price_group')}
        autocomplete="off"
        @input=${(e) => (this.changes.price_group = e.target.value.trim())}
      />
    `;
  }

  renderLocations() {
    const { divisions } = this;
    return html`
      <h5>Locations</h5>
      <div class="py-2">
        ${this.getChangedValue('divisions').map((id) =>
          this.renderLocation(id)
        )}
        <div style="clear: both;"></div>
      </div>
      <div class="input-group">
        <span class="input-group-text" id="basic-addon1">
          <i class="bi bi-plus"></i>
        </span>
        <select
          class="form-select form-select-sm"
          @input=${(event) => this.addDivision(event.target)}
        >
          <option value="-" selected="selected">Add Location</option>
          ${Object.entries(divisions)
            .filter(([_, { used }]) => !used)
            .map(
              ([id, { name }]) => html` <option value=${id}>${name}</option> `
            )}
        </select>
      </div>
    `;
  }

  renderLocation(division_id) {
    const { divisions } = this;
    return html`
      <span class="float-start badge bg-secondary p-2 me-2 my-2">
        ${divisions[division_id].name}
        <i
          class="bi bi-trash ms-2"
          @click=${() => this.removeDivision(division_id)}
        ></i>
      </span>
    `;
  }

  renderDeliveryDays() {
    return html`
      <h5>Delivery days</h5>
      <shared-delivery-days-editor
        .delivery_days=${deepCopy(this.getChangedValue('delivery_days'))}
        @delivery_days:toggleDay=${(e) => this.toggleDeliveryDay(e.detail)}
        @delivery_days:setDayValue=${(e) => this.setDeliveryDayValue(e.detail)}
      ></shared-delivery-days-editor>
    `;
  }

  renderDatesWithNoDelivery() {
    const no_delivery = this.getChangedValue('dates_with_no_delivery') || [];
    return html`
      <h5>Dates with no delivery</h5>
      <input
        class="form-control my-1"
        name="customer-id"
        value=${no_delivery.join(', ')}
        autocomplete="off"
        @input=${(e) =>
          (this.changes.dates_with_no_delivery = e.target.value
            .split(',')
            .map((s) => s.trim())
            .filter(Boolean))}
      />
    `;
  }

  change(key) {
    if (!this.changes[key]) {
      this.changes[key] = deepCopy(this.site[key] || {});
    }
    return this.changes[key];
  }

  getChangedValue(key) {
    return Object.hasOwn(this.changes, key)
      ? this.changes[key]
      : this.site[key];
  }

  addDivision(select) {
    const id = select.value;

    this.divisions[id].used = true;
    this.change('divisions').push(id);

    select.selectedIndex = 0;
    this.requestUpdate();
  }

  removeDivision(id) {
    this.divisions[id].used = false;
    this.change('divisions').splice(this.change('divisions').indexOf(id), 1);
    this.requestUpdate();
  }

  toggleDeliveryDay(index) {
    const delivery_days = this.change('delivery_days');
    if (delivery_days[index]) {
      delete delivery_days[index];
    } else {
      delivery_days[index] = { lead_days: 1 };
    }
    this.requestUpdate();
  }

  setDeliveryDayValue({ index, key, value }) {
    this.change('delivery_days')[index][key] =
      key === 'lead_days' ? Number(value) : value || undefined;

    this.requestUpdate();
  }
}

customElements.define(
  'modal-account-supplier-site-details',
  ModalAccountSupplierSite
);
