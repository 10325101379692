import { html } from 'lit';
import { formatRelativeDate } from '@yourbarmate/commons/date-and-time/index.js';
import { AccountUsageController } from '../../lib/controllers/account-usage.js';
import { usageDays } from '../../lib/usage-days.js';
import { BaseElement } from '../base.js';

export class AppStats extends BaseElement {
  static properties = {
    sort: { state: true },
    invert: { state: true }
  };

  constructor() {
    super();
    this.usage = new AccountUsageController(this);
    this.sort = 'latest';
    this.invert = false;
  }

  render() {
    return this.usage.render((usages) => {
      const cmp =
        this.sort === 'created' ? createComparator : comparator(this.sort);
      usages.sort(cmp);
      if (this.invert) {
        usages = usages.reverse();
      }
      return html`
        <div class="table-responsive table-striped">
          <table class="table table-striped">
            <colgroup>
              <col style="width: 40%;" />
              <col style="width: 10%;" />
              <col style="width: 10%;" />
              <col style="width: 10%;" />
              <col style="width: 10%;" />
              <col style="width: 10%;" />
              <col style="width: 10%;" />
            </colgroup>
            <thead>
              <tr>
                <th>Account</th>
                ${this.renderHeading('created', 'Created')}
                ${this.renderHeading('latest', 'Latest')}
                ${this.renderHeading('shop_session', 'Shop')}
                ${this.renderHeading('shop_delivery', 'Delivery')}
                ${this.renderHeading('inspection', 'Inspection')}
                ${this.renderHeading('timeline', 'Timeline')}
              </tr>
            </thead>
            <tbody class="table-group-divider">
              ${usages.map((usage) => {
                const { account, display_name, created } = usage.account;
                return html`
                  <tr>
                    <td class="text-nowrap">
                      <a href="/accounts/${account}/">${display_name}</a>
                    </td>
                    <td class="text-nowrap">
                      ${formatRelativeDate(created, 'en')}
                    </td>
                    <td class="text-nowrap">
                      ${usageDays(usage.latest, created)}
                    </td>
                    <td class="text-nowrap">
                      ${usageDays(usage.shop_session, created)}
                    </td>
                    <td class="text-nowrap">
                      ${usageDays(usage.shop_delivery, created)}
                    </td>
                    <td class="text-nowrap">
                      ${usageDays(usage.inspection, created)}
                    </td>
                    <td class="text-nowrap">
                      ${usageDays(usage.timeline, created)}
                    </td>
                  </tr>
                `;
              })}
            </tbody>
          </table>
        </div>
      `;
    });
  }

  renderHeading(key, label) {
    return html`
      <th>
        <a
          href=""
          class="d-block text-decoration-none text-nowrap"
          @click=${() => this.onSort(key)}
        >
          <span>${label}</span>
          ${key === this.sort
            ? html`<i
                class="bi ${this.invert ? 'bi-sort-up' : 'bi-sort-down'} ms-2"
              ></i>`
            : null}
        </a>
      </th>
    `;
  }

  onSort(key) {
    if (this.sort === key) {
      this.invert = !this.invert;
    } else {
      this.sort = key;
      this.invert = key === 'created';
    }
  }
}

function createComparator(a, b) {
  const ka = a.account.created;
  const kb = b.account.created;
  if (ka < kb) {
    return -1;
  }
  if (ka > kb) {
    return 1;
  }
  return 0;
}

/**
 * @param {string} sort
 * @returns {function(*, *): number}
 */
function comparator(sort) {
  return (a, b) => {
    const ka = a[sort] || `1970-01-01/${a.account.created}`;
    const kb = b[sort] || `1970-01-01/${b.account.created}`;
    if (ka < kb) {
      return -1;
    }
    if (ka > kb) {
      return 1;
    }
    return 0;
  };
}

customElements.define('app-stats', AppStats);
