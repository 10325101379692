import { html } from 'lit';
import { BaseElement } from '../base.js';

class SharedCopyId extends BaseElement {
  static properties = {
    id: {},
    copied: { state: true }
  };

  constructor() {
    super();
    this.id = '';
    this.copied = false;
  }

  async onCopy() {
    await navigator.clipboard.writeText(this.id);
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 2000);
  }

  render() {
    const icon = this.copied ? 'bi-clipboard-check' : 'bi-clipboard';
    return html`
      <code>${this.id}</code>
      <a href="javascript:;" class="ms-1" @click=${this.onCopy}>
        <i class="bi ${icon}"></i>
      </a>
    `;
  }
}

customElements.define('shared-copy-id', SharedCopyId);
