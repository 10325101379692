import { Task } from '@lit-labs/task';
import { stringPropertyComparator } from '@yourbarmate/commons/string-comparator.js';
import { request } from '../request.js';
import { TaskController } from './task.js';

/**
 * @typedef {import('lit').LitElement} LitElement
 */

export class AccountAreasController extends TaskController {
  /**
   * @param {LitElement} host
   */
  constructor(host) {
    super();

    this.host = host;
    this.divisions = [];
    this.areas = [];
    this.task = new Task(
      host,
      async ([account]) => {
        const { divisions, areas } = await request(
          'GET',
          `/accounts/${account}/areas`
        );
        this.divisions = divisions.sort(stringPropertyComparator('name'));
        this.areas = areas.sort(stringPropertyComparator('name'));
        return { divisions, areas };
      },
      () => [host.getAttribute('account')]
    );
  }

  /**
   * @param {Object} division
   */
  addDivision(division) {
    this.divisions.push(division);
    this.host.requestUpdate();
  }

  /**
   * @param {Object[]} areas
   */
  addArea(...areas) {
    this.areas.push(...areas);
    this.host.requestUpdate();
  }

  /**
   * @param {Object} area
   */
  updateArea(area) {
    const index = this.areas.findIndex(({ id }) => id === area.id);
    this.areas.splice(index, 1, area);
    this.host.requestUpdate();
  }

  reload() {
    this.task.run();
  }
}
