import { html } from 'lit';
import { request } from '../../lib/request.js';
import { ModalElement } from '../modal/base.js';

const available_languages = ['de', 'en', 'fr', 'it'];

class ModalConnectAccountLanguage extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    language: { state: true },
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Language setting';
    this.account = {};
    this.language = null;
    this.saving = false;
  }

  async onSave() {
    this.saving = true;
    const request_body = { lang: this.language };
    const updated = await request(
      'PATCH',
      `/connect-accounts/${this.account.account}`,
      request_body
    );
    this.emit('updated', updated);
    this.close();
  }

  renderBody() {
    return html`
      <select
        class="form-select"
        name="country"
        @change=${(e) => (this.language = e.target.value)}
      >
        ${available_languages.map(
          (lang) => html`
            <option value=${lang} ?selected=${lang === this.account.lang}>
              ${lang}
            </option>
          `
        )}
      </select>
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Update
      </button>
    `;
  }
}

customElements.define(
  'modal-connect-account-language',
  ModalConnectAccountLanguage
);
