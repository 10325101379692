import { Task } from '@lit-labs/task';
import { request } from '../request.js';
import { TaskController } from './task.js';

/**
 * @typedef {import('lit').LitElement} LitElement
 */

export class ConnectAccountsController extends TaskController {
  /**
   * @param {LitElement} host
   * @param {Function} [filter]
   */
  constructor(host, filter) {
    super();

    this.task = new Task(
      host,
      async () => {
        const res = await request('GET', '/connect-accounts');
        const list = filter ? res.list.filter(filter) : res.list;
        return list;
      },
      () => []
    );
  }
}
