import { html, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { BaseElement } from '../base.js';

class SharedMultiInput extends BaseElement {
  static properties = {
    values: { state: true },
    config: { state: true }
  };

  constructor() {
    super();
    this.values = [];
    this.config = {};
  }

  render() {
    const { move, remove } = this.config;
    const render_values = this.values.map((value, i) => ({
      id: `itm_${i}:${value}`,
      value
    }));
    return html`
      ${repeat(
        render_values,
        ({ id }) => id,
        ({ value, id }, i) => html`
          <div class="input-group mb-2">
            <input
              id=${id}
              type="text"
              class="form-control"
              autocomplete="off"
              value=${value}
              @change=${(event) => {
                this.values[i] = event.target.value;
                this.emit('change');
              }}
            />
            ${move
              ? html`
                  <div class="btn-group-vertical">
                    <button
                      class="btn btn-sm py-0 btn-secondary"
                      ?disabled=${i === 0}
                      @click=${() => this.move(i, -1)}
                    >
                      <i class="bi bi-arrow-up"></i>
                    </button>
                    <button
                      class="btn btn-sm py-0 btn-secondary"
                      ?disabled=${i === this.values.length - 1}
                      @click=${() => this.move(i, 1)}
                    >
                      <i class="bi bi-arrow-down"></i>
                    </button>
                  </div>
                `
              : nothing}
            ${remove
              ? html`
                  <button
                    class="btn btn-sm btn-secondary"
                    @click=${() => this.delete(i)}
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                `
              : nothing}
          </div>
        `
      )}
      <button
        class="btn btn-sm btn-primary"
        @click=${() => {
          this.values.push('');
          this.emit('change');
          this.requestUpdate();
        }}
      >
        <i class="bi bi-plus"></i>Add
      </button>
    `;
  }

  move(index, direction) {
    const new_index = index + direction;
    if (new_index >= 0 && new_index < this.values.length) {
      const swap = this.values[new_index];
      this.values[new_index] = this.values[index];
      this.values[index] = swap;

      this.emit('change');
      this.requestUpdate();
    }
  }

  delete(index) {
    this.values.splice(index, 1);
    this.emit('change');
    this.requestUpdate();
  }
}

customElements.define('shared-multi-input', SharedMultiInput);
