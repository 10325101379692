import { ContextConsumer } from '@lit-labs/context';
import { html, nothing } from 'lit';
import { router_context } from '../../../lib/context.js';
import { mapDeliveryDetails } from '../../../lib/map-delivery-details.js';
import { request } from '../../../lib/request.js';
import {
  integrationMethods,
  connectStatus
} from '../../../lib/supplier-integrations.js';
import { pushState } from '../../../lib/route.js';
import { BaseElement } from '../../base.js';
import { getExcelExportFormatLabel } from './modal-excel-format.js';
import './modal-connect.js';
import './modal-customer-id.js';
import './modal-email-overrides.js';
import './modal-integrations.js';
import './modal-delivery-details.js';
import './modal-price-group.js';
import './supplier-sites.js';

export class AccountSupplierStatus extends BaseElement {
  static properties = {
    account: {},
    supplier: { attribute: false },
    new_division_id: { attribute: false }
  };

  constructor() {
    super();
    this.account = null;
    this.supplier = null;
    this.routes = new ContextConsumer(this, router_context);
  }

  onConnect() {
    this.emit(
      'modal:open',
      html`
        <modal-account-supplier-connect
          account="${this.account}"
          .supplier=${this.supplier}
          @select=${(e) => {
            const { account } = e.detail;
            pushState(this.routes.value, `products?connect=${account}`);
          }}
        ></modal-account-supplier-connect>
      `
    );
  }

  onUnlink() {
    this.emit('modal:confirm', {
      description: 'Do you really want to unlink this supplier?',
      confirm: () => this.unlink()
    });
  }

  onToggleActive() {
    const { archived } = this.supplier;

    this.emit('modal:confirm', {
      description: `Do you really want to ${
        archived ? 're-activate' : 'deactivate'
      } this supplier?`,
      confirm: () => this.toggleActive()
    });
  }

  async unlink() {
    const { created } = this.supplier;

    await request('PATCH', `/accounts/${this.account}/suppliers/${created}`, {
      connect: null
    });
    delete this.supplier.connect;
    this.requestUpdate();
  }

  async toggleActive() {
    const { created, archived } = this.supplier;
    const set_archived = archived ? null : Date.now();

    await request('PATCH', `/accounts/${this.account}/suppliers/${created}`, {
      archived: set_archived
    });
    this.supplier.archived = set_archived;
    this.requestUpdate();
  }

  onChangeCustomerId() {
    const { created, customer_id } = this.supplier;
    this.emit(
      'modal:open',
      html`
        <modal-account-supplier-customer-id
          account="${this.account}"
          created="${created}"
          customer-id="${customer_id}"
          @updated=${(e) => {
            this.supplier.customer_id = e.detail.customer_id || undefined;
            this.requestUpdate();
          }}
        ></modal-account-supplier-customer-id>
      `
    );
  }

  onUpdateIntegrations() {
    this.emit(
      'modal:open',
      html`
        <modal-supplier-integrations
          account="${this.account}"
          .supplier="${this.supplier}"
          @updated=${(e) => {
            this.supplier.integrations = e.detail.integrations || undefined;
            this.requestUpdate();
          }}
        ></modal-supplier-integrations>
      `
    );
  }

  onUpdateEmailOverrides() {
    this.emit(
      'modal:open',
      html`
        <modal-supplier-email-overrides
          account="${this.account}"
          .supplier="${this.supplier}"
          @updated=${(e) => {
            this.supplier.email = e.detail.email || undefined;
            this.supplier.email_overrides =
              e.detail.email_overrides || undefined;
            this.requestUpdate();
          }}
        ></modal-supplier-email-overrides>
      `
    );
  }

  onChangePriceGroup() {
    const { created, price_group } = this.supplier;
    this.emit(
      'modal:open',
      html`
        <modal-account-supplier-price-group
          account="${this.account}"
          created="${created}"
          price-group="${price_group}"
          @updated=${(e) => {
            this.supplier.price_group = e.detail.price_group || undefined;
            this.requestUpdate();
          }}
        ></modal-account-supplier-price-group>
      `
    );
  }

  onDeliveries() {
    this.emit(
      'modal:open',
      html`
        <modal-account-supplier-deliveries
          account="${this.account}"
          .supplier=${this.supplier}
          @updated=${(e) => {
            Object.assign(this.supplier, e.detail);
            this.requestUpdate();
          }}
        ></modal-account-supplier-deliveries>
      `
    );
  }

  onOrderExcelFileFormat() {
    const { created, excel_file_format } = this.supplier;
    this.emit(
      'modal:open',
      html`
        <modal-account-supplier-excel-format
          account="${this.account}"
          created="${created}"
          excel-file-format="${excel_file_format || ''}"
          @updated=${(e) => {
            Object.assign(this.supplier, e.detail);
            this.requestUpdate();
          }}
        ></modal-account-supplier-excel-format>
      `
    );
  }

  render() {
    const { connect, name, archived } = this.supplier;
    return html`
      <div
        class="alert alert-${archived
          ? 'warning'
          : connect
            ? 'success'
            : 'info'} hstack gap-3"
      >
        <div>
          <i class="bi bi-${archived ? 'archive' : 'truck'} me-1"></i>
          ${name}
        </div>
        ${archived &&
        html`<div class="badge text-bg-warning">Deactivated</div>`}
        ${connect &&
        html`<div class="badge text-bg-${connectStatus(this.supplier)}">
          Connect
        </div>`}
        <div class="hstack gap-2 ms-auto">
          ${connect
            ? html`
                <a class="btn btn-sm btn-success" href="products">
                  <i class="bi bi-link me-1"></i>
                  Products
                </a>
                <button
                  class="btn btn-sm btn-danger ms-auto"
                  @click=${this.onUnlink}
                >
                  <i class="bi bi-x me-1"></i>
                  Unlink
                </button>
              `
            : html`
                <button
                  class="btn btn-sm btn-info ms-auto"
                  @click=${this.onConnect}
                >
                  <i class="bi bi-link me-1"></i>
                  Connect
                </button>
              `}
          ${archived
            ? html`
                <button
                  class="btn btn-sm btn-success ms-auto"
                  @click=${this.onToggleActive}
                >
                  <i class="bi bi-truck me-1"></i>
                  Activate
                </button>
              `
            : html`
                <button
                  class="btn btn-sm btn-danger ms-auto"
                  @click=${this.onToggleActive}
                >
                  <i class="bi bi-archive me-1"></i>
                  Deactivate
                </button>
              `}
        </div>
      </div>
      ${connect
        ? html`
            <div class="my-4">
              <a href="/connect-accounts/${connect.account}">Connect Account</a>
              ID:
              <shared-copy-id id=${connect.account}></shared-copy-id>
            </div>
          `
        : null}
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-1 row-cols-xl-2 g-4">
        ${this.renderCustomerIdCard()} ${this.renderCustomerPriceGroupCard()}
        ${this.renderIntegrationsCard()} ${this.renderDeliveryDetailsCard()}
        ${this.renderOrderExcelFormatCard()} ${this.renderEmailOverridesCard()}
      </div>
      <account-supplier-sites
        account="${this.account}"
        .supplier=${this.supplier}
        supplier_id=${this.supplier.created}
      ></account-supplier-sites>
    `;
  }

  renderCustomerIdCard() {
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack gap-3">
            <h5 class="mb-0 me-auto">Customer ID</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click=${this.onChangeCustomerId}
            >
              Update
            </button>
          </div>
          <div class="card-body">
            ${this.supplier.customer_id ||
            html`<div class="badge text-bg-danger">MISSING</div>`}
          </div>
        </div>
      </div>
    `;
  }

  renderCustomerPriceGroupCard() {
    if (!this.supplier.connect) {
      return nothing;
    }
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack gap-3">
            <h5 class="mb-0 me-auto">Customer Price Group</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click=${this.onChangePriceGroup}
            >
              Update
            </button>
          </div>
          <div class="card-body">${this.supplier.price_group || '–'}</div>
        </div>
      </div>
    `;
  }

  renderDeliveryDetailsCard() {
    if (!this.supplier.connect) {
      return nothing;
    }
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack gap-3">
            <h5 class="mb-0 me-auto">Deliveries</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click=${this.onDeliveries}
            >
              Update
            </button>
          </div>
          <div class="card-body">
            ${mapDeliveryDetails(this.supplier.connect)}
          </div>
        </div>
      </div>
    `;
  }

  renderOrderExcelFormatCard() {
    if (this.supplier.connect) {
      return nothing;
    }
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack gap-3">
            <h5 class="mb-0 me-auto">Order Excel Format</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click=${this.onOrderExcelFileFormat}
            >
              Update
            </button>
          </div>
          <div class="card-body">
            ${getExcelExportFormatLabel(
              this.supplier.excel_file_format || null
            )}
          </div>
        </div>
      </div>
    `;
  }

  renderIntegrationsCard() {
    const integrations = integrationMethods(this.supplier);
    if (!Object.keys(integrations).length) {
      return nothing;
    }
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack gap-3">
            <h5 class="mb-0 me-auto">Integrations</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click=${this.onUpdateIntegrations}
            >
              Update
            </button>
          </div>
          <div class="card-body">
            ${Object.entries(integrations).map(
              ([method, operations]) => html`
                <div class="my-2">
                  <h6>${method}</h6>
                  ${Object.entries(operations).map(
                    ([op, actions]) => html`
                      <div class="ps-2 my-2 hstack gap-2">
                        <strong class="me-auto">${op}</strong>
                        ${Object.entries(actions).map(
                          ([action, approved]) => html`
                            <div
                              class="badge text-bg-${approved
                                ? 'success'
                                : 'warning'}"
                            >
                              <i
                                class="bi ${approved
                                  ? 'bi-check-circle-fill'
                                  : 'bi-x-circle'}"
                              ></i>
                              ${action}
                            </div>
                          `
                        )}
                      </div>
                    `
                  )}
                </div>
              `
            )}
          </div>
        </div>
      </div>
    `;
  }

  renderEmailOverridesCard() {
    if (!this.supplier.connect) {
      return nothing;
    }
    const { email: contact_email, email_overrides, connect } = this.supplier;
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack gap-3">
            <h5 class="mb-0 me-auto">Email Overrides</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click=${this.onUpdateEmailOverrides}
            >
              Update
            </button>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <h6>Contact Email</h6>
              <div class="ms-2 mb-2">
                ${contact_email
                  ? html`<code>${contact_email}</code>`
                  : html`Not set - default:
                      <code>${connect.notifications.emails_default[0]}</code>`}
              </div>
            </li>
            <li class="list-group-item">
              ${email_overrides && Object.keys(email_overrides).length
                ? Object.entries(email_overrides).map(
                    ([operation, actions]) => html`
                      <h6 class="text-capitalize">${operation}</h6>
                      ${Object.entries(actions).map(
                        ([action, emails]) => html`
                          <div class="ms-2 mb-2">
                            <strong>${action}</strong>:
                            ${emails.map(
                              (email, index) =>
                                html`${index ? ', ' : ''}<code>${email}</code>`
                            )}
                          </div>
                        `
                      )}
                    `
                  )
                : html`No email overrides specified.`}
            </li>
          </ul>
        </div>
      </div>
    `;
  }
}

customElements.define('account-supplier-status', AccountSupplierStatus);
