import { html } from 'lit';
import { days_of_the_week } from '../../lib/date-and-time.js';
import { BaseElement } from '../base.js';

class SharedDeliveryDaysEditor extends BaseElement {
  static properties = {
    changes: { state: true },
    delivery_days: {}
  };

  constructor() {
    super();
    this.changes = {};
    this.delivery_days = {};
  }

  render() {
    return html`
      <div class="container">
        <div class="row gx-2 my-2">
          <div class="col-6"></div>
          <div class="col-3">Lead days</div>
          <div class="col-3">Cutoff time</div>
        </div>
        ${days_of_the_week.map((day, i) => this.renderDeliveryDay(day, i))}
      </div>
    `;
  }

  renderDeliveryDay(day, index) {
    const { delivery_days } = this;
    const is_allowed = delivery_days && delivery_days[index];
    const details = is_allowed ? delivery_days[index] : {};
    const { lead_days, cutoff } = details;
    return html`
      <div class="row gx-2 my-2">
        <div class="col-4">
          <strong>${day}</strong>
        </div>
        <div class="col-2">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              name="${day.toLowerCase()}-allowed"
              ?checked=${is_allowed}
              @change=${() => this.emit('delivery_days:toggleDay', index)}
            />
          </div>
        </div>
        <div class="col-3">
          <input
            type="number"
            autocomplete="off"
            class="form-control form-control-sm"
            name="${day.toLowerCase()}-lead_days"
            .value=${lead_days}
            ?disabled=${!is_allowed}
            @change=${(e) =>
              this.emit('delivery_days:setDayValue', {
                index,
                key: 'lead_days',
                value: e.target.value
              })}
          />
        </div>
        <div class="col-3">
          <input
            type="string"
            autocomplete="off"
            class="form-control form-control-sm"
            name="${day.toLowerCase()}-cutoff"
            .value=${cutoff || ''}
            pattern="\\d{2,2}:\\d{2,2}"
            ?disabled=${!is_allowed}
            @change=${(e) =>
              this.emit('delivery_days:setDayValue', {
                index,
                key: 'cutoff',
                value: e.target.value
              })}
          />
        </div>
      </div>
    `;
  }
}

customElements.define('shared-delivery-days-editor', SharedDeliveryDaysEditor);
