import { html, nothing } from 'lit';
import { ConnectCustomersController } from '../../lib/controllers/connect-customers.js';
import { BaseElement } from '../base.js';
import { connectStatus } from '../../lib/supplier-integrations.js';

export class ConnectAccountCustomers extends BaseElement {
  static properties = {
    account: { attribute: true },
    item: {}
  };

  constructor() {
    super();
    this.account = '';
    this.item = null;
    this.customers = new ConnectCustomersController(this);
  }

  render() {
    return this.customers.render((customers) => {
      return customers.length
        ? html`
            <div
              class="row row-cols-1 row-cols-md-2 row-cols-lg-1 row-cols-xl-2 g-4"
            >
              ${customers.map(customerCard(this.item))}
            </div>
          `
        : html`<h6>There are no customers yet</h6>`;
    });
  }
}

function customerCard(connect_account) {
  return (customer) => {
    const has_warning =
      connectStatus({
        connect: connect_account,
        integrations: customer.integrations
      }) === 'warning';
    const sites_link = `/accounts/${customer.account}/suppliers/${customer.supplier_id}/`;
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="mb-0 me-auto text-truncate">${customer.name}</h5>
          </div>
          <div class="card-body vstack gap-2">
            <small>
              ${statusIcon(customer.status)} Status:
              <strong class="text-${statusColor(customer.status)}">
                ${customer.status}
              </strong>
            </small>
            <small>
              <i class="bi bi-hash"></i>
              Customer ID:
              ${customer.customer_id
                ? html`
                    <shared-copy-id id=${customer.customer_id}></shared-copy-id>
                  `
                : html`<span class="text-muted">none</span>`}
            </small>
            <small>
              <i class="bi bi-building"></i>
              <a href="/accounts/${customer.account}/" class="link">
                Manage Account
              </a>
            </small>
            <div class="hstack">
              <small class="me-auto">
                <i class="bi bi-${customer.sites ? 'building' : 'truck'}"></i>
                <a href="${sites_link}">
                  ${customer.sites
                    ? html`Manage ${customer.sites.length} sites`
                    : html`Manage Supplier`}
                </a>
              </small>
              ${has_warning
                ? html`
                    <small class="badge text-bg-warning fw-normal">
                      <i class="bi bi-send"></i>
                      Missing integrations
                    </small>
                  `
                : nothing}
            </div>
          </div>
        </div>
      </div>
    `;
  };
}

function statusIcon(status) {
  return html`
    <i class="bi bi-circle-fill me-1 text-${statusColor(status)}"></i>
  `;
}

function statusColor(status) {
  switch (status) {
    case 'PENDING':
      return 'warning';
    case 'ACTIVE':
      return 'success';
    default:
      return 'danger';
  }
}

customElements.define('connect-account-customers', ConnectAccountCustomers);
