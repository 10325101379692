import { html } from 'lit';
import { BaseElement } from '../base.js';

export class Pagination extends BaseElement {
  static properties = {
    page_count: { attribute: 'page-count', type: Number },
    page_index: { attribute: 'page-index', type: Number },
    max_page_links: { attribute: 'max-page-links', type: Number }
  };

  constructor() {
    super();
    this.page_count = 0;
    this.page_index = 0;
    this.max_page_links = 7;
  }

  render() {
    const { page_count, page_index, max_page_links } = this;

    if (page_count <= 1) {
      return null;
    }
    const pages = new Array(Math.min(page_count - 2, max_page_links - 2));
    const page_offset = Math.max(
      Math.min(page_index - 2, page_count - max_page_links),
      0
    );
    pages.fill(0);

    const onSelectPage = (event) => {
      const { target } = event;
      const link = target.tagName === 'BUTTON' ? target : target.parentElement;
      const { classList } = link.parentElement;
      if (!classList.contains('disabled') && !classList.contains('active')) {
        this.emit('pagination:pageIndex', Number(link.dataset.page_index));
      }
    };

    return html`
      <nav>
        <ul class="pagination pagination-sm mb-0">
          <li class="page-item ${page_index === 0 ? 'disabled' : ''}">
            <button
              class="page-link"
              data-page_index=${page_index - 1}
              @click=${onSelectPage}
            >
              <i class="bi bi-chevron-left"></i>
            </button>
          </li>
          ${pageLink(0, page_index, onSelectPage)}
          ${page_offset === 0 ? null : spacer('pagination-spacer-left')}
          ${pages.map((_, i) =>
            pageLink(page_offset + i + 1, page_index, onSelectPage)
          )}
          ${pages.length + page_offset === page_count - 2
            ? null
            : spacer('pagination-spacer-right')}
          ${pageLink(page_count - 1, page_index, onSelectPage)}
          <li
            class="page-item ${page_index === page_count - 1 ? 'disabled' : ''}"
          >
            <button
              class="page-link"
              data-page_index=${page_index + 1}
              @click=${onSelectPage}
            >
              <i class="bi bi-chevron-right"></i>
            </button>
          </li>
        </ul>
      </nav>
    `;
  }
}

function pageLink(index, selection, onSelectPage) {
  return html`
    <li class="page-item ${index === selection ? 'active' : ''}">
      <button class="page-link" data-page_index=${index} @click=${onSelectPage}>
        ${index + 1}
      </button>
    </li>
  `;
}

function spacer(test_id) {
  return html`
    <li class="page-item" data-testid=${test_id}>
      <i class="page-link disabled bg-body bi bi-three-dots"></i>
    </li>
  `;
}

customElements.define('shared-pagination', Pagination);
