import { html } from 'lit';
import alertError from './html/alert-error.js';
import './elements/layouts/centered.js';
import './elements/layouts/app.js';
import './elements/accounts/index.js';
import './elements/connect-accounts/index.js';
import './elements/stats/index.js';
import './elements/reports/index.js';
import './elements/app/index.js';
import './elements/app/login.js';

export function pageLogin() {
  return renderCentered(html`<app-login></app-login>`);
}

export function pageIndex() {
  return renderApp(html`<app-index></app-index>`);
}

export function pageAccounts() {
  return renderApp(html`<app-accounts></app-accounts>`);
}

export function pageConnectAccounts() {
  return renderApp(html`<app-connect-accounts></app-connect-accounts>`);
}

export function pageStats() {
  return renderApp(html`<app-stats></app-stats>`);
}

export function pageReports() {
  return renderApp(html`<app-reports></app-reports>`);
}

export function page404() {
  return renderCentered(alertError('404'));
}

function renderApp(children) {
  return html`<layout-app>${children}</layout-app>`;
}

function renderCentered(children) {
  return html`<layout-centered>${children}</layout-centered>`;
}
