import { Task } from '@lit-labs/task';
import { request } from '../request.js';
import { TaskController } from './task.js';
import { mapAccount } from './accounts.js';

/**
 * @typedef {import('lit').LitElement} LitElement
 */

export class AccountController extends TaskController {
  /**
   * @param {LitElement} host
   */
  constructor(host) {
    super();

    this.task = new Task(
      host,
      async ([account]) => {
        const doc = await request('GET', `/accounts/${account}`);
        mapAccount(doc);
        return doc;
      },
      () => [host.getAttribute('account')]
    );
  }
}
