import { html } from 'lit';
import { AccountUsageController } from '../../lib/controllers/account-usage.js';
import { getTheme } from '../../lib/theme.js';
import { usageDays } from '../../lib/usage-days.js';
import { BaseElement } from '../base.js';

export class AccountUsage extends BaseElement {
  static properties = {
    account: {}
  };

  constructor() {
    super();
    this.account = '';
    this.usage = new AccountUsageController(this);
  }

  render() {
    return this.usage.render((usage) => {
      const { created } = usage.account;
      return html`
        <table class="table">
          <thead class="${getTheme() === 'dark' ? 'text-light' : 'text-dark'}">
            <tr>
              <th scope="col">Latest</th>
              <td scope="col">${usageDays(usage.latest, created)}</td>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr>
              <th scope="row">Shop</th>
              <td>${usageDays(usage.shop_session, created)}</td>
            </tr>
            <tr>
              <th scope="row">Delivery</th>
              <td>${usageDays(usage.shop_delivery, created)}</td>
            </tr>
            <tr>
              <th scope="row">Inspection</th>
              <td>${usageDays(usage.inspection, created)}</td>
            </tr>
            <tr>
              <th scope="row">Timeline</th>
              <td>${usageDays(usage.timeline, created)}</td>
            </tr>
          </tbody>
        </table>
      `;
    });
  }
}

customElements.define('account-usage', AccountUsage);
