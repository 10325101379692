import { LitElement } from 'lit';

export class BaseElement extends LitElement {
  createRenderRoot() {
    return this;
  }

  emit(event, detail) {
    this.dispatchEvent(
      new CustomEvent(event, { detail, bubbles: true, composed: true })
    );
  }

  on(event, handler) {
    this.addEventListener(event, (e) => {
      e.stopPropagation();
      handler.call(this, e.detail);
    });
  }
}
