import 'urlpattern-polyfill';
import { render, html } from 'lit';
import { Router } from '@lit-labs/router';
import { ContextProvider } from '@lit-labs/context';
import logger from '@studio/log';
import Console from '@studio/log-format/console.js';
import { router_context } from './lib/context.js';
import { BaseElement } from './elements/base.js';
import { ModalElement } from './elements/modal/base.js';
import {
  pageIndex,
  pageLogin,
  pageAccounts,
  pageConnectAccounts,
  pageStats,
  pageReports,
  page404
} from './pages.js';
import './elements/shared/index.js';
import './elements/modal/index.js';

logger.pipe(new Console());

class AppRoot extends BaseElement {
  static properties = {
    modal: { type: Object, attribute: false }
  };

  constructor() {
    super();

    this.on('modal:open', this.onModalOpen);
    this.on('modal:closed', this.onModalClosed);

    this.on('modal:alert', this.onModalAlert);
    this.on('modal:confirm', this.onModalConfirm);

    /** @type {ModalElement | null} */
    this.modal = null;

    this.router = new Router(this, [
      { path: '/', render: pageIndex },
      { path: '/login', render: pageLogin },
      { path: '/accounts/*', render: pageAccounts },
      { path: '/connect-accounts/*', render: pageConnectAccounts },
      { path: '/stats/*', render: pageStats },
      { path: '/reports/*', render: pageReports },
      { path: '*', render: page404 }
    ]);
    this.routerProvider = new ContextProvider(
      this,
      router_context,
      this.router
    );
  }

  connectedCallback() {
    super.connectedCallback();

    this.handleError = (event) => {
      this.onModalAlert({
        message: getMessageForEvent(event)
      });
    };
    window.addEventListener('error', this.handleError);
    window.addEventListener('unhandledrejection', this.handleError);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    if (this.handleError) {
      window.removeEventListener('error', this.handleError);
      window.removeEventListener('unhandledrejection', this.handleError);
    }
  }

  onModalOpen(modal) {
    const current_modal = /** @type {ModalElement | null} */ (
      this.querySelector('#app-modal')
    );
    if (current_modal) {
      current_modal.close();
      this.modal = modal;
    } else {
      this.modal = modal;
    }
  }

  onModalClosed() {
    this.modal = null;
  }

  /**
   * @param {Object} config
   * @param {string} [config.title]
   * @param {string} config.message
   */
  onModalAlert({ title, message }) {
    this.onModalOpen(html`
      <modal-alert
        title="${title || 'Oups!'}"
        message="${message}"
        @confirm=${confirm}
      ></modal-alert>
    `);
  }

  onModalConfirm({ description, details, confirm }) {
    this.onModalOpen(html`
      <modal-confirm
        description="${description || ''}"
        details="${details || ''}"
        @confirm=${confirm}
      ></modal-confirm>
    `);
  }

  render() {
    return html`${this.router.outlet()}${this.modal}`;
  }
}

customElements.define('app-root', AppRoot);

render(html`<app-root></app-root>`, document.body);

function getMessageForEvent(event) {
  if (event.reason) {
    return [event.reason.code, event.reason.message].filter(Boolean).join(': ');
  }
  return event.message || String(event);
}
