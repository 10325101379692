import { html, nothing } from 'lit';
import { BaseElement } from '../base.js';

export class AccountsList extends BaseElement {
  static properties = {
    items: { state: true }
  };

  constructor() {
    super();
    this.items = [];
  }

  render() {
    return html`
      <div class="list-group">
        ${this.items.length === 0
          ? html`<p class="m-3">No accounts found</p>`
          : nothing}
        ${this.items.map(
          (item) => html`
            <a
              href="${item.account}/"
              class="list-group-item list-group-item-action hstack"
            >
              <div class="me-auto">
                ${renderAccountTypeIcon(item)} ${item.display_name}
              </div>
              <small class="d-none d-lg-block text-muted ms-2">
                ${item.email}
              </small>
            </a>
          `
        )}
      </div>
    `;
  }
}

function renderAccountTypeIcon(item) {
  return html`<i
    class="bi bi-${item.principals ? 'person' : 'building'} me-2"
  ></i>`;
}

customElements.define('accounts-list', AccountsList);
