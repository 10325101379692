import { BaseElement } from './base.js';

/**
 * @template {BaseElement} T
 * @typedef {new (...args: any[]) => T} Constructor
 */

// eslint-disable-next-line jsdoc/require-returns
/**
 * @template {Constructor<BaseElement>} T
 * @param {T} SuperClass
 */
export const paginated = (SuperClass) =>
  class extends SuperClass {
    /**
     *
     * @param {...any} _args
     */
    constructor(..._args) {
      super();
      this.page_size = 10;
      this.page_index = 0;

      this.on('pagination:pageIndex', (page_index) => {
        this.page_index = page_index;
        this.requestUpdate();
      });
    }

    /**
     * @template T
     * @param {T[]} items
     * @returns {number}
     */
    getPageCount(items) {
      return Math.ceil(items.length / this.page_size);
    }

    /**
     * @template T
     * @param {T[]} items
     * @returns {T[]}
     */
    page(items) {
      const from_index = this.page_index * this.page_size;
      return items.slice(from_index, from_index + this.page_size);
    }
  };
