import { html } from 'lit';

export default function (config) {
  const selected =
    config.options.find((o) => o.value === config.selected) ||
    config.options[0];
  const icon = selected.icon || config.icon;
  return html`
    <button
      class="input-group-text dropdown-toggle"
      type="button"
      data-bs-toggle="dropdown"
      ?disabled=${config.disabled}
    >
      ${icon ? html`<i class="bi bi-${icon}"></i>` : null}
      ${config.name ? config.name : null}
    </button>
    <ul class="dropdown-menu dropdown-menu-end">
      ${config.options.map(
        ({ name, value }) => html`
          <li>
            <a
              class="dropdown-item ${config.selected === value ? 'active' : ''}"
              href="#"
              @click=${() => config.onSelect(value)}
              >${name}</a
            >
          </li>
        `
      )}
    </ul>
  `;
}
