import { html } from 'lit';
import { request } from '../../../lib/request.js';
import { deepCopy } from '../../../lib/deep-copy.js';
import { ORDER_ACTIONS, ORDER_OPERATIONS } from '../../../lib/constants.js';
import { ModalElement } from '../../modal/base.js';

class ModalEmailOverrides extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    email_overrides: { state: true },
    email: { state: true },
    supplier: {},
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Supplier Email Overrides';
    this.account = '';
    this.email_overrides = {};
    this.email = '';
    this.supplier = null;
    this.saving = false;
  }

  connectedCallback() {
    super.connectedCallback();
    const { email, email_overrides } = this.supplier;

    this.email_overrides = email_overrides ? deepCopy(email_overrides) : {};
    this.email = email || '';
  }

  async onSave() {
    this.saving = true;
    const email = this.email.trim() || null;
    const email_overrides = Object.keys(this.email_overrides).length
      ? this.email_overrides
      : null;

    const updated = await request(
      'PATCH',
      `/accounts/${this.account}/suppliers/${this.supplier.created}`,
      {
        email,
        email_overrides
      }
    );
    this.emit('updated', updated);
    this.close();
  }

  inputEmailOverride(operation, action) {
    return (event) => {
      const emails = event.target.value
        .split(',')
        .map((email) => email.trim().toLowerCase())
        .filter(Boolean);

      if (!emails.length) {
        delete this.email_overrides[operation][action];
        if (!Object.keys(this.email_overrides[operation]).length) {
          delete this.email_overrides[operation];
        }
      } else {
        if (!this.email_overrides[operation]) {
          this.email_overrides[operation] = {};
        }

        this.email_overrides[operation][action] = emails;
      }
      this.requestUpdate();
    };
  }

  renderBody() {
    const { email, email_overrides } = this;

    return html`
      <div class="mt-3">
        <h6>Contact Email</h6>
        <div class="ms-2 mb-2">
          <input
            type="text"
            .value=${email}
            class="form-control"
            @change=${(event) => (this.email = event.target.value)}
          />
        </div>
      </div>
      <div class="mt-4">
        ${ORDER_OPERATIONS.map(
          (operation) => html`
            <h6 class="text-capitalize">${operation}</h6>
            ${ORDER_ACTIONS.map(
              (action) => html`
                <div class="ms-2 mb-2">
                  <label class="form-label">${action}</label>
                  <input
                    type="text"
                    .value=${(email_overrides[operation]?.[action] || []).join(
                      ', '
                    )}
                    class="form-control"
                    @change=${this.inputEmailOverride(operation, action)}
                  />
                </div>
              `
            )}
          `
        )}
      </div>
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Update
      </button>
    `;
  }
}

customElements.define('modal-supplier-email-overrides', ModalEmailOverrides);
