import { Task } from '@lit-labs/task';
import { request } from '../request.js';
import { TaskController } from './task.js';
import alertError from '../../html/alert-error.js';
import spinner from '../../html/spinner.js';

/**
 * @typedef {import('lit').LitElement} LitElement
 */

const PAGE_SIZE = 10;

export const status_options = [
  {
    value: '',
    name: 'All',
    icon: 'asterisk'
  },
  {
    value: 'ACTIVE',
    name: 'Active',
    icon: 'check-circle'
  },
  {
    value: 'INACTIVE',
    name: 'Inactive',
    icon: 'trash'
  },
  {
    value: 'PENDING',
    name: 'Pending',
    icon: 'airplane'
  }
];

export const feature_options = [
  {
    value: '',
    name: 'All',
    icon: 'flag'
  },
  {
    value: 'MIGRATED',
    name: 'Migrated',
    icon: 'hand-thumbs-up'
  },
  {
    value: 'SHOP',
    name: 'Shop',
    icon: 'cart'
  },
  {
    value: 'STOCK_TRACKING',
    name: 'Stock tracking',
    icon: 'box'
  },
  {
    value: 'INSPECTIONS',
    name: 'Inspections',
    icon: '123'
  },
  {
    value: 'INTERNAL_ORDERS',
    name: 'Internal orders',
    icon: 'send'
  }
];

export const type_options = [
  {
    value: 'main',
    name: 'Main accounts',
    icon: 'building'
  },
  {
    value: 'staff',
    name: 'Staff accounts',
    icon: 'person'
  }
];

export class AccountsController extends TaskController {
  /**
   * @param {LitElement} host
   */
  constructor(host) {
    super();

    this.host = host;
    this.results = null;
    this.search = null;
    this.status = null;
    this.feature = null;
    this.type = 'main';
    this.page_index = 0;
    this.task = new Task(
      host,
      async () => {
        const query = {
          offset: String(this.page_index * PAGE_SIZE),
          type: this.type
        };
        if (this.search) {
          query.search = this.search;
        }
        if (this.status) {
          query.status = this.status;
        }
        if (this.feature) {
          query.feature = this.feature;
        }
        const query_string = new URLSearchParams(query).toString();
        const res = await request(
          'GET',
          `/accounts${query_string ? `?${query_string}` : ''}`
        );
        this.results = {
          accounts: res.list.map(mapAccount),
          total: res.total
        };
        return this.results;
      },
      () => []
    );
  }

  setStatus(status) {
    if (status === this.status) {
      return;
    }
    this.status = status;
    this.page_index = 0;
    this.task.run();
  }

  setFeature(feature) {
    if (feature === this.feature) {
      return;
    }
    this.feature = feature;
    this.page_index = 0;
    this.task.run();
  }

  setType(type) {
    if (type === this.type) {
      return;
    }
    this.type = type;
    this.page_index = 0;
    this.task.run();
  }

  get pagination() {
    return {
      page_index: this.page_index,
      page_count: this.results ? Math.ceil(this.results.total / PAGE_SIZE) : 0
    };
  }

  setPage(page) {
    if (page === this.page_index) {
      return;
    }
    this.page_index = page;
    this.task.run();
  }

  setSearch(search) {
    if (search === this.search) {
      return;
    }

    this.search = search;
    this.page_index = 0;
    if (search.length !== 1) {
      this.task.run();
    }
  }

  clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  setSpinnerTimeout() {
    if (this.timeout) {
      return;
    }
    this.timeout = setTimeout(() => {
      this.accounts = null;
      this.host.requestUpdate();
    }, 250);
  }

  render(complete) {
    return this.task.render({
      pending: () => {
        if (this.results) {
          this.setSpinnerTimeout();
          return complete(this.results);
        }

        return spinner();
      },

      error: alertError,

      complete: (results) => {
        this.clearTimeout();

        return complete(results);
      }
    });
  }
}

export function mapAccount(item) {
  item.display_name = item.company || nameFallback(item);
  return item;
}

function nameFallback(item) {
  return [item.first_name, item.last_name].filter(Boolean).join(' ');
}
