import { html } from 'lit';
import { getPackagingTypeName } from '@yourbarmate/packaging-types';
import {
  unitGroupForUnit,
  unitMl,
  convertVesselUnit
} from '@yourbarmate/commons/unit.js';
import { formatPrice } from '@yourbarmate/commons/price.js';
import { formatRelativeDate } from '@yourbarmate/commons/date-and-time/index.js';
import { AccountSupplierProductsController } from '../../../lib/controllers/account-supplier-products.js';
import { BaseElement } from '../../base.js';
import alertInfo from '../../../html/alert-info.js';
import { request } from '../../../lib/request.js';
import { paginated } from '../../paginated.js';

const status_icon = {
  CONNECTED: 'bi-link-45deg',
  NOT_CONNECTED: 'bi-type-strikethrough',
  ARCHIVE: 'bi-type-strikethrough',
  DISCONNECT: 'bi-type-strikethrough',
  REIMPORT: 'bi-arrow-left-right'
};

const can_edit_product = {
  NOT_CONNECTED: true,
  MISSING_CONNECT_PRODUCT: true,
  MISSING_CODE: true,
  DUPLICATE: true
};

const show_archive_or_disconnect = {
  NOT_CONNECTED: true,
  MISSING_CONNECT_PRODUCT: true,
  MISSING_CODE: true,
  ARCHIVE: true,
  DISCONNECT: true
};

export class AccountSupplierProducts extends paginated(BaseElement) {
  static properties = {
    account: { type: String },
    created: { type: Number },
    connect: { type: String },
    updates: { state: true },
    archives: { state: true },
    disconnects: { state: true },
    reimports: { state: true },
    saving: { state: true }
  };

  constructor() {
    super();
    this.account = null;
    this.created = null;
    this.connect = null;
    this.updates = {};
    this.archives = [];
    this.disconnects = [];
    this.reimports = new Set();
    this.saving = false;
    this.products = new AccountSupplierProductsController(this);
  }

  render() {
    return this.products.render((results) => {
      const page = this.page(results);
      const has_unconnected_products = Boolean(
        this.connect && results.find(isNotConnected)
      );
      const has_updates =
        this.reimports.size ||
        this.archives.length !== 0 ||
        this.disconnects.length !== 0 ||
        Object.keys(this.updates).length !== 0;
      const can_connect =
        !has_updates && results.every((result) => isStatusOkay(result.status));

      return html`
        ${results.length
          ? html`
              <div class="list-group mb-5 pb-5">
                ${page.map((result) => this.renderResult(result))}
              </div>
            `
          : alertInfo('No results for this supplier found in the account.')}
        <shared-actions-bar>
          <shared-pagination
            class="ms-auto"
            page-count=${this.getPageCount(results)}
            page-index=${this.page_index}
          ></shared-pagination>
          <button
            class="btn btn-primary"
            ?disabled=${this.saving || !has_updates}
            @click=${this.onSaveChangedProductCodes}
          >
            Save Updates
          </button>
          ${this.connect
            ? null
            : html`
                <button
                  class="btn btn-success"
                  ?disabled=${this.saving || !can_connect}
                  @click=${this.onConnectSupplier}
                >
                  Connect Supplier
                </button>
              `}
          ${has_unconnected_products
            ? html`
                <button
                  class="btn btn-success"
                  ?disabled=${this.saving || !can_connect}
                  @click=${this.onConnectSupplier}
                >
                  Connect Products
                </button>
              `
            : null}
        </shared-actions-bar>
      `;
    });
  }

  renderResult(result) {
    const { status, client, connect } = result;
    const vessel_match =
      !connect.name || vesselMatch(client.vessel, connect.vessel);

    return html`
      <div class="list-group-item">
        <div class="row align-items-center">
          <div class="col-4">
            <div class="input-group">
              <span class="input-group-text" title="${status}">
                ${renderProductStatus(status, vessel_match)}
              </span>
              <input
                type="text"
                class="form-control text-end ${connect.id
                  ? ''
                  : 'border-danger'}"
                .value=${connect.id}
                ?disabled=${!can_edit_product[status]}
                @change=${(e) =>
                  this.onChangeProductCode(result, e.target.value)}
              />
              ${show_archive_or_disconnect[status]
                ? connect.id && !client.archived && !client.deleted
                  ? html`
                      <button
                        class="input-group-text text-danger"
                        @click=${() => this.onArchiveProduct(result)}
                      >
                        <i
                          class="bi ${status === 'ARCHIVE'
                            ? 'bi-arrow-counterclockwise'
                            : 'bi-archive'}"
                        ></i>
                      </button>
                    `
                  : html`
                      <button
                        class="input-group-text text-danger"
                        @click=${() => this.onDisconnectProduct(result)}
                      >
                        <i
                          class="bi ${status === 'DISCONNECT'
                            ? 'bi-arrow-counterclockwise'
                            : 'bi-trash'}"
                        ></i>
                      </button>
                    `
                : null}
              ${status === 'ARCHIVE' ||
              vessel_match ||
              client.archived ||
              client.deleted
                ? null
                : html`
                    <button
                      class="input-group-text text-danger"
                      @click=${() => this.onReimportProduct(result)}
                    >
                      <i
                        class="bi ${status === 'REIMPORT'
                          ? 'bi-arrow-counterclockwise'
                          : 'bi-arrow-left-right'}"
                      ></i>
                    </button>
                  `}
            </div>
          </div>
          <div class="col-4">
            <div class="text-truncate">
              <small><i class="bi bi-person me-1"></i> ${client.name}</small>
            </div>
            <div class="text-truncate">
              <small>
                <i class="bi bi-truck me-1"></i>
                ${connect.name ||
                html`<div class="badge text-bg-danger">Missing</div>`}
              </small>
            </div>
          </div>
          <div class="col-3 ${vessel_match ? '' : 'text-warning'}">
            ${renderVessel(client.vessel)}<br />
            ${renderVessel(connect.vessel)}
          </div>
          <div class="col-1 text-end">
            ${formatPrice(client.price)}<br />
            ${formatPrice(connect.price) || '–'}
          </div>
        </div>
        <div class="row align-items-center pt-1 text-secondary">
          <small class="col-4">
            Delivered:
            ${client.master_data_locked
              ? html`<i class="bi bi-cart-check-fill ms-1"></i>`
              : '–'}
          </small>
          <small class="col-4">
            ${client.deleted
              ? `Deleted: ${formatRelativeDate(client.deleted, 'en')}`
              : client.archived
                ? `Deactivated: ${formatRelativeDate(client.archived, 'en')}`
                : '–'}
          </small>
          <small class="col-4">Connect: ${connect.status}</small>
        </div>
      </div>
    `;
  }

  async onChangeProductCode(result, value) {
    const previous_status = result.status;
    const previous_value = result.connect.id;
    result.connect.id = value;
    if (!value) {
      result.status = 'NOT_CONNECTED';
      this.updates[result.client.id] = '';
      this.requestUpdate();
      return;
    }
    result.status = 'REFRESH';
    this.saving = true;
    try {
      const search = new URLSearchParams();
      search.append('connect', this.getConnectAccount());
      search.append('code', result.client.id);
      search.append('id', value);
      const res = await request(
        'GET',
        `/accounts/${this.account}/suppliers/${this.created}/products?${search}`
      );
      Object.assign(result, res);
      this.updates[result.client.id] = res.connect.id;
    } catch (e) {
      result.status = previous_status;
      result.connect.id = previous_value;
      throw e;
    } finally {
      this.saving = false;
    }
  }

  onArchiveProduct(result) {
    if (result.status === 'ARCHIVE') {
      result.status = 'NOT_CONNECTED';
      this.archives.splice(this.archives.indexOf(result.client.id), 1);
      if (result.connect.id) {
        this.updates[result.client.id] = result.connect.id;
      }
    } else {
      result.status = 'ARCHIVE';
      this.archives.push(result.client.id);
      delete this.updates[result.client.id];
    }
    this.requestUpdate();
  }

  onDisconnectProduct(result) {
    if (result.status === 'DISCONNECT') {
      result.status = 'NOT_CONNECTED';
      this.disconnects.splice(this.disconnects.indexOf(result.client.id), 1);
      if (result.connect.id) {
        this.updates[result.client.id] = result.connect.id;
      }
    } else {
      result.status = 'DISCONNECT';
      this.disconnects.push(result.client.id);
      delete this.updates[result.client.id];
    }
    this.requestUpdate();
  }

  onReimportProduct(result) {
    if (result.status === 'REIMPORT') {
      result.status = 'NOT_CONNECTED';
      this.reimports.delete(result.client.id);
    } else {
      result.status = 'REIMPORT';
      this.reimports.add({
        client: result.client.id,
        connect: result.connect.id
      });
    }
    this.requestUpdate();
  }

  async onSaveChangedProductCodes() {
    this.saving = true;
    try {
      await request(
        'PATCH',
        `/accounts/${this.account}/suppliers/${this.created}/products`,
        {
          updates: this.updates,
          archives: this.archives,
          disconnects: this.disconnects,
          reimports: Array.from(this.reimports),
          connect_account: this.getConnectAccount()
        }
      );
      this.updates = {};
      this.archives = [];
      this.disconnects = [];
      this.reimports.clear();
      this.products.reload();
      this.requestUpdate();
    } finally {
      this.saving = false;
    }
  }

  async onConnectSupplier() {
    this.saving = true;
    const connect = this.getConnectAccount();
    try {
      await request(
        'PATCH',
        `/accounts/${this.account}/suppliers/${this.created}`,
        { connect }
      );
    } finally {
      this.saving = false;
    }
    this.connect = connect;
    this.products.reload();
  }

  /**
   * @returns {string}
   */
  getConnectAccount() {
    if (this.connect) {
      return this.connect;
    }
    const search = new URLSearchParams(location.search);
    return /** @type {string} */ (search.get('connect'));
  }
}

function renderProductStatus(status, vessel_match) {
  if (status === 'REFRESH') {
    return html`<span class="spinner-border spinner-border-sm"></span>`;
  }
  const ok = isStatusOkay(status);
  const style = ok
    ? vessel_match
      ? 'text-success'
      : 'text-warning'
    : 'text-danger';
  const icon = status_icon[status] || 'bi-exclamation-circle-fill';

  return html`<i class="bi ${icon} ${style}"></i>`;
}

function isStatusOkay(status) {
  return status === 'CONNECTED' || status === 'NOT_CONNECTED';
}

function isNotConnected(item) {
  return item.status !== 'CONNECTED';
}

function vesselMatch(client_vessel, connect_vessel) {
  if (!connect_vessel) {
    return !client_vessel;
  }
  if (!client_vessel) {
    return false;
  }
  if (
    unitGroupForUnit(client_vessel.unit) !==
    unitGroupForUnit(connect_vessel.unit)
  ) {
    return false;
  }
  return (
    unitMl(client_vessel.unit) * client_vessel.size ===
    unitMl(connect_vessel.unit) * connect_vessel.size
  );
}

function renderVessel(vessel) {
  if (!vessel) {
    return '–';
  }
  const { size, unit, display_unit, type } = vessel;
  if (!unit) {
    return '–';
  }
  const str = display_unit
    ? `${convertVesselUnit(size, unit, display_unit).size} ${display_unit}`
    : `${size} ${unit}`;
  return type ? `${str} ${getPackagingTypeName(type, 'en')}` : str;
}

customElements.define('account-supplier-products', AccountSupplierProducts);
