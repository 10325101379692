import { Task } from '@lit-labs/task';
import { request } from '../request.js';
import { TaskController } from './task.js';

/**
 * @typedef {import('lit').LitElement} LitElement
 */

export class AccountSupplierListController extends TaskController {
  /**
   * @param {LitElement} host
   */
  constructor(host) {
    super();

    this.host = host;
    this.items = [];
    this.task = new Task(
      host,
      async ([account]) => {
        const res = await request('GET', `/accounts/${account}/suppliers`);
        this.items = res.list;
        return this.items;
      },
      () => [host.getAttribute('account')]
    );
  }

  /**
   * @param {Object} supplier
   */
  add(supplier) {
    this.items.push(supplier);
    this.host.requestUpdate();
  }
}
