import { html, nothing } from 'lit';
import { AccountLocationsController } from '../../lib/controllers/account-locations.js';
import { request } from '../../lib/request.js';
import { BaseElement } from '../base.js';
import './new-location-modal.js';
import './new-division-modal.js';
import './setup-divisions-modal.js';
import './setup-locations.js';
import { formatRelativeDate } from '@yourbarmate/commons/date-and-time/index.js';

export class AccountLocations extends BaseElement {
  static properties = {
    account: {}
  };

  constructor() {
    super();
    this.account = '';
    this.locations = new AccountLocationsController(this);
  }

  onArchiveLocation(e) {
    this.emit('modal:confirm', {
      description: `Do you really want to archive this area?`,
      confirm: () =>
        this.changeLocationArchiveDate('POST', e.target.dataset.code)
    });
  }

  onActivateLocation(e) {
    this.emit('modal:confirm', {
      description: `Do you really want to activate this area?`,
      confirm: () =>
        this.changeLocationArchiveDate('DELETE', e.target.dataset.code)
    });
  }

  onAddLocation(div_id, grp_id) {
    const { divisions, location_groups } = this.locations;
    const division = div_id && divisions.find(({ id }) => id === div_id);
    const group = grp_id && location_groups.find(({ id }) => id === grp_id);
    this.emit(
      'modal:open',
      html`
        <modal-new-location
          account=${this.account}
          .division=${division}
          .group=${group}
          @added=${({ detail }) => this.locations.addLocation(detail)}
        ></modal-new-location>
      `
    );
  }

  onAddLocations(setup) {
    const { bars, storages } = setup;
    const all_locations = [
      ...storages
        .filter(Boolean)
        .map((display_name) => ({ type: 'storage', display_name })),
      ...bars
        .filter(Boolean)
        .map((display_name) => ({ type: 'bar', display_name }))
    ];
    this.emit('modal:confirm', {
      description: 'Create the following areas?',
      details: all_locations
        .map(({ type, display_name }) => `${display_name}(${type})`)
        .join(', '),
      confirm: async () => {
        const new_locations = await request(
          'POST',
          `/accounts/${this.account}/locations`,
          all_locations
        );

        this.locations.addLocation(...new_locations);
      }
    });
  }

  onAddDivision() {
    this.emit(
      'modal:open',
      html`
        <modal-new-division
          account=${this.account}
          @added=${({ detail }) => this.locations.addDivision(detail)}
        ></modal-new-division>
      `
    );
  }

  onSetupDivisions() {
    const { locations, location_groups } = this.locations;
    this.emit(
      'modal:open',
      html`
        <modal-setup-divisions
          account=${this.account}
          .locations=${locations}
          .location_groups=${location_groups}
          @done=${() => this.locations.reload()}
        ></modal-setup-divisions>
      `
    );
  }

  async changeLocationArchiveDate(method, code) {
    const location = await request(
      method,
      `/accounts/${this.account}/locations/${code}/archived`
    );
    this.locations.updateLocation(location);
  }

  render() {
    return this.locations.render(
      ({ divisions, locations, location_groups }) => {
        if (!divisions.length && !locations.length) {
          return this.renderNewAccount();
        }
        if (divisions.length) {
          return this.renderDivisions(divisions, locations, location_groups);
        }
        return this.renderLocations(locations, location_groups);
      }
    );
  }

  renderNewAccount() {
    return html`
      <account-locations-setup
        @addDivision=${() => this.onAddDivision()}
        @createLocations=${({ detail }) => this.onAddLocations(detail)}
      >
      </account-locations-setup>
    `;
  }

  renderDivisions(divisions, locations, location_groups) {
    return html`
      ${divisions.map(
        (division) => html`
          <h3>
            <span>${division.name}</span>
            <code class="ms-2 fs-6">${division.id}</code>
          </h3>
          ${this.renderLocations(
            locations.filter((l) => l.division === division.id),
            location_groups.filter((l) => l.division === division.id),
            division.id
          )}
        `
      )}
      <div class="my-2">&nbsp;</div>
      <div class="fixed-bottom p-3 bg-body shadow">
        <div class="container d-flex justify-content-between">
          <button class="btn btn-primary" @click=${() => this.onAddDivision()}>
            Add New Location
          </button>
        </div>
      </div>
    `;
  }

  renderLocations(locations, location_groups, division) {
    const locations_by_groups = {};
    for (const group of location_groups) {
      locations_by_groups[group.id] = [];
    }
    const remaining_locations = [];
    for (const location of locations) {
      if (location.group) {
        locations_by_groups[location.group].push(location);
      } else {
        remaining_locations.push(location);
      }
    }
    return html`
      <div class="list-group mb-5">
        ${location_groups.map(
          (location_group) => html`
            <div class="list-group-item py-3">
              <h5>
                ${renderType(location_group.type)}
                ${location_group.display_name}
              </h5>
              <div class="list-group">
                ${this.renderLocationRows(
                  locations_by_groups[location_group.id]
                )}
              </div>
              ${this.renderAddButton(division, location_group.id)}
            </div>
          `
        )}
        ${this.renderLocationRows(remaining_locations)}
        ${this.renderAddButton(division)}
        ${division
          ? nothing
          : html`
              <div class="my-2">&nbsp;</div>
              <div class="fixed-bottom p-3 bg-body shadow">
                <div class="container d-flex justify-content-between">
                  <button
                    class="btn btn-primary"
                    @click=${() => this.onSetupDivisions()}
                  >
                    Set up locations
                  </button>
                </div>
              </div>
            `}
      </div>
    `;
  }

  renderLocationRows(locations) {
    return locations.map(
      (location) => html`
        <div
          class="list-group-item d-flex align-items-center justify-content-between"
        >
          <div class="d-flex">
            <code class="me-2">${location.code}</code>
            ${renderType(location.type)}
            <h6 class="mb-0">${location.display_name}</h6>
          </div>
          ${location.group
            ? null
            : html`
                <div>
                  ${location.archived
                    ? html`
                        <shared-tooltip>
                          <div
                            class="badge text-bg-warning me-2"
                            data-bs-toggle="tooltip"
                            data-bs-title="${formatRelativeDate(
                              location.archived,
                              'en'
                            )}"
                          >
                            Archived
                          </div>
                        </shared-tooltip>
                        <button
                          class="btn btn-sm btn-outline-secondary"
                          data-code="${location.code}"
                          @click=${this.onActivateLocation}
                        >
                          Activate
                        </button>
                      `
                    : html`
                        <button
                          class="btn btn-sm btn-outline-secondary"
                          data-code="${location.code}"
                          @click=${this.onArchiveLocation}
                        >
                          Archive
                        </button>
                      `}
                </div>
              `}
        </div>
      `
    );
  }

  renderAddButton(division, group) {
    return html`
      <div class="mt-2">
        <button
          type="button"
          class="btn btn-primary btn-sm"
          @click=${() => this.onAddLocation(division, group)}
        >
          <i class="bi bi-plus me-1"></i>
          Add area
        </button>
      </div>
    `;
  }
}

customElements.define('account-locations', AccountLocations);

function renderType(type) {
  return html`<span class="badge text-bg-secondary me-2">${type}</span>`;
}
