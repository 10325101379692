import { nothing } from 'lit';
import { Task } from '@lit-labs/task';
import spinner from '../../html/spinner.js';
import alertError from '../../html/alert-error.js';

/**
 * @typedef {import('lit').TemplateResult} TemplateResult
 */

export class TaskController {
  /** @type {Task | null} */
  _task = null;

  set task(task) {
    if (task) {
      // We have a generic error handler, we don't want the promise rejection.
      task.taskComplete.catch(() => {});
    }
    this._task = task;
  }

  get task() {
    return this._task;
  }

  /**
   * @param {(items: Array | Object) => typeof nothing | TemplateResult | TemplateResult[]} complete
   * @returns {unknown}
   */
  render(complete) {
    if (!this._task) {
      return alertError('Missing task');
    }
    return this._task.render({
      pending: spinner,
      error: alertError,
      complete
    });
  }
}
