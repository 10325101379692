import { html } from 'lit';
import { BaseElement } from '../base.js';
import { Modal } from 'bootstrap';

/**
 * @typedef {import('lit').TemplateResult} TemplateResult
 */

export class ModalElement extends BaseElement {
  static properties = {
    title: {},
    css: {},
    size: {}
  };

  constructor() {
    super();
    this.css = '';
    this.size = '';
  }

  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('id', 'app-modal');
  }

  firstUpdated() {
    const node = this.querySelector('.modal');
    this.modal = new Modal(node);
    node?.addEventListener('hidden.bs.modal', () => {
      this.modal.dispose();
      node.parentNode?.removeChild(node);
      this.emit('modal:closed');
    });
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

  render() {
    return html`
      <div
        class="modal fade ${this.size ? `modal-${this.size}` : ''}"
        tabindex="-1"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog">
          <div class="modal-content ${this.css}">
            <div class="modal-header">${this.renderHeader()}</div>
            <div class="modal-body">${this.renderBody()}</div>
            <div class="modal-footer">${this.renderFooter()}</div>
          </div>
        </div>
      </div>
    `;
  }

  /**
   * @returns {TemplateResult | null | unknown}
   */
  renderHeader() {
    return html`
      <h5 class="modal-title">${this.title}</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
    `;
  }

  /**
   * @returns {TemplateResult | string | null | unknown}
   */
  renderBody() {
    return null;
  }

  /**
   * @returns {TemplateResult | null | unknown}
   */
  renderFooter() {
    return null;
  }
}
