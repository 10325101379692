import { html, nothing } from 'lit';
import { stringPropertyComparator } from '@yourbarmate/commons/string-comparator.js';
import { request } from '../../lib/request.js';
import { AccountLocationsController } from '../../lib/controllers/account-locations.js';
import { ModalElement } from '../modal/base.js';

class ModalAccountStripeSub extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    stripe_info: {},
    product: {},
    pricing: {},
    tax_rate: {},
    quantity: {},
    coupon: {},
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Stripe Subscription';
    this.account = '';
    this.stripe_info = { products: [] };
    this.locations = new AccountLocationsController(this);
    this.product = null;
    this.pricing = null;
    this.tax_rate = null;
    this.quantity = 0;
    this.coupon = null;
    this.saving = false;
  }

  async onSubscribe() {
    this.saving = true;
    const json = {
      product: this.product.id,
      pricing: this.pricing.id,
      quantity: Number(this.quantity)
    };
    if (this.tax_rate) {
      json.tax_rate = this.tax_rate.id;
    }
    if (this.coupon) {
      json.coupon = this.coupon.id;
    }

    const response = await request(
      'POST',
      `/accounts/${this.account}/stripe/subscription`,
      json
    );
    this.emit('updated', response);
    this.close();
  }

  estimate() {
    const { quantity, pricing } = this;
    if (!pricing) {
      return '';
    }

    const discount = this.coupon?.amount_off || 0;
    const percent_off = this.coupon?.percent_off || 0;
    const tax_rate = this.tax_rate?.percentage || 0;
    const percentage = (100 - percent_off) / 100;

    return `${
      (pricing.unit_amount * quantity * percentage - discount) / 100
    } ${pricing.currency.toUpperCase()}${recurrence(this.pricing)}${
      tax_rate ? ` + ${tax_rate}% tax` : ''
    }`;
  }

  selectProduct(event) {
    this.product = this.stripe_info.products.find(
      ({ id }) => id === event.target.value
    );
    this.pricing = this.stripe_info.pricing.find(
      ({ id }) => id === this.product.default_price
    );
  }

  selectStripeDetail(property, detail) {
    return (event) => {
      this[property] = this.stripe_info[detail].find(
        ({ id }) => id === event.target.value
      );
    };
  }

  renderBody() {
    return this.locations.render(({ locations, location_groups }) => {
      if (!this.quantity) {
        this.quantity = areaCount(locations, location_groups);
      }
      return html`
        <div class="vstack gap-3">
          <div>
            <label class="form-label">Stripe product:</label>
            <select
              class="form-select"
              name="product"
              @input=${this.selectProduct}
            >
              <option value="">Select product</option>
              ${this.stripe_info.products
                .sort(stringPropertyComparator('name'))
                .map(
                  ({ id, name }) => html`<option value="${id}">${name}</option>`
                )}
            </select>
          </div>
          ${this.product ? this.renderPricing() : nothing}
          ${this.pricing && this.pricing.tax_behavior === 'exclusive'
            ? this.renderTaxRates()
            : nothing}
          ${this.pricing && this.pricing.billing_scheme === 'per_unit'
            ? html`
                <div>
                  <label class="form-label">Areas:</label>
                  <input
                    type="number"
                    class="form-control"
                    .value=${this.quantity}
                    @input=${(event) => {
                      this.quantity = event.target.value;
                    }}
                  />
                </div>
              `
            : nothing}
          ${this.pricing && this.stripe_info.coupons?.length
            ? this.renderCoupons()
            : nothing}
        </div>
      `;
    });
  }

  renderFooter() {
    const subscription_allowed =
      this.pricing &&
      (this.pricing.tax_behavior !== 'exclusive' || this.tax_rate);
    return html`
      <div class="flex-grow-1">${this.estimate()}</div>
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSubscribe}
        ?disabled=${this.saving || !subscription_allowed}
      >
        Subscribe
      </button>
    `;
  }

  renderPricing() {
    return html`
      <div>
        <label class="form-label">Pricing:</label>
        <select
          class="form-select"
          name="pricing"
          @input=${this.selectStripeDetail('pricing', 'pricing')}
        >
          <option value="">Select pricing</option>
          ${this.stripe_info.pricing
            .filter((pricing) => pricing.product === this.product.id)
            .map(
              (pricing) => html`
                <option
                  value="${pricing.id}"
                  ?selected=${pricing.id === this.pricing?.id}
                >
                  ${displayPricing(pricing)}
                </option>
              `
            )}
        </select>
      </div>
    `;
  }

  renderTaxRates() {
    return html`
      <div>
        <label class="form-label">Tax rate:</label>
        <select
          class="form-select"
          name="tax_rate"
          @input=${this.selectStripeDetail('tax_rate', 'tax_rates')}
        >
          <option value="">Select tax rate</option>
          ${this.stripe_info.tax_rates.map(
            ({ id, display_name, country }) => html`
              <option value="${id}" ?selected=${id === this.tax_rate?.id}>
                ${display_name} (${country})
              </option>
            `
          )}
        </select>
      </div>
    `;
  }

  renderCoupons() {
    return html`
      <div>
        <label class="form-label">Coupon:</label>
        <select
          class="form-select"
          name="coupon"
          @input=${this.selectStripeDetail('coupon', 'coupons')}
        >
          <option value="">No coupon</option>
          ${this.stripe_info.coupons.map(
            ({ id, name }) => html`
              <option value="${id}" ?selected=${id === this.coupon?.id}>
                ${name}
              </option>
            `
          )}
        </select>
      </div>
    `;
  }
}

customElements.define('modal-stripe-subscription', ModalAccountStripeSub);

function recurrence(pricing) {
  const { recurring } = pricing;
  return recurring
    ? ` / ${recurring.interval_count > 1 ? recurring.interval_count : ''}${
        recurring.interval
      }`
    : '';
}

function displayPricing(pricing) {
  const { unit_amount, currency } = pricing;
  return pricing.nickname
    ? pricing.nickname
    : `${unit_amount / 100} ${currency.toUpperCase()}${recurrence(pricing)}`;
}

function areaCount(locations, location_groups) {
  return (
    locations.filter(({ archived, group }) => !archived && !group).length +
    location_groups.filter(({ archived }) => !archived).length
  );
}
