import { html } from 'lit';
import { request } from '../../../lib/request.js';
import { ModalElement } from '../../modal/base.js';

const excel_format_options = [
  { value: '', label: 'Standard' },
  { value: 'INTERNAL', label: 'Internal Supplier' }
];

export function getExcelExportFormatLabel(value) {
  return /** @type {Object} */ (
    excel_format_options.find((option) => (option.value || null) === value)
  ).label;
}

class ModalAccountSupplierExcelFormat extends ModalElement {
  static properties = {
    ...ModalElement.properties,
    account: {},
    created: { type: Number },
    excel_file_format: { attribute: 'excel-file-format' },
    saving: { state: true }
  };

  constructor() {
    super();
    this.title = 'Supplier Order Excel File Format';
    this.account = '';
    this.created = null;
    this.excel_file_format = '';
    this.saving = false;
  }

  async onSave() {
    this.saving = true;
    const updated = await request(
      'PATCH',
      `/accounts/${this.account}/suppliers/${this.created}`,
      {
        excel_file_format: this.excel_file_format || null
      }
    );
    if (!updated.excel_file_format) {
      updated.excel_file_format = undefined;
    }
    this.emit('updated', updated);
    this.close();
  }

  renderBody() {
    return html`
      <select
        class="form-control"
        name="excel-format"
        value=${this.excel_file_format}
        @input=${(e) => (this.excel_file_format = e.target.value)}
      >
        ${excel_format_options.map(
          ({ value, label }) => html`
            <option
              value="${value}"
              .selected=${this.excel_file_format === value}
            >
              ${label}
            </option>
          `
        )}
      </select>
    `;
  }

  renderFooter() {
    return html`
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        ?disabled=${this.saving}
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click=${this.onSave}
        ?disabled=${this.saving}
      >
        Update
      </button>
    `;
  }
}

customElements.define(
  'modal-account-supplier-excel-format',
  ModalAccountSupplierExcelFormat
);
