/**
 * @template {Object | null | undefined} T
 * @param {T} obj
 * @returns {T}
 */
export function deepCopy(obj) {
  if (!obj) {
    return obj;
  }
  return JSON.parse(JSON.stringify(obj));
}
